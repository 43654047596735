const languages = {
    'de': {
        'ACADEMY' : 'MyShopBooster Academy',
        'AGREE_PRIVACY_POLICY' :  'Bitte bestätige unsere Datenschutzerklärung.',
        'ALSO_INTERESTING_FOR_YOU' : 'Für Dich interessant',
        'APPEARANCE' : 'Darstellung',
        'AREA' : 'Bereich',
        'ASSESSMENT' : 'Bewertung',
        'BACK_TO_DASHBOARD' : 'Zum Dashboard',
        'BACK_TO_LOGIN' : 'Zurück zum Log-In',       
        'BENEFIT' : 'Nutzen', 
        'CHAPTER' : 'Kapitel',
        'COLLECT_WEEKLY_DATA' : 'Wochendaten erfassen',
        'COLLECT_MONTHLY_DATA' : 'Monatsdaten erfassen',
        'COMPANIES' : 'Firmen',
        'COURSE_CONTENT' : 'Kursinhalt',
        'CREATED_AT' : 'Erstellt am',
        'CREATE_NEW_COMPANY' : 'Neue Firma anlegen',
        'CREATE_NEW_PRODUCT':  'Neues Produkt anlegen',
        'CREATE_NEW_PROJECT' : 'Neues Projekt anlegen',
        'CREATE_NEW_USER' : 'Neuen Nutzer anlegen',
        'DELETE' : 'Löschen',
        'DESCRIPTION' : 'Beschreibung',           
        'DONE' : 'Erledigt',          
        'DOWNLOAD' : 'Download',  
        'EFFORT' : 'Aufwand',
        'E_MAIL' : 'E-Mail-Adresse',
        'EMAIL_EXISTS_RESPONSE_TEXT' : 'Du bist bereits registriert. Schaue bitte in Dein E-Mail-Postfach - wir habe Dir bereits eine E-Mail mit Deinen Zugangsdaten gesendet. Falls Du Dein Passwort vergessen hast, nutze bitte die Passwort-Vergessen-Funktion im Bereich der Anmeldung. ',
        'EMPTY_ITEMS_TABLE_MSG': 'Keine Produkte vorhanden',
        'EMPTY_FILTERED_ITEMS_TABLE_MSG' : 'Es wurden keine Produkte gefunden, die mit den Suchkriterien übereinstimmen.',
        'ERROR404_TEXT' : 'Es tut uns sehr leid... die angeforderte Seite konnte nicht gefunden werden. Willst Du stattdessen zum Dashboard zurückkehren?', 
        'ERROR404_TITLE' : '404 Error',
        'ERROR_TITLE_DEFAULT' :  'Leider ist ein Fehler aufgetreten',
        'EVALUATIONS' : 'Auswertungen',
        'FILTER' : 'Filter', 
        'FORM_SALUTATION': 'Anrede',
        'FORM_FIRSTNAME': 'Vorname',
        'FORM_LASTNAME': 'Nachname',
        'FORM_TELEPHONE': 'Telefon',
        'FORM_EMAIL': 'E-Mail',
        'FREE' : 'Gratis',
        'FREE_INITIAL_CONSULTATION' : 'Kostenlose Erstberatung',
        "FREE_OF_CHARGE_DOWNLOAD" : 'Gratis Download',
        "FREE_OF_CHARGE_ACCESS" : 'Gratis Zugang',
        'FREE_SHOP_ANALYSIS' : 'Kostenlose Shopanalyse',
        'CONTACT_CONSULTING' : 'Kontaktiere uns',
        'FORM_LOGIN_TITLE': 'Anmeldung',
        'FORM_REGISTRATION_TITLE' : 'Registrierung',
        'REGISTER_BUTTON' : 'Jetzt registrieren',
        'GET_NEW_PASSWORD': 'Passwort anfordern',
        'GO_TO_LEARNING_CONTENT' : 'Lesson starten',
        'GO_TO_LEARNING_CONTENT_FREE' : 'Gratis Zugang',
        'BUY_LEARNING_CONTENT' : 'jetzt kaufen',
        'PURCHASED_LEARNING_CONTENT' : 'Mein Kurs',
        'PURCHASED_CHECKLIST' : 'Meine Checkliste',
        'PURCHASED_WHITEPAPER' : 'Mein Whitepaper',
        'PURCHASED_MASTERMIND' : 'Mastermind',
        'PURCHASED_SUPPORT' : 'Support',
        'PURCHASED_DATE' : 'Erworben am: ',
        'IMPORTANCE' : 'Wichtigkeit',
        'INFO' : 'Beispiele',
        'INSERT_YOUR_NOTES' : 'Trage hier Deine Notizen ein!',
        'MAIN_CATEGORIES' : 'Hauptkategorien',
        'MISSING_RECORDS' : 'Fehlende Datensätze',
        'MISSING_ENTRIES_FOR_TURNOVER' : 'Umsätze',
        'MISSING_ENTRIES_FOR_VISITORS' : 'Besucher',
        'MONTHS' : 'Monate',
        'MONTHLY_DATA_COLLECTION_HEADLINE': 'Monatliche Datenerfassung',
        'MONTHLY_DATA_COLLECTION_TEXT' : 'Bitte trage die folgenden Daten ein.<br/>Es werden die monatlichen Umsatz- und Besucherzahlen Deines Onlineshops benötigt.',
        'MY_PROJECTS' : 'Meine Projekte',
        'NAV_LINK_TEXT_ACADEMY' : 'Academy',
        'NAV_LINK_TEXT_ADMIN_COMPANIES' : 'Firmen',
        'NAV_LINK_TEXT_ADMIN_PRODUCT' : 'Produktverwaltung',
        'NAV_LINK_TEXT_ADMIN_USER' : 'Nutzerliste',
        'NAV_LINK_TEXT_DASHBOARD' : 'Dashboard',
        'NAV_LINK_TEXT_MY_PRODUCTS' : 'Meine Produkte',
        'NAV_LINK_TEXT_STATISTICS' : 'Statistiken',
        'NEW_PRODUCTS' : 'Neuste Produkte',
        'NEWS' : 'News',
        'NEXT_LESSON' : 'Nächste Lektion',
        'NO_PRODUCTS_IN_LIST' : 'noch keine Produkte erworben',
        'NO_PRODUCTS_IN_YOUR_LIST' : 'Aktuell hast Du noch keine Produkte erworben.',
        'NO_PROJECTS_IN_YOUR_LIST' : 'Aktuell hast Du noch keine Projekte angelegt.',
        'NOT_SET' : 'Nicht angegeben',
        'NO_VIDEO_IN_LIST' : 'Aktuell stehen noch keine Lerninhalte zur Verfügung.',
        'NOTES' : 'Notizen',
        'OPTIMIZE' : 'Optimieren',
        'PAGE_SUBTITLE' : 'MyShopBooster',
        'PAGE_TITLE' : 'Member Area',
        'PASSWORD' : 'Passwort',
        'PASSWORD_FORGOTTEN_DEFAULT_TEXT' : 'Bitte gebe Deine E-Mail-Adresse ein. Du erhältst im Anschluss ein neues Passwort zugeschickt, insofern Du bereits registriert bist.',
        'PASSWORD_FORGOTTEN_RESPONSE_TEXT' : 'Insofern Deine E-Mail-Adresse registriert ist, erhältst Du in den nächsten Minuten eine E-Mail mit einem Aktivierungslink, um Dein neues Passwort zu erhalten.',
        'PLACEHOLDER_NOTICE' : 'Trage hier Deine Notizen zur Aufgabe ein.',
        'POLICY_TERMS_TITLE' : 'Datenschutz & AGB',
        'POLICY_TITLE' : 'Datenschutzerklärung',
        'PRICE_DISCOUNT' : 'Ersparnis: {discount}%',
        'PRIORITY' : 'Priorität',
        'PRODUCT_MANAGEMENT' : 'Produktverwaltung',
        'PRODUCT_OVERVIEW' : 'Alle Produkte ansehen',
        'PRODUCT_RECOMMONDATIONS' : 'Produktempfehlungen',
        'PROJECT_INDEX' : 'Gesamtindex',
        'PW_FORGOTTEN_TITLE' : 'Passwort vergessen?',
        'PW_ACTIVATION_TITLE' : 'Passwort vergessen Aktivierung',
        'PW_ACTIVATION_SUCCESS_TEXT' : 'Dir wurden neue Zugangsdaten zugesendet.',
        'PW_ACTIVATION_ERROR_TEXT' : 'Der Aktivierungslink ist falsch oder abgelaufen. Bitte wiederhole den Vorgang.',  
        'QUICK_TIP' : 'Kurzanleitung',
        'QUICK_TIP_TITLE' : 'Kurzanleitung zur Benutzung Deiner Checklisten',
        'SEARCH' : 'Suche',
        'SIGN_IN' : 'Anmelden',
        'STATISTIC_HINTS_HEADLINE' : 'Hinweise zur Benutzung',
        'STATISTIC_HINTS_TEXT_1' : '<span class="font-weight-bold">Darstellung:</span> Klick auf <span class="font-weight-bold">Monate</span> zeigt die Auswertung nach Monaten. Klick auf Kalenderwochen zeigt die <span class="font-weight-bold">Kalenderwochen</span> eines Jahres.',
        'STATISTIC_HINTS_TEXT_2' : '<span class="font-weight-bold">Filter:</span> aktivieren / deaktivieren der Buttons schränkt die Ergebnisse ein.',
        'STATISTIC_HINTS_TEXT_3' : '<span class="font-weight-bold">Funktionen in den Charts:</span> Benutze die Chart-Funktionsbar, um die Ansichten zu vergößern, zu verkleinern oder zu exportieren.',
        'STATISTIC_HINTS_TEXT_4' : 'Deine Daten bleiben immer gleich, unabhängig der ausgewählten Kategorien.',
        'STATUS' : 'Status',
        'SUB_CATEGORIES' : 'Unterkategorien',
        'SUCCESS_REGISTRATION_RESPONSE_TEXT' : 'Vielen Dank. Wir haben Dir eine E-Mail mit den Zugangsdaten gesendet. ',
        'SUCCESS_TITLE_DEFAULT' :  'Vielen Dank',        
        'SUCCESS_TEXT_SAVE_DATA' : 'Vielen Dank! Die Daten wurden gespeichert.',
        'TASK' :  'Aufgabe',
        'TASK_TITLE_MONTHLY' : 'Monatliche Datenerfassung',
        'TASK_TITLE_WEEKLY' : 'Wöchentliche Datenerfassung',
        'TASK_TEXT_INCOMPLETE' : 'Hier findest Du Aufgaben, die noch zu erledigen sind.',
        'TASK_TEXT_COMPLETE' : 'Vielen Dank! Du hast aktuell alle Daten eingetragen.',
        'TERM' : 'Begriff...',
        'TERMS_TITLE' : 'AGB',
        'TODO': 'Todo',
        'TOTAL' : 'Gesamt',
        'TO_THE_CHECKLIST' : 'Zur Checkliste',
        'MORE_DETAILS' : 'Mehr Informationen',
        'USER' : 'Benutzer',
        'USER_SALUTATION' : 'Herzlich willkommen, {username}!',
        'VALID_EMAIL_ADDRESS_REQUIRED' : 'Bitte gebe eine gültige E-Mail-Adresse ein.',
        'VALID_SURENAME_REQUIRED' : 'Bitte gebe Deinen Vornamen an.',
        'VALID_TELEPHONE_NUMBER_REQUIRED' : 'Bitte gebe eine gültige Telefonnummer ein.',
        'VIDEO_TOTALTIME' : 'Dauer: {time}',
        'WEEKLY_DATA_COLLECTION_HEADLINE' : 'Wöchentliche Datenerfassung',
        'WEEKLY_DATA_COLLECTION_TEXT' : 'Bitte trage die folgenden Daten ein.<br/>Es werden die wöchentlichen Umsatz- und Besucherzahlen Deines Onlineshops benötigt.',
        'WEEKS' : 'Kalenderwochen',
        'YOUR_LEARNING_PROGRESS' : 'Dein Lernfortschritt',
        'YOUR_LEARNING_PROGRESS_DESCRIPTION' : 'Hier siehst Du, wie weit Du bereits fortgeschritten bist.',
        


    }
}

export {
    languages
}
