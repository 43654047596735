<template>
  <b-container fluid="xxl">

    <b-row class="mb-6" v-if="getNotPurchasedProducts.length > 0">
      <b-col cols="12">
        <hocSlider :show-button="false" :no-padding="true" :reset="emitChanges" :small-layout="true"  variant="primary" :title="$t('ALSO_INTERESTING_FOR_YOU')" :cardicon="offerIcon" slider-class="wl-slider__slider-default">
          <template #slideitems>
            <CardSliderItem v-for="(product, product_index) in getNotPurchasedProducts" :key="product_index"
            :product="product"
            :product-id="product.product_id  | tostring"
            :product-title="product.product.product_name" 
            :product-type="product.product.product_type"
            :product-description="product.product.product_description_short"

            :product-cta-link="
              (product.product.product_type == 'Whitepaper')
                ? product['whitepaper'].media[0].url
                : ''"
            :product-btn-label="handleSliderProductBtnLabel(product)"
              v-on:submit="handleSliderProductCardEvent"
            
          />
          </template>
          
          <template #slideimages="{ slide }">
          <CardSliderImage :slide="slide" v-for="(product, product_index) in getNotPurchasedProducts" :key="product_index" 
            :id="product_index" 
            :product-image="$utils.getMediaImg(1, product.media, 'product_media_description_type_id')"
          />
          </template>
        </hocSlider>
      </b-col>
    </b-row>



      <b-row>
        <b-col cols="12">
          <h3>{{ $t('NAV_LINK_TEXT_MY_PRODUCTS') }}</h3>
          <p class="mb-5" v-if="getMyPurchasedProducts.length == 0" v-html="$t('NO_PRODUCTS_IN_YOUR_LIST')"></p>
      </b-col>
    </b-row>
    
    

    <b-row>

      <!-- item -->
      <b-col
          v-for="(product, product_index)  in getMyPurchasedProducts"
          :key="product_index"
          cols="12" xl="6" xxl="4"
          class="mt-4 mb-3"
      >
        <ProductCard 
          :no-padding="true"
          :product="product"
          :product-id="product.product_id  | tostring"
          :product-title="product.product.product_name" 
          :product-type="product.product.product_type"
          :product-image="$utils.getMediaImg(3, product.media, 'product_media_description_type_id')"
          :product-is-my-product="product.is_my_product"
          :product-purchased="$t('PURCHASED_DATE') + product.sale_date_formatted"
          :badge-text="badgeTextHandler(product)"

          :product-cta-link="productCardButtonCta(product)"
         
          :product-btn-label="productCardButtonLabelHandler(product)"

          :product-description="product.product.product_description_short"
          v-on:submit="handleFreeProductCardEvent"
          :small-layout="true"
        />


      </b-col>

    </b-row>

    
     
  </b-container>
</template>

<script>
//import * as CONFIG from '@/wl-config/wl-app-config';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";
import hocSlider from '@/components/hoc/hocSlider.vue';
import CardSliderItem from '@/components/ProductCardSlider/CardSliderItem.vue';
import CardSliderImage from '@/components/ProductCardSlider/CardSliderImage.vue';
import ProductCard from '@/components/ProductCard.vue';
//import Vue from 'vue';

export default {
  name: "areaMyProducts",
  components: {
    hocSlider,
    CardSliderItem,
    CardSliderImage,
    ProductCard
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API, 
      emitChanges: false,
      offerIcon: CONFIG.WLCONFG.ICON_OFFER
    }
  },
  mounted() {
    this.loadMyProductList();
    
  },
  computed: {
    ...mapGetters([
      'getMyPurchasedProducts',
      'getNotPurchasedProducts'
    ]),
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        'setMyProductList',
        'updateMyProductIsMyProduct',
    ]),
    ...mapGetters([]),

    async loadMyProductList() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_myproducts_list',
            cookie: cookie,
          }
      );

      if (!response.data.error) {
        this.$store.dispatch('setMyProductList', response.data);

      }

    },
    handleSliderProductBtnLabel(product) {

      //console.log(product);

      if(!product || !("product" in product) ) {
        return;
      }

      //window.open(data.url,'_blank');

      var label = '';

      switch(product.product.product_type) {
        case 'Whitepaper':
          label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
          break;

        case 'Checkliste':
          if (product.product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('FREE_OF_CHARGE_ACCESS');
          }
          break;

        case 'Onlinekurs':
          if (product.product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('GO_TO_LEARNING_CONTENT_FREE');
          }
          break;

        case 'Support':
          label = this.$t('MORE_DETAILS');
          break;

        case 'Mastermind':
          label = this.$t('MORE_DETAILS');
          break;


      }

      return label;


    },
    handleSliderProductCardEvent(data) {
      if(!data || !("type" in data) ) {
        return;
      }

      //console.log(data);

      switch(data.type) {

        case 'Whitepaper':
          this.trackSale(data.id, data.url);
          break;

        case 'Checkliste':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            //zur Kaufseite
            window.open(data.product.product.product_url,'_blank');
          } else {
            //Gratisliste erwerben
            this.trackSaleChecklist(data.id);
          }
          break;

        case 'Onlinekurs':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            //zur Kaufseite
            window.open(data.product.product.product_url,'_blank');
          } else {
            //Gratis erwerben
            this.trackSaleCourse(data.id);
          }
          break;

        case 'Support':
        case 'Mastermind':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            //zur Kaufseite
            window.open(data.product.product.product_url,'_blank');
          }
          break;
      }
        
        
    },
    handleFreeProductCardEvent(data) {
      if(!data || !("type" in data) ) return;

      switch(data.type) {
        case 'Whitepaper':
          window.location.href = data.url;
          break;

        case 'Checkliste':
          this.$router.push(data.url);
          break;

        case 'Onlinekurs':
          //console.log(data);
          this.trackSaleCourse(data.product.product_id);
          break;
      }
    },
    async trackSale(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.emitChanges = true;
      //console.log(url);
      window.location = url;


    },

    async trackSaleChecklist(product_id) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_checklist',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.$router.push('/myChecklistProjects/' + product_id);

    },

    async trackSaleCourse(product_id) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_course',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$router.push('/academylesson/' + product_id);

    },

    badgeTextHandler(product) {

      //console.log(product);

      var label = '';

      switch(product.product.product_type) {

        case 'Checkliste':

          label = this.$t('PURCHASED_CHECKLIST');
          break;

        case 'Whitepaper':

          label = this.$t('PURCHASED_WHITEPAPER');
          break;

        case 'Onlinekurs':

          label = this.$t('PURCHASED_LEARNING_CONTENT');
          break;

        case 'Mastermind':

          label = this.$t('PURCHASED_MASTERMIND');
          break;

        case 'Support':

          label = this.$t('PURCHASED_SUPPORT');
          break;

      }

      return label;

    },

    productCardButtonLabelHandler(product) {

      var label = '';

      switch(product.product.product_type) {

        case 'Checkliste':

          label = this.$t('TO_THE_CHECKLIST');
          break;

        case 'Whitepaper':

          label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
          break;

        case 'Onlinekurs':

          label = this.$t('GO_TO_LEARNING_CONTENT');
          break;

      }

      return label;


    },

    productCardButtonCta(product) {

      var out = '';

      switch(product.product.product_type) {

        case 'Checkliste':

          out = `/myChecklistProjects/${product.product_id}`;
          break;

        case 'Whitepaper':

          out = product['whitepaper'].media[0].url;
          break;

        case 'Onlinekurs':

          out = `/academylesson/${product.product_id}`;
          break;

      }

      return out;


    },
  }
}
</script>

