<template>
   
    <b-container  fluid="xl" class="h-100 d-flex justify-content-center align-items-center mw-991">
        
        <b-row class="justify-content-md-center mt-4 mt-lg-7 mt-xl-10">
            <b-col cols="12" :lg="wlAppConstants.USE_CONTENT_IMAGE_ERROR_PAGE ? 9 : 12" :md="wlAppConstants.USE_CONTENT_IMAGE_ERROR_PAGE ? 8 : 12">
                 <div v-if="wlAppConstants.USE_CONTENT_IMAGE_ERROR_PAGE">
                    <h5>{{ $t('ERROR404_TITLE')}}</h5>
                    <b-img :src="getLogo()"  fluid />
                 </div>
                <div v-else>
                    <h2 class="mt-5 mb-4">{{ $t('ERROR404_TITLE') }}</h2>
                </div>
                <p class="mt-5 mb-4">{{ $t('ERROR404_TEXT') }}</p>
                <p> <b-button href="#" variant="cta" @click="$router.push('/')">{{ $t('BACK_TO_DASHBOARD') }}</b-button></p>
                
                
            </b-col>
        </b-row>
    </b-container>
   
</template>


<script>
//import * as CONFIG from '@/wl-config/wl-app-config';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)



 const DEFAULTCONFIG = {
    direction : 'x',
    scaleMode : 'contain',
    interval         : 35,
    fitFirstImage    : true
}

export default {
  name: "areaError404",
  components: {
  
  },
  data() {
    return {
      config: DEFAULTCONFIG,   
      wlAppConstants: CONFIG.WLCONFG
    }
  },
 
  methods: {
 
    getLogo() {   
        return require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/assets/images/${this.wlAppConstants.ERROR_LOGO}`);
    }
  }
}
</script>
