<template>
    <div class="text-center mt-5" v-if="!activation && activationChecked"> 
      <b-button href="#" variant="cta" @click="$router.push('/login')">{{ $t('BACK_TO_LOGIN') }}</b-button>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "areaPasswordForgottenActivation",
  props: ['user_key'],
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      activation: false,
      activationChecked: false,
      autoHideToast: 4000
    }
  },
  mounted() {

    //console.log(this.user_key);
//d37f14209ef69e8ab66c58d1fb5awww7524
    if (this.user_key === undefined || this.user_key == '') {
      console.log('user_key ist nicht gesetzt');
      //return this.newUserData;
      this.activation = false;
      this.activationChecked = true;
       this.showMessage(true);
     
      return;
    }

    this.sendPwForgottenActivationKey();

  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
    ]),
    ...mapGetters([
    ]),

    showMessage(errorstate) {
      if(errorstate) {
        this.$bvToast.toast([this.$t('PW_ACTIVATION_ERROR_TEXT')], {
          title: [this.$t('ERROR_TITLE_DEFAULT')],
          solid: true,
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          noCloseButton: true,
          autoHideDelay: this.autoHideToast
        })
      } else {
        this.$bvToast.toast([this.$t('PW_ACTIVATION_ERROR_TEXT')], {
          title: [this.$t('ERROR_TITLE_DEFAULT')],
          solid: true,
          toaster: 'b-toaster-top-center',
          variant: 'success',
          noCloseButton: true,
          autoHideDelay: this.autoHideToast
        })
      }
    },
    async sendPwForgottenActivationKey() {

      const response = await axios.post(
          this.API_URL,
          {
            type: 'send_password_forgotten_activation_key',
            user_key: this.user_key,
          }
      );

      //console.log(response);

      if (response.data.error === undefined) {
        //console.log('atrue');
        this.activation = true;
        this.showMessage(false);
      } else {
        //console.log('afalse');
        this.activation = false;
        this.activationChecked = true;
        this.showMessage(true);
      }

    },
  }
}
</script>

<style scoped>

</style>