const state = {

    is_logged_id: false,
    user: {},
    company: {},
    token: '',
    userTypes: {},
}

const mutations = {

    SET_IS_LOGGED_IN(state, payload) {
        state.is_logged_id = payload;
    },

    SET_USER(state, payload) {
        state.user = payload;
    },

    SET_COMPANY(state, payload) {
        state.company = payload;
    },

    SET_TOKEN(state, payload) {
        state.token = payload;
    },

    SET_USER_TYPES(state, payload) {
        state.userTypes = payload;
    },

}

const actions = {

    setIsLoggedIn({commit}, payload) {
        commit('SET_IS_LOGGED_IN', payload);
    },

    setUser({commit}, payload) {
        commit('SET_USER', payload);
    },

    setCompany({commit}, payload) {
        commit('SET_COMPANY', payload);
    },

    setToken({commit}, payload) {
        commit('SET_TOKEN', payload);
    },

    setUserTypes({commit}, payload) {
        commit('SET_USER_TYPES', payload);
    },

}

const getters = {

    getUser() {
        return state.user;
    },
    getUserLevel() {
        return state.user.user_level;
    },
    getIsLoggedIn() {
        return state.is_logged_id;
    },
    getCompany() {
        return state.company;
    },
    getToken() {
        return state.token;
    },
    getUserTypes() {
        return state.userTypes;
    },

}

const user = {
    state,
    mutations,
    actions,
    getters

}

export default user;