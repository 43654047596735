<template>
<div class="wl-task">
  <hocCard>

      <div class="wl-task__header"
        :class="{'wl-task__header--open' : visible, 'collapsed' : !visible, 'wl-task__header--complete' : complete,  'wl-task__header--always-open' : keepOpen, 'wl-task__header--no-completion': !completionVisible }"
        :area-expanded="visible ? 'true' : 'false'"
        :aria-controls="`control-${id}`"
        @click="visible = (keepOpen) ? true : !visible"
      >
      
        <h5 v-if="link" class="wl-task__link" @click="$emit('submit', { url: link})">{{ title }}</h5>
        <div v-else>{{ title }}</div>
      </div>
        <b-collapse :id="`control-${id}`" v-model="visible" class="mt-2">
          <slot/>
        </b-collapse>
    
  </hocCard>
  </div>
</template>

<script>
import hocCard from '@/components/hoc/hocCard';
import Vue from 'vue'
export default {
    name: "hockTask",
    emits: ['submit'],
    components: {
      hocCard
    },
    data() {
      return {
        visible: this.open,
        keepOpen: this.alwaysOpen,
        completionVisible: this.showCompletion,
        link: this.url
      }
    },
    props: {

        url: String,
        alwaysOpen: {
          type: Boolean,
          default: false
        },
        showCompletion: {
          type: Boolean,
          default: true
        },
        complete: {
          type: Boolean,
            default: false 
        },
        open: {
            type: Boolean,
            default: false  
        },
        title: {
            type: String,
            default: ''  
        },
        id: {
          type: String,
          default: () => Vue.utils.generateRandomHash(10)
        }
    },
    watch: {
        // whenever question changes, this function will run
        open(val, oldVal) {
            if (val != oldVal) {
                this.visible = val
            }
        }
    }

}
</script>