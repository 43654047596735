<template>
  <div class="wl-navigation d-block d-xl-inline-block">
 

      <div class="wl-side-nav badge badge-pill badge-primary ">
        <div class="wl-side-nav__inner d-flex align-items-center flex-xl-column justify-content-between pb-4 pt-xl-5 pb-xl-5 pr-5 pl-5 pr-xl-0 pl-xl-0">
          <b-link :to="item.link" class="wl-side-nav__item mt-2 mb-2" v-for="item in navitems" v-bind:key="item.id">
              <font-awesome-icon :icon="item.icon" />
              <span class="wl-side-nav__tooltip bg-primary p-4 d-block">
                  {{ $t(item.title) }}
                  <span class="arrow d-block bg-primary"></span>
              </span>
              <!--<span class="wl-side-nav__item__subtitle">{{ $t(item.title) }}</span>-->
          </b-link> 
        </div>

        
      </div>

  </div>
  

</template>

<script>

import { mapGetters } from 'vuex';
//import * as CONFIG from '@/wl-config/wl-app-config';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)
const items = [
  { id: 1, icon: CONFIG.WLCONFG.ICON_NAVIGATION_DASHBOARD, link: '/dashboard', title: 'NAV_LINK_TEXT_DASHBOARD' },
  { id: 2, icon: CONFIG.WLCONFG.ICON_NAVIGATION_MY_PRODUCTS, link: '/myProducts', title: 'NAV_LINK_TEXT_MY_PRODUCTS'  },
  { id: 4,  icon: CONFIG.WLCONFG.ICON_NAVIGATION_ACADEMY, link: '/academylessons', title: 'NAV_LINK_TEXT_ACADEMY'  }
];

//{ id: 3,  icon: CONFIG.WLCONFG.ICON_NAVIGATION_STATISTICS, link: '/statistic', title: 'NAV_LINK_TEXT_STATISTICS'  },

export default {
  name: "NavigationBar",
  data() {
    return {
      navitems: items
    }
  },
  computed: {
    ...mapGetters([
      'getUserLevel'
    ]),
  }
}
</script>

<style scoped>


</style>