<template>
  <b-container fluid>

    <b-row class="mb-3">
      <b-col cols="12" md="auto" class="mt-3">
        <h5>{{ salutation }}</h5>
        <p v-if="isMonthlyTaskComplete && isWeeklyTaskComplete">{{ $t('TASK_TEXT_COMPLETE') }}</p>
        <p v-else>{{ $t('TASK_TEXT_INCOMPLETE') }}</p>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col cols="12">
          <hocTask :title="$t('TASK_TITLE_WEEKLY')" :open="true" :complete.sync="isWeeklyTaskComplete">
        
            <p class="mt-3 mb-4"><small>{{ $t('MISSING_RECORDS') }}</small></p>
            <uiProgessbar :reverse-math="true" :currentvalue="getRecordDataWeekly.sum" :totalvalue="getRecordDataWeekly.total"/>
            <span class="d-block mt-3 d-flex align-items-center"><small><iconTurnOver class="mr-1"/> {{ $t('MISSING_ENTRIES_FOR_TURNOVER') }} </small> <b-badge pill variant="antiflash" class="pr-3 pl-3 pt-2 pb-2 ml-auto">{{ getRecordDataWeekly.turnover }}</b-badge></span>
            <span class="d-block mt-2 d-flex align-items-center"><small><iconVisitors class="mr-1"/> {{ $t('MISSING_ENTRIES_FOR_VISITORS') }} </small> <b-badge pill variant="antiflash" class="pr-3 pl-3 pt-2 pb-2 ml-auto">{{ getRecordDataWeekly.visitor }} </b-badge></span>
       
            <div class="text-center">
              <b-button
              type="button"
              variant="cta"
              class="mt-4 button-cta"
              to="/recordingWeeks"
              >{{ $t('COLLECT_WEEKLY_DATA') }}</b-button>
            </div>
            

        </hocTask>

        <hocTask :title="$t('TASK_TITLE_MONTHLY')" :open="false" class="mt-4" :complete.sync="isMonthlyTaskComplete">

           <p class="mt-3  mb-4"><small>{{ $t('MISSING_RECORDS') }}</small></p>
            <uiProgessbar :reverse-math="true" :currentvalue="getRecordDataMonthly.sum" :totalvalue="getRecordDataMonthly.total"/>
             <span class="d-block mt-3 d-flex align-items-center"><small><iconTurnOver class="mr-1"/> {{ $t('MISSING_ENTRIES_FOR_TURNOVER') }} </small> <b-badge pill variant="antiflash" class="pr-3 pl-3 pt-2 pb-2 ml-auto">{{ getRecordDataMonthly.turnover }}</b-badge></span>
            <span class="d-block mt-2 d-flex align-items-center"><small><iconVisitors class="mr-1"/> {{ $t('MISSING_ENTRIES_FOR_VISITORS') }} </small> <b-badge pill variant="antiflash" class="pr-3 pl-3 pt-2 pb-2 ml-auto">{{ getRecordDataMonthly.visitor }}</b-badge></span>
          
            <div class="text-center">
              <b-button
                  type="button"
                  variant="cta"
                  class="mt-4 button-cta"
                  to="/recordingMonths"
              >{{ $t('COLLECT_MONTHLY_DATA') }}</b-button>     
            </div>
           
        </hocTask>
       

      </b-col>
    </b-row>

  </b-container>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
import hocTask from '@/components/hoc/hocTask.vue';
import uiProgessbar from '@/components/ui/uiProgressbar.vue';
import axios from "axios";
import iconTurnOver from '@/icons/TURNOVER.vue';
import iconVisitors from '@/icons/VISITORS.vue';

export default {
  name: "areaRecording",
  components: {
    hocTask,
    uiProgessbar,
    iconTurnOver,
    iconVisitors
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      months: {}
    }
  },
  created() {

    var today = new Date();
    const lastSunday = new Date();
    lastSunday.setDate(today.getDate() - today.getDay());
    var lastSundayFormatted = new Date(lastSunday).toISOString().slice(0, 10);
    //console.log(lastSundayFormatted);

    const currentYear = new Date().getFullYear();
    const previousYear =  currentYear - 1;  
    
    const startDate = this.$utils.convertDate(new Date(previousYear + "-01-01T00:00:00"));
    const dates = this.$utils.getDates(new Date(previousYear + "-01-01T00:00:00"), new Date(lastSundayFormatted + "T00:00:00"));
    if (startDate == '') {
      this.$store.dispatch('setRecordingStartDate', previousYear + "-01-01");
    } else {
       this.$store.dispatch('setRecordingStartDate', startDate);
    }
    this.$store.dispatch('setRecordingEndDate', lastSundayFormatted);
    this.$store.dispatch('setRecordingDates', dates);
    

   this.sendRecords();
    

  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getRecordingMonths',
      'getUser',
      'getRecords',
      'getRecordDataWeekly',
      'getRecordDataMonthly'
    ]),

    isMonthlyTaskComplete( ){
      return this.getRecordDataMonthly.sum == 0
    },
    isWeeklyTaskComplete( ){
      return this.getRecordDataWeekly.sum == 0
    },
    salutation() {
      const user = this.getUser;
      //console.log(user);
      return this.$t('USER_SALUTATION', '', {username: user.firstname});
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    ...mapGetters([
    ]),

    async sendRecords() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      var startDate = this.$store.getters.getRecordingStartDate;
      var endDate = this.$store.getters.getRecordingEndDate;

      //console.log('start: ', startDate);
      //console.log('end: ', endDate);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_records',
            startDate: startDate,
            endDate: endDate,
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setRecords', {});

      } else {

        //console.log(response.data);
        this.$store.dispatch('setRecords', response.data);

      }
    }
  }
}
</script>

<style scoped>

</style>