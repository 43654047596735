<template>
<b-container fluid="xxl">
  <b-row v-if="getNotPurchasedProducts.length > 0">
    <b-col cols="12" class="mt-5 mb-5 d-flex">

      <hocSlider :show-button="true" :no-padding="true" :reset="emitChanges" :small-layout="true"  variant="primary" :title="$t('NEW_PRODUCTS')" :cardicon="offerIcon" slider-class="wl-slider__slider-default">
          <template #slideitems>
            <CardSliderItem v-for="(product, product_index) in getNotPurchasedProducts" :key="product_index"
                            :product="product"
                            :product-id="product.product_id  | tostring"
                            :product-title="product.product.product_name"
                            :product-type="product.product.product_type"
                            :product-description="product.product.product_description_short"
                            :product-cta-link="
                            (product.product.product_type == 'Whitepaper')
                              ? product['whitepaper'].media[0].url
                              : ''"
                            :product-btn-label="handleSliderProductBtnLabel(product)"
                            v-on:submit="handleSliderProductCardEvent"
            
          />
          </template>
          
          <template #slideimages="{ slide }">
            <CardSliderImage :slide="slide" v-for="(product, product_index) in getNotPurchasedProducts" :key="product_index" 
              :id="product_index" 
              :product-image="$utils.getMediaImg(1, product.media, 'product_media_description_type_id')"
            />
          </template>
        </hocSlider>

    </b-col>
    
  </b-row>
<!--
   <b-row>
    <b-col cols="12" xxl="6" class="mt-5 mb-5 d-flex">
      <hocCard :small-layout="true" :title="$t('EVALUATIONS')" :cardicon="chartsIcon">
        <template #default>

        </template>               
     </hocCard>
    </b-col>

    <b-col cols="12" xxl="6" class="mt-5 mb-5 d-flex">
      <hocCard>
        <template #default>
            
        </template>               
      </hocCard>
    </b-col>
  </b-row>
  -->

  <b-row>
    <b-col cols="12" cs="6" xxl="4" class="mt-5 mb-5 d-flex">
      <hocSlider :headline-muted="false" :no-padding="true"  :small-layout="true" :show-button="false" slider-class="wl-slider__slider-news" :title="$t('NEWS')" :cardicon="newsIcon">
        <template #slideitems>
          <CardSliderItem v-for="(news, index) in newsdata" :key="index"
          :product-title="news.title" 
          :product-type="news.subtitle"
          :product-description="news.description"
          :product-btn-label="$t(news.buttontext)"
          :product-cta-link="news.url"
          v-on:submit="handleSliderProductCardEvent"
        />
        </template>
      </hocSlider>
 
    </b-col>

    <b-col cols="12" cs="6" xxl="8" class="mt-5 mb-5 d-flex">
      <hocCard>
        <template #default>
            <uiVideoPlayer controls="~@/assets/mejs-controls.svg" video="Intro-myShopBooster.mp4" poster="poster_default.jpg"/>
        </template>               
      </hocCard>
    </b-col>
    
  </b-row>
 
</b-container>
  
           
</template>

<script>

//import * as CONFIG from '@/wl-config/wl-app-config';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)
import { mapGetters} from "vuex";
import axios from "axios";
import hocCard from '@/components/hoc/hocCard';
import hocSlider from '@/components/hoc/hocSlider.vue';
import CardSliderImage from '@/components/ProductCardSlider/CardSliderImage.vue';
import CardSliderItem from '@/components/ProductCardSlider/CardSliderItem.vue';
import uiVideoPlayer from '@/components/ui/uiVideoPlayer';


export default {
  name: "viewDashboard",
  components: {
    hocCard,
    uiVideoPlayer,
    hocSlider,
    CardSliderImage,
    CardSliderItem
},
  data () {
    return {
      API_URL: process.env.VUE_APP_API, 
      newsdata: [],
      newsIcon: CONFIG.WLCONFG.ICON_NEWS,
      chartsIcon: CONFIG.WLCONFG.ICON_CHARTS,
      offerIcon: CONFIG.WLCONFG.ICON_OFFER,

      emitChanges: false,
      
    }
  },
  mounted() {
    this.loadMyProductList();
    this.loadNewsData();
    
  },
  computed: {
    ...mapGetters([
      'getNotPurchasedProducts'
    ]),
  },
  methods: {
    async loadMyProductList() {

      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_myproducts_list',
            cookie: cookie,
          }
      );

      if (!response.data.error) {

        this.$store.dispatch('setMyProductList', response.data);

      }

    },

    async loadNewsData() {
      const response = await axios.get('./staticMockData/news.json');
      //console.log(response);
      this.newsdata = response.data.news;
    },

    async trackSale(product_id, url) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.emitChanges = true;
      //console.log(url);
      window.location = url;


    },

    async trackSaleChecklist(product_id) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_checklist',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$store.dispatch('updateMyProductIsMyProduct', product_id);
      this.$router.push('/myChecklistProjects/' + product_id);

    },

    async trackSaleCourse(product_id) {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      await axios.post(
          this.API_URL,
          {
            type: 'track_sale_course',
            cookie: cookie,
            product_id: product_id,
          }
      );

      this.$router.push('/academylesson/' + product_id);

    },
    handleSliderProductBtnLabel(product) {

      //console.log(product);

      if(!product || !("product" in product) ) {
        return;
      }

      //window.open(data.url,'_blank');

      var label = '';

      switch(product.product.product_type) {
        case 'Whitepaper':
          label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
          break;

        case 'Checkliste':
          if (product.product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('FREE_OF_CHARGE_ACCESS');
          }
          break;

        case 'Onlinekurs':
          if (product.product.product_price > 0) {
            /*
            label = '';
            if (product.product.product_pseudoprice > 0) {
              label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
            }
            label += Vue.utils.formatPrice((product.product.product_price));

             */
            label = this.$t('MORE_DETAILS');
          } else {
            label = this.$t('GO_TO_LEARNING_CONTENT_FREE');
          }
          break;

        case 'Support':
          label = this.$t('MORE_DETAILS');
          break;

        case 'Mastermind':
          label = this.$t('MORE_DETAILS');
          break;


      }

      return label;


    },
    handleSliderProductCardEvent(data) {
      if(!data || !("type" in data) ) {
        return;
      }

      //console.log(data);

      switch(data.type) {

        case 'Whitepaper':
          this.trackSale(data.id, data.url);
          break;

        case 'Checkliste':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            //zur Kaufseite
            window.open(data.product.product.product_url,'_blank');
          } else {
            //Gratisliste erwerben
            this.trackSaleChecklist(data.id);
          }
          break;

        case 'Onlinekurs':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            //zur Kaufseite
            window.open(data.product.product.product_url,'_blank');
          } else {
            //Gratis erwerben
            this.trackSaleCourse(data.id);
          }
          break;

        case 'Support':
        case 'Mastermind':
          if (("product" in data)
              && data.product.product.product_price > 0
          ) {
            //zur Kaufseite
            window.open(data.product.product.product_url,'_blank');
          }
          break;
      }


    },
  }
}
</script>

<style scoped>
</style>