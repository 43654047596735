// For more Icons take a lokk at https://fontawesome.com/

const WLCONFG= {
    USE_CONTENT_IMAGE_ERROR_PAGE:   true,
    ICON_NAVIGATION_DASHBOARD:      'gauge-high',
    ICON_NAVIGATION_MY_PRODUCTS:    'cart-arrow-down',
    ICON_NAVIGATION_STATISTICS:     'chart-pie',
    ICON_NAVIGATION_ACADEMY:        'graduation-cap',
    ICON_NEWS:                      'bullhorn',
    ICON_CHARTS:                    'chart-area',
    ICON_OFFER:                     'basket-shopping',
    LOGO_SIGNET:                    'logo_rg_white_signet.png',
    LOGO:                           'logo_rg.png',
    ERROR_LOGO:                     'rg_error_logo.png'
}

export  {
    WLCONFG
}
