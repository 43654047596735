<template>
    
        <div class="d-flex">
            <div class="wl-toggle-pill">
                <input type="checkbox" :checked="isChecked" :disabled="isDisabled" :id="id" :name="name" ref="checkbox" value="1" >                       
                <label :for="name" @click="update()" ></label>
            </div>
            <div class="wl-toggle-pill__label ml-2">
                {{label}}
            </div>
        </div>
</template>

<script>
import Vue from 'vue';

export default {
    name: "uiPill",
    emits: ['update'],

    props: {
            id: {
                type: String,
                default: () => Vue.utils.generateRandomHash(10)
            },

            label: {
                type: String,
                default: 'Label'
            },
            name: {
                type: String,
                default: 'name'
            },
            isChecked: {
                type: Boolean,
                default: false
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
    },

    methods: {
        update() {    
            const { checkbox } = this.$refs;
            if(checkbox.disabled )
                return;
            checkbox.checked  = !checkbox.checked
            this.$emit('update', {checked: checkbox.checked}); 
        }
    }
}
</script>