<template>
  <b-container fluid="xxl">
    <b-row class="mt-5">
        <b-col >
            <b-navbar-brand to="/dashboard" class="w-100">
                <b-img :src="getLogo()" width="210" fluid />
            </b-navbar-brand>

        </b-col>
        <b-col>
           <b-navbar class="wl-nav">
                <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown
                        class="mr-3"
                        text="Administration"
                        right
                        v-if="getUserLevel >= 30"
                    >
                    <template slot="button-content">
                        <font-awesome-icon
                            :icon="['fa', 'gears']"
                            class="cursor-pointer navlink"
                        />
                    </template>
                    

                        <b-dropdown-item
                            to="/adminProductList"
                        >{{ $t('NAV_LINK_TEXT_ADMIN_PRODUCT') }}</b-dropdown-item>

                        <b-dropdown-item 
                            to="/userList"
                        >{{ $t('NAV_LINK_TEXT_ADMIN_USER') }}</b-dropdown-item>
                        <b-dropdown-item
                            to="/companyList"
                        >{{ $t('NAV_LINK_TEXT_ADMIN_COMPANIES') }}</b-dropdown-item>


                    </b-nav-item-dropdown>

                    <b-nav-item href="/" class="wl-nav__log-out bg-cta rounded-circle">
                        <font-awesome-icon
                            :icon="['fa', 'sign-out-alt']"
                            
                        />
                    </b-nav-item>
                </b-navbar-nav>
            </b-navbar>
        </b-col>
    </b-row>
   
  </b-container>
</template>

<script>


import { mapGetters } from 'vuex';
//import * as CONFIG from '@/wl-config/wl-app-config';
const CONFIG =  require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/wl-app-config`)

export default {
  name: "viewHeader",
  data() {
    return {
      wlAppConstants: CONFIG.WLCONFG
    }
  },
  computed: {
    ...mapGetters([
      'getUserLevel'
    ]),
  }, methods: {
    getLogo() {   
      return require(`@/wl-config/${process.env.VUE_APP_WL_FOLDER}/assets/images/${this.wlAppConstants.LOGO}`);
    }
  }
}
</script>

<style scoped>
</style>