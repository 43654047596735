<template>

    <areaRecordingVue />

</template>

<script>

import areaRecordingVue from '@/components/areaRecording.vue';

export default {
  name: "viewTasks",
  components: {
        areaRecordingVue
  }
}
</script>

<style scoped>
</style>