<template>
  <b-container fluid="xxl">
    <b-row>
        <b-col cols="12" xl="6">         
          <h3>{{$t('PRODUCT_MANAGEMENT')}}</h3>
      </b-col>

      <b-col cols="12" xl="6"> 
        <b-form-group
            :label="$t('SEARCH')"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="md"
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('TERM')"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="cta">{{$t('DELETE')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>


    <b-table
        sticky-header
        striped hover
        :fields="fields"
        :items="getProductList"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        stacked="xl"
        show-empty
        class="mt-4"
        small
        @filtered="onFiltered"
    >

      <template #empty>
        <p class="w-100 text-center"><small>{{ $t('EMPTY_ITEMS_TABLE_MSG') }}</small></p>
      </template>
      <template #emptyfiltered>
        <p class="w-100 text-center"><small>{{ $t('EMPTY_FILTERED_ITEMS_TABLE_MSG') }}</small></p>
      </template>

      <template #cell(product_name)="data">

        <a
            v-if="data.item.product_url != ''"
            :href="data.item.product_url"
            class="btn-link link-primary"
            target="_blank"
        ><strong>{{ data.value }}</strong></a>

        <strong
          v-else
        >{{ data.value }}</strong>

      </template>

      <!--<template #cell(product_description)="data">

        <span v-html="data.value">{{ data.value }}</span>

      </template>-->

      <template #cell(product_type)="data">

        {{ data.value }}

      </template>

      <template #cell(product_price)="data">

        <span
          v-if="data.value == 0"
          >kostenlos</span>

        <span
          v-else
        ><s>{{ Number(data.item.product_pseudoprice).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} €</s> {{ Number(data.item.product_price).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} €</span>

      </template>

      <template #cell(sales)="data">

        {{ data.value }}

      </template>

      <template #cell(turnover)="data">

        {{ Number(data.value).toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} €

      </template>

      <template #cell(product_is_active)="data">

        <span :class="data.value == 1 ? 'wl-status wl-status--active': 'wl-status wl-status--inactive'"></span>
        <p v-if="data.item.product_access.length > 0">
          <small v-for="(product_access, product_access_index) in data.item.product_access"
            :key="product_access_index"
          >
            <span v-if="product_access_index > 0"><br/></span>{{ product_access.description.trim() }}</small>
        </p>

      </template>

      <template #cell(product_creation_date_formatted)="data">

        <span
            v-if="data.value !== '' && data.value != '00.00.0000'">{{ data.value }}</span>

        <span
            v-else>noch nie</span>

      </template>

      <template #cell(edit)="data">
        <b-button
                  type="button"
                  variant="cta"
                  class="button-cta rounded-circle icon small"
                  :to="'/productEdit/' + data.item.product_id"
              >
              <font-awesome-icon icon="fa-solid fa-pencil" /></b-button>

      </template>

    </b-table>

    <b-row>
      <b-col cols="12">
        <b-button to="/productEdit" type="button" variant="cta" class="button-cta mt-4">
            {{ $t('CREATE_NEW_PRODUCT') }}
        </b-button>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "areaAdminProductList",
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      fields: [
        {
          key: 'product_name',
          label: 'Produkt',
          sortable: true
        },
        /*{
          key: 'product_description',
          label: 'Beschreibung',
          sortable: true
        },*/
        {
          key: 'product_type',
          label: 'Produkt-Typ',
          sortable: true
        },
        {
          key: 'product_price',
          label: 'Preis',
          sortable: true
        },
        {
          key: 'sales',
          label: 'Käufe',
          sortable: true
        },
        {
          key: 'turnover',
          label: 'Umsatz',
          sortable: true
        },
        {
          key: 'product_is_active',
          label: 'Status',
          sortable: true
        },
        {
          key: 'product_creation_date_formatted',
          label: 'Erstelldatum',
          sortable: true
        },
        {
          key: 'edit',
          label: 'Bearbeiten',
          sortable: true
        }
      ],
      filter: null,
      filterOn: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 5000,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
    }
  },
  mounted() {

    this.loadProductList();
    this.loadProductTypes();
  },
  computed: {
    ...mapGetters([
      'getProductList',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([]),
    ...mapGetters([]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getFormattedDate(date) {

      var res = date.toISOString().slice(0, 10).split(/-/);
      return res[2] + '.' + res[1] + '.' + res[0];

    },

    async loadProductList() {

      //console.log('loadUserList');

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_productlist',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setProductList', []);

      } else {

        //console.log('response');
        console.log(response.data);
        this.$store.dispatch('setProductList', response.data);
        // Set the initial number of items
        this.totalRows = this.getProductList.length;

      }

    },

    async loadProductTypes() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_product_types',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setProductTypes', {});

      } else {

        //console.log('response');
        //console.log(response.data);
        this.$store.dispatch('setProductTypes', response.data);

      }

    },
  }
}
</script>

<style scoped>

</style>