<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">

  <g id="icon_forgotten" transform="translate(-543 -165)">
    <g transform="translate(545 165)">
      <g clip-path="url(#clip-path-2)">
        <path d="M4,7.981C4,7.253,3.99,6.55,4,5.848a5.992,5.992,0,0,1,11.982.192c0,.557,0,1.115,0,1.672v.28a4,4,0,0,1,2.242.7,3.883,3.883,0,0,1,1.751,3.3q.015,4,0,8a4,4,0,0,1-3.986,4q-5.975.011-11.951,0A3.992,3.992,0,0,1,0,19.941q0-3.933,0-7.867A3.994,3.994,0,0,1,3.833,8c.045,0,.089-.011.169-.021m9.99.011c0-.693,0-1.358,0-2.022a4,4,0,0,0-7.836-1.1A11.786,11.786,0,0,0,6,7.946c0,.013.022.027.039.046ZM8.979,14.086c.017-.1.024-.184.043-.262a.938.938,0,0,1,.943-.724.934.934,0,0,1,.977.675.957.957,0,0,1-.636,1.22,1.919,1.919,0,0,0-1.2,1.195A6.08,6.08,0,0,0,9,17.974h1.99c0-.31.012-.6,0-.891a.331.331,0,0,1,.244-.384,2.753,2.753,0,0,0,1.348-1.156,2.948,2.948,0,0,0-1.149-4.1,3.036,3.036,0,0,0-4.174,1.4,2.728,2.728,0,0,0-.246,1.242Zm2,4.935H9.01v1.953h1.966Z" transform="translate(0 0)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>

</template>

<script>
export default {
  name: "FPW",
};
</script>