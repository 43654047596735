<template>
   <b-container fluid="xxl">

    <b-row>
        <b-col cols="12" xl="6">         
          <h3>{{$t('COMPANIES')}}</h3>
      </b-col>

      <b-col cols="12" xl="6"> 
        <b-form-group
            :label="$t('SEARCH')"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="md"
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('TERM')"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="cta">{{$t('DELETE')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>
    

    <b-table
        sticky-header
        striped hover
        :fields="fields"
        :items="getCompanies"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        stacked="xl"
        show-empty
        class="mt-4"
        small
        @filtered="onFiltered"
    >

      <template #cell(company)="data">

        <span
          v-if="data.item.company.company == ''">{{ $t('NOT_SET') }}</span>

        <span
          v-else>{{ data.item.company.company }}</span>

      </template>

      <template #cell(products)="data">

        <div
          v-if="data.item.products.length > 0"
          >
          <div
              v-for="(product, pindex) in data.item.products"
              :key="pindex"
              >

            <div
              v-if="product.is_my_product">

              <div
                v-if="product.product.product_type_id == 1">

                {{ product.product.product_type }} - {{ product.product.product_name }}<br/>

                <ul>
                  <li
                      v-for="(checklist, cindex) in product.checklist"
                      :key="cindex"
                  >
                    <b-link
                        :to="'/myChecklistOverview/' + checklist.company_checklist_id"
                    >{{ checklist.checklist_name }}</b-link>
                  </li>


                </ul>
                
              </div>

              <p
                v-else>{{ product.product.product_type }} - {{ product.product.product_name }}</p>

              </div>

          </div>

        </div>

        <span v-else>{{ $t('NO_PRODUCTS_IN_LIST')}}</span>

      </template>

    </b-table>

    <b-row>
      <b-col cols="12">
        <b-button to="/userEdit" type="button" variant="cta" class="button-cta mt-4">
            {{ $t('CREATE_NEW_COMPANY') }}
        </b-button>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: "areaCompanyList",
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      fields: [
        {
          key: 'company',
          label: 'Firma',
          sortable: true
        },
        {
          key: 'products',
          label: 'Produkte',
          sortable: true
        },
      ],
      filter: null,
      filterOn: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 5000,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
    }
  },
  mounted() {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

    this.loadCompanies();
  },
  computed: {
    ...mapGetters([
      'getCompanies',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
        'setCompanies',
    ]),
    ...mapGetters([]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getFormattedDate(date) {

      var res = date.toISOString().slice(0, 10).split(/-/);
      return res[2] + '.' + res[1] + '.' + res[0];

    },
    async loadCompanies() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_company_list_and_products',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setCompanies', []);

      } else {

        //console.log('response');
        //console.log(response.data);
        this.$store.dispatch('setCompanies', response.data);
        // Set the initial number of items
        this.totalRows = Object.keys(this.getCompanies).length;

      }

    },

  }
}
</script>

<style scoped>

</style>