const state = {

    userList: [],
    userList2: [
        {
            company: {
                name: 'DSM8',
                additional_address: '',
                street: 'Leipziger Platz 1',
                plz: '12234',
                city: 'Berlin'
            },
            name: {
                firstname: 'Holger',
                lastname: 'Schmidt',
                email: 'asfadsf@asdfdsf.de',
                telephone: '12234',
                salutation: 1,
                salutation_str: 'Herr'
            },
            main_categories: [
                {
                    main_category_id: 1,
                    main_category: 'Indoor'
                },
                {
                    main_category_id: 2,
                    main_category: 'Outdoor'
                }
            ],
            sub_categories: [
                {
                    sub_category_id: 1,
                    sub_category: 'Bühne'
                },
                {
                    sub_category_id: 2,
                    sub_category: 'Sightseeing'
                }
            ],
            type: 'Admin',
            type_id: 1,
            status: 1,
            last_login: '22.02.2022',
            last_insert: '22.02.2022',
            user_id: 8,
            edit: 1
        },
        {
            company: {
                name: 'Banane',
                additional_address: '',
                street: 'Leipziger Platz 1',
                plz: '12234',
                city: 'Berlin'
            },
            name: {
                firstname: 'Holger',
                lastname: 'Schmidt',
                email: 'asfadsf@asdfdsf.de',
                telephone: '12234',
                salutation: 1,
                salutation_str: 'Herr'
            },
            main_categories: [
                {
                    main_category_id: 1,
                    main_category: 'Indoor'
                },
                {
                    main_category_id: 2,
                    main_category: 'Outdoor'
                }
            ],
            sub_categories: [
                {
                    sub_category_id: 1,
                    sub_category: 'Bühne'
                },
                {
                    sub_category_id: 2,
                    sub_category: 'Sightseeing'
                }
            ],
            type: 'Admin',
            type_id: 1,
            status: 1,
            last_login: '22.02.2022',
            last_insert: '22.02.2022',
            user_id: 6,
            edit: 1
        },
        {
            company: {
                name: 'xxx',
                additional_address: '',
                street: 'Leipziger Platz 1',
                plz: '12234',
                city: 'Berlin'
            },
            name: {
                firstname: 'Holger',
                lastname: 'Schmidt',
                email: 'asfadsf@asdfdsf.de',
                telephone: '12234',
                salutation: 1,
                salutation_str: 'Herr'
            },
            main_categories: [
                {
                    main_category_id: 1,
                    main_category: 'Indoor'
                },
                {
                    main_category_id: 2,
                    main_category: 'Outdoor'
                }
            ],
            sub_categories: [
                {
                    sub_category_id: 1,
                    sub_category: 'Bühne'
                },
                {
                    sub_category_id: 2,
                    sub_category: 'Sightseeing'
                }
            ],
            type: 'Admin',
            type_id: 1,
            status: 1,
            last_login: '22.02.2022',
            last_insert: '22.02.2022',
            user_id: 2,
            edit: 1
        },
    ],
    main_categories: [
        {
            main_category_id: 1,
            main_category: 'Hauptkategorie 1',
            active: false,
        },
        {
            main_category_id: 2,
            main_category: 'Hauptkategorie 2',
            active: false,
        }
    ],
    sub_categories: [
        {
            sub_category_id: 3,
            sub_category: 'Unterkategorie 1',
            active: false,
        },
        {
            sub_category_id: 4,
            sub_category: 'Unterkategorie 2',
            active: false,
        },
        {
            sub_category_id: 5,
            sub_category: 'Unterkategorie 3',
            active: false,
        },
        {
            sub_category_id: 6,
            sub_category: 'Unterkategorie 4',
            active: false,
        }
    ],
    recordingDates: {},
    recordingYears: {},
    recordingMonths: {},
    recordingStartDate: '',
    recordingEndDate: '',
    records: {},
    statisticFilterButtons: {},
    companies: [],
}

const mutations = {
    SET_NEW_USER(state, payload) {
        //console.log('setze Monat: ' + payload);

        if (payload.index < 0) {

            state.userList.push(payload.user);
            console.log(state.userList);
            return;
        }

        state.userList[payload.index] = payload.user;
    },

    SET_RECORDING_DATES(state, payload) {
        //month
        let months = {};
        const keys = Object.keys(payload);
        keys.forEach((key) => {

            if (months[payload[key]['yearmonth']] === undefined) {
            months[payload[key]['yearmonth']] = payload[key];
            }
        });
        
        this.commit('SET_RECORDING_MONTHS', months);

        state.recordingDates = payload;
    },
    SET_RECORDING_START_DATE(state, payload) {
        state.recordingStartDate = payload;
    },
    SET_RECORDING_END_DATE(state, payload) {
        state.recordingEndDate = payload;
    },
    SET_RECORDS(state, payload) {
        state.records = payload;
    },
    SET_USERLIST(state, payload) {
        state.userList = payload;
    },
    SET_RECORDING_MONTHS(state, payload) {
        state.recordingMonths = payload;
    },

    SET_CATEGORY_ACTIVE(state, payload) {

        var type = payload.type;
        var category_id = payload.category_id;
        var active = payload.active;
        let data = {};
        var key = '';
        var idname = '';

        if (type == 'main') {
            data = state.main_categories;
            key = 'main_categories';
            idname = 'main_category_id';
        } else {
            data = state.sub_categories;
            key = 'sub_categories';
            idname = 'sub_category_id';
        }

        //console.log(category_id, idname, data);

        Object.keys(data).forEach(el => {
            console.log(el, data[el][idname]);

            if (data[el][idname] === category_id) {
                state[key][el].active = active;
            }
        });

    },

    SET_STATISTIC_FILTER_BUTTONS(state, payload) {
        state.statisticFilterButtons = payload;
    },

    SET_CATEGORIES(state, payload) {
        state.main_categories = payload.main_categories;
        state.sub_categories = payload.sub_categories;
    },

    SET_COMPANIES(state, payload) {
        state.companies = payload;
    },
}

const actions = {

    setNewUser({commit}, payload) {
        commit('SET_NEW_USER', payload);
    },

    setRecordingDates({commit}, payload) {
        commit('SET_RECORDING_DATES', payload);
    },

    setRecordingStartDate({commit}, payload) {
        //console.log(payload);
        commit('SET_RECORDING_START_DATE', payload);
    },

    setRecordingEndDate({commit}, payload) {
        commit('SET_RECORDING_END_DATE', payload);
    },

    setRecords({commit}, payload) {
        commit('SET_RECORDS', payload);
    },

    setUserList({commit}, payload) {
        commit('SET_USERLIST', payload);
    },

    setRecordingMonths({commit}, payload) {
        commit('SET_RECORDING_MONTHS', payload);
    },

    setCategoryActive({commit}, payload) {
        commit('SET_CATEGORY_ACTIVE', payload);
    },

    setStatisticFilterButtons({commit}, payload) {
        commit('SET_STATISTIC_FILTER_BUTTONS', payload);
    },

    setCategories({commit}, payload) {
        commit('SET_CATEGORIES', payload);
    },

    setCompanies({commit}, payload) {
        commit('SET_COMPANIES', payload);
    },

}

const getters = {

    userList: state => state.userList,
    userListById2: state => user_id => state.userList.find(user => user.user_id === user_id),

    userListById(user_id) {

        console.log(user_id);

        let userList = state.userList;

        Object.keys(userList).forEach(el => {
            console.log(el, userList[el].user_id);
        });

    },


    getRecordDataWeekly() {
        const dates = state.recordingDates;
        const records = state.records;
  
    
        let turnoverCounter = 0;
        let visitorCounter = 0;
        const datesCounter = dates.length;
        
  
        if (records.turnover !== undefined) {
          turnoverCounter = Object.keys(records.turnover).length;
        }
  
        if (records.visitor !== undefined) {
          visitorCounter = Object.keys(records.visitor).length;
        }
          
        const diff = ( datesCounter * 2 ) - ( turnoverCounter + visitorCounter );
       
        return {
            total:  datesCounter * 2,
            sum : diff,
            visitor: datesCounter - visitorCounter,
            turnover:  datesCounter - turnoverCounter
        }
       
    },

    getRecordDataMonthly() {

        const months = state.recordingMonths;
        const records = state.records;
  
    
        let turnoverCounter = 0;
        let visitorCounter = 0;
        const monthsCounter = Object.keys(months).length;
        
  
        if (records.turnoverMonth !== undefined) {
            turnoverCounter = Object.keys(records.turnoverMonth).length;
        }
        
        if (records.visitorMonth !== undefined) {
            visitorCounter = Object.keys(records.visitorMonth).length;
        }
          
        const diff = ( monthsCounter * 2 ) - ( turnoverCounter + visitorCounter );
       
        return {
            total:  monthsCounter * 2,
            sum : diff,
            visitor: monthsCounter - visitorCounter,
            turnover:  monthsCounter - turnoverCounter
        }
  
       
    },
    getUserList() {
        return state.userList;
    },
    getMainCategories() {
        return state.main_categories;
    },
    getSubCategories() {
        return state.sub_categories;
    },

    getRecordingDates() {
        return state.recordingDates;
    },
    getRecordingYears() {
        return state.recordingYears;
    },
    getRecordingStartDate() {
        return state.recordingStartDate;
    },
    getRecordingEndDate() {
        return state.recordingEndDate;
    },
    getRecords() {
        return state.records;
    },
    getRecordingMonths() {
        return state.recordingMonths;
    },

    getStatisticFilterButtons() {
        return state.statisticFilterButtons;
    },

    getCompanies() {
        return state.companies;
    },


}

const userList = {
    state,
    mutations,
    actions,
    getters

}

export default userList;