<template>
    <div  class="wl-sticky" ref="stickyelement" :class="{ 'wl-sticky--position-reset': isSticky }">
        <div :class="{ 'wl-sticky--issticky': isSticky }" :style="cssStyles">            
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: "hocSticky",
    data() {
    return{
      cssStyles: '',
      scrollY: null,
      offsetTop: 0,
      isSticky: false,
      dims: {},
      timeoutid: 0
    }
  },
  props: {
    offset: {
      type: String,
      default: '0'
    }

  },
  mounted() {

      

      const {stickyelement} = this.$refs;
      window.addEventListener('scroll', () => {
        this.scrollY = Math.round(window.scrollY);
      });

      window.addEventListener("resize",  () => {
        clearTimeout(this.timeoutid);
        this.timeoutid = setTimeout( () => {
          if(stickyelement) {
            window.scroll = 0;
            
            this.dims = stickyelement.getBoundingClientRect();
            this.offsetTop = this.dims.top + parseInt(this.offset);
            if(this.isSticky) this.cssStyles = `transform: translateX(${ this.dims.left   - window.scrollX}px);`;
            this.scrollY = Math.round(window.scrollY);
          }
        }, 100)
       
      });

      this.dims = stickyelement.getBoundingClientRect();
      this.offsetTop = this.dims.top + parseInt(this.offset);

      
  },
  watch: {
    scrollY(newValue) {
      
      if (newValue > this.offsetTop ) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    isSticky(newValue) {
      if(newValue) {
   
        this.cssStyles = `transform: translateX(${ this.dims.left  - window.scrollX}px);`;
      } else {
        this.cssStyles = '';
      }
    }
  }

}
</script>
