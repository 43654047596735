<template>


    <!-- TABS -->
    <b-tabs content-class="" class="wl-tabs wl-tabs__login">

        <!-- TAB ANMELDUNG -->
        <b-tab :title="$t('FORM_LOGIN_TITLE')" active class="position-relative">
            <hocAnimatedOverlay ref="overlay">
                <div class="justify-content-md-center">
                    <hocCard :title="$t('PW_FORGOTTEN_TITLE')" :subtitle="$t('PAGE_TITLE')" variant="transparent" :insert-logo="true">
                        <template #default><areaPasswordForgotten/></template>
                    </hocCard>
                </div>
            </hocAnimatedOverlay>
            <hocCard :title="$t('FORM_LOGIN_TITLE')" :subtitle="$t('PAGE_TITLE')" variant="primary" :insert-logo="true">
                <template #default>
                    <areaLogin/>
                    <div class="text-center">
                        <b-link to="#" v-on:click="openOverlay" class="d-block mt-1 text-light"><small>{{ $t('PW_FORGOTTEN_TITLE') }}</small></b-link>      
                    </div>
                </template>               
            </hocCard>
           
            
        </b-tab>
        <!-- END TAB ANMELDUNG-->

        <!-- TAB REGISTRIERUNG -->

        <b-tab :title="$t('FORM_REGISTRATION_TITLE')">
             <hocCard :title="$t('FORM_REGISTRATION_TITLE')" :subtitle="$t('PAGE_TITLE')" variant="primary" :insert-logo="true">
                <template #default><areaRegistration/></template>
            </hocCard>
        </b-tab>

        <!-- END TAB REGISTRIERUNG-->

    </b-tabs>
    <!-- END TABS-->

</template>


<script>

import hocAnimatedOverlay from '@/components/hoc/hocAnimatedOverlay';
import hocCard from '@/components/hoc/hocCard';
import areaLogin from '@/components/areaLogin';
import areaPasswordForgotten from '@/components/areaPasswordForgotten';
import areaRegistration from '@/components/areaRegistration';

export default {
    name: "viewLoginOrSignup",
    components: {
        hocAnimatedOverlay,
        hocCard,
        areaLogin,
        areaPasswordForgotten,
        areaRegistration

    },
    methods: {
        openOverlay() {
            const { overlay } = this.$refs;
            overlay.show();
        }
    
    },
    mounted () {

        if (this.$store.getters.getIsLoggedIn) {
            this.$router.push('/dashboard');
        }

    },
}
</script>

<style scoped>
</style>