<template>
    <div class="wl-audioplayer d-flex align-items-end justify-content-center" :style="`background-image: url(${poster})`">

        <b-card tag="div" class="shadow h-100 wl-audioplayer__player-wrapper w-100 mt-7 mr-2 ml-2" :class="`bg-${variant}`">
            <div class="wl-audioplayer__player-body  d-flex align-items-center">
                
                <button class="wl-audioplayer__play-button mr-3" ref="playbutton"></button>
                <div class="wl-audioplayer__waveform" ref="waveform"></div>
            
            </div>
            <div class="wl-audioplayer__controls">
                <div class="wl-audioplayer__volume">
                    <div ref="volumeicon" id="volumeIcon" class="wl-audioplayer__volume__toggle mr-2"></div>
                    <input ref="volumeslider"
                        id="volumeSlider"
                        class="wl-audioplayer__volume__slider mr-3"
                        type="range"
                        name="volume-slider"
                        min="0"
                        max="100"
                        value="50"
                    />
                </div>
                <div class="wl-audioplayer__timecodes ml-2">
                    <span id="currentTime">{{ getCurrentTime }}</span> | <span id="totalDuration">{{ totalDuration }}</span>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>

import Vue from 'vue'
import WaveSurfer from 'wavesurfer.js';
import hocCard from '@/components/hoc/hocCard';

export default {
    name: "uiAudioPlayer",
    mixins: [hocCard],
    emits: ['ready', 'update', 'played', 'paused', 'ended'],
     data() {
      return {
        playerinstance: null,
        intervalID: 0,
        isPlaying: false,
        isMuted: false,
        totalDuration: '',
        getCurrentTime: this.$utils.formatTimecode(this.currenttime)
      }
   },
   computed: {
        playtime() {
            return this.getCurrentTime
        }
   },
   props: {
        useCallBack: {
            type: Boolean,
            fedault:false
        },
        refHash: {
            type: String,
            default: ''
        },
        audioid: {
            type: Number,
            default:-1
        },
        interval: {
            type: Number,
            default: 2000
        },
        currenttime: {
            type: Number,
            default: 0
        },
        poster: {
            type: String,
            default: ''
        },
        audio: {
            type: String,
            default: ''
         },
         id: {
            type: String,
            default: () => Vue.utils.generateRandomHash(10)
         },
         shouldPlay:{
            type: Boolean,
            fedault:false
        }
  }, 
  methods: {
    updatePlayer() {    
        this.$emit('update', {time:  this.playerinstance.getCurrentTime(), id: this.audioid});
  
       
    },
    createPlayer() {
        
    
        const { waveform, volumeslider, playbutton, volumeicon } = this.$refs;
   
        
        if(this.playerinstance) {
            this.playerinstance.destroy();
            this.playerinstance.empty();
            this.playerinstance = null;
        }
        
   
        volumeslider.addEventListener("input", this.handleVolumeChange);
        playbutton.addEventListener("click", this.togglePlay)
        volumeicon.addEventListener("click", this.toggleMute)

        const root = document.querySelector(':root');
        this.playerinstance = WaveSurfer.create({
            container: waveform,
            responsive: true,
            height: 90,
            backgroundColor: getComputedStyle(root).getPropertyValue('--primary'),
            waveColor: getComputedStyle(root).getPropertyValue('--antiflash'),
            progressColor: getComputedStyle(root).getPropertyValue('--cta'),
            cursorColor: getComputedStyle(root).getPropertyValue('--secondary'),
            barGap: 1,
            barMinHeight: 50,
            barRadius:3,
            barWidth: 3,
            
        });

        
         
        this.playerinstance.on("ready", () => {
            this.playerinstance.setVolume(volumeslider.value / 100)
            this.totalDuration =  this.$utils.formatTimecode(this.playerinstance.getDuration());
            this.playerinstance.seekTo(this.currenttime / this.playerinstance.getDuration())

            this.$emit('ready');  

            if(this.shouldPlay)  {    
                this.playerinstance.play();
                playbutton.classList.add('wl-audioplayer__play-button--playing');
            }  
            
        });

        this.playerinstance.on("audioprocess", () => {
            const time = this.playerinstance.getCurrentTime();
            this.getCurrentTime = this.$utils.formatTimecode(time);
              
          
           
        });

        this.playerinstance.on("seek", () => {
            const time = this.playerinstance.getCurrentTime();
            this.getCurrentTime = this.$utils.formatTimecode(time);
            if(this.useCallBack) {
                if(!this.playerinstance.isPlaying()) {
                    clearInterval(this.intervalID);  
                   
                }
                this.updatePlayer();
            }
        }); 

        if(this.useCallBack) {
            this.playerinstance.on("play", () => {
                this.intervalID = setInterval(() => {
                    this.updatePlayer()
                }, this.interval);      
                this.$emit('played');  
            }); 

            this.playerinstance.on("pause", () => {
                clearInterval(this.intervalID);
                this.$emit('paused', {time:  this.playerinstance.getCurrentTime(), id: this.audioid});   
            });  
           
        }

        this.playerinstance.on("finish", () => {
            
            playbutton.classList.remove('wl-audioplayer__play-button--playing');
            if(this.useCallBack) {
                this.$emit('ended',{time:  this.playerinstance.getCurrentTime(), id: this.audioid});  
                clearInterval(this.intervalID);
                this.updatePlayer()   
            }
        })
        
        this.startPlayer();
       
    },
    togglePlay() {
        const  {playbutton} = this.$refs;
        this.playerinstance.playPause()
        this.isPlaying = this.playerinstance.isPlaying();
        if (this.isPlaying) {
            playbutton.classList.add('wl-audioplayer__play-button--playing');
        } else {
            playbutton.classList.remove('wl-audioplayer__play-button--playing');
        }

    },

    setPause() {
        if(this.useCallBack) {
            clearInterval(this.intervalID);
            this.$emit('paused', {time:  this.playerinstance.getCurrentTime(), id: this.audioid});   
        }
       
        const  {playbutton} = this.$refs;
        this.playerinstance.pause();        
        
        playbutton.classList.remove('wl-audioplayer__play-button--playing');
       

    },
    handleVolumeChange (e) {
        const volume = e.target.value / 100
        this.playerinstance.setVolume(volume)
    },
    
    toggleMute() {
        const  {volumeslider, volumeicon } = this.$refs;
        this.playerinstance.toggleMute();
        this.isMuted = this.playerinstance.getMute();

        if(this.isMuted) {
            volumeicon.classList.add('wl-audioplayer__volume__toggle--muted');
            volumeslider.disabled = true
        } else {
            volumeslider.disabled = false
            volumeicon.classList.remove('wl-audioplayer__volume__toggle--muted');
        }
      
    },
    startPlayer() {
        this.playerinstance.load(this.audio);    
        
    }
  },
  mounted() {
    this.createPlayer();
  },
   watch: {
    refHash(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.startPlayer();
      }
    },
  },
  beforeDestroy() {
    
    if(this.playerinstance) {
        this.playerinstance.destroy();
    }
    clearInterval(this.intervalID);
  }

}
</script>
