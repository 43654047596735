<template>
    <b-carousel-slide>
        <template #img>
            <h6 class="font-light">{{ productType }}</h6>
            <h5>{{ productTitle }}</h5>
            <p v-html="productDescription"></p>
            <div class="mb-2" v-if="productBtnLabel">

                <b-button @click="$emit('submit', { url: productCtaLink, type: productType, id: productId, product: product})"  type="button" variant="cta" class="button-cta mt-4">
                    <span v-html="productBtnLabel"></span>
                </b-button>
            
            </div>
            
        </template>
    </b-carousel-slide>
</template>


<script>
import Vue from 'vue';
export default {
  name: "CardSliderItem",
  emits: ['submit'],
  props: {
    product:{
      type: Object,
    },
    productId:{
        type: String,
        default:''
    },
    productTitle: {
        type: String,
        default:''
    },
    productType: {
        type: String,
        default:''
    },
    productAction: {
        type: String,
        default:''
    },
    productCtaLink:  {
        type: String,
        default:''
    },
    productBtnLabel: {
        type: String,
        default:''
    },
    productDescription: {
        type: String,
        default:''
    },
    id: {
        type: String,
        default: () => Vue.utils.generateRandomHash(10)
    }
  },
  methods: {
    /*
    clickNewsButton(url) {

      window.open(url,'_blank');

    }

     */
  }
}
</script>