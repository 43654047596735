<template>
<b-container fluid="xxl">
  <b-row>
   
    <b-col cols="12">
      <b-tabs content-class="" class="wl-tabs wl-tabs__modal">
        <!-- TAB AGB-->
        <b-tab :title="$t('TERMS_TITLE')" active class="position-relative">
       
          <p class="mt-4">A. Allgemeine Bedingungen für Lieferung und Leistung</p>
          <p class="font-bold mt-4">§2 Geltung der AGB</p>
          <p>A. Diese Allgemeinen Geschäftsbedingungen gelten für alle Lieferungen und Leistungen die MyShopBooster für den Kunden erbringt.</p>
          <p>B. Entgegenstehende oder abweichende Bedingungen des Kunden erkennt MyShopBooster nicht an, es sei denn ihrer Geltung wurde ausdrücklich schriftlich zugestimmt. Dies gilt auch dann, wenn MyShopBooster in Kenntnis entgegenstehender Bedingungen des Kunden die Lieferung oder Leistung an den Kunden vorbehaltlos ausführt.</p>
          <p>C. Soweit nicht etwas anderes bestimmt ist, gelten vorrangig die individuell zwischen den Parteien getroffenen Vereinbarungen.</p>
          <p>D. Änderungen dieser AGB werden dem Kunden schriftlich bekannt gegeben. Sie gelten als genehmigt, wenn der Kunde nicht schriftlich Widerspruch erhebt. Der Widerspruch ist innerhalb von sechs Wochen nach Bekanntgabe an MyShopBooster abzusenden. MyShopBooster wird den Kunden bei der Bekanntgabe auf diese Folgen hinwiesen.</p>
          <p class="font-bold mt-4">§3 Angebot und Vertragsschluss</p>
          <p>(1) Art, Umfang und Spezifikation der von MyShopBooster zu erbringenden Leistungen sowie Liefer- und Ausführungsfristen ergeben sich aus dem diesen Geschäftsbedingungen zugrunde gelegten Angebot, sowie der dazugehörigen Leistungsbeschreibung und der Preisübersicht die auf diese AGB Bezug nehmen.</p>
          <p>(2) Die Angebote und Kostenvoranschläge von MyShopBooster sind grundsätzlich unverbindlich, es sei denn, MyShopBooster hat sie ausdrücklich und in schriftlicher Form als verbindlich bezeichnet.</p>
          <p class="font-bold mt-4">§4 Leistungserbringung</p>
          <p>E. MyShopBooster erbringt seine Leistungen nach Maßgabe der vertraglichen Vereinbarungen umsichtig und mit der Sorgfalt eines ordentlichen Kaufmanns, sowie nach einem angemessenen Stand der Technik.</p>
          <p>F. MyShopBooster bestimmt und verantwortet die Art und Weise der Durchführung seiner Leistungen selbst; er kann Leistungen im Rahmen der gesetzlichen Anforderungen an Unterauftragnehmer vergeben.</p>
          <p>G. Soweit nicht ausdrücklich schriftlich etwas anderes vereinbart wurde, liegt die Verantwortung für die Auswahl bestellter Liefergegenstände bzw. Leistungen und für die mit ihnen beabsichtigten Ergebnisse allein beim Kunden.</p>
          <p>H. Sind die versprochenen Leistungen nicht verfügbar, weil MyShopBooster von ihren Unterlieferanten nicht beliefert wurde und der Vorrat von MyShopBooster an den betreffenden Leistungsgegenständen erschöpft ist, ist dieser berechtigt, in Qualität und Preis gleichwertige Leistungen zu erbringen. Ist die Erbringung von preislich und qualitativ mindestens gleichwertigen Leistungen nicht möglich, so kann MyShopBooster sich vom Vertrag lösen und braucht die versprochenen Leistungen nicht zu erbringen. MyShopBooster verpflichtet sich für diesen Fall, den Kunden unverzüglich über die Nichtverfügbarkeit zu informieren und eine gegebenenfalls bereits geleistete Zahlung dem Kunden unverzüglich zurückzuerstatten.</p>
          <p class="font-bold mt-4">§5 Verzug und Termine für Lieferungen und Leistungen</p>
          <p>I. Liefer- und Leistungszeitangaben sind nur verbindlich, wenn sie von MyShopBooster schriftlich bestätigt worden sind und der Kunde MyShopBooster alle zur Ausführung der Leistung oder Lieferungen erforderlichen Informationen und Unterlagen rechtzeitig mitgeteilt bzw. zur Verfügung gestellt und seine Mitwirkungspflichten ordnungsgemäß und rechtzeitig erfüllt hat.</p>
          <p>J. Bei nachträglichen Auftragsänderungen ist MyShopBooster berechtigt, verbindlich vereinbarte Termine und Aufwände anzupassen.</p>
          <p>K. Liefer- und Leistungszeiten verlängern sich in angemessenem Umfang, wenn MyShopBooster an der Erfüllung ihrer Verpflichtung durch höhere Gewalt oder sonstige unvorhersehbare und/oder außergewöhnliche Ereignisse gehindert wird, die außerhalb des Einflussbereiches von MyShopBooster liegen und trotz zumutbarer Sorgfalt nicht abgewendet werden können. Während des Vorliegens von Ereignissen im Sinne des Satzes 1 sind sämtliche Service Level ausgesetzt.</p>
          <p>L. MyShopBooster ist zu Teillieferungen und Teilleistungen berechtigt, soweit dies für den Kunden zumutbar ist.</p>
          <p class="font-bold mt-4">§6 Abnahme</p>
          <p>M. Von MyShopBooster für den Kunden erbrachte Werk- und Installationsleistungen sind unverzüglich nach Übergabe durch MyShopBooster schriftlich vom Kunden abzunehmen.</p>
          <p>N. Unwesentliche Mängel berechtigen nicht zur Verweigerung der Abnahme.</p>
          <p>O. Solange MyShopBooster die schriftliche Abnahmeerklärung des Kunden nicht vorliegt, ist der Kunde nicht berechtigt die erbrachten Leistungen produktiv einzusetzen. Bringt der Kunde das Werk dennoch zum produktiven Einsatz oder nimmt er ohne Zustimmung von MyShopBooster daran Änderungen vor, gilt dies als Abnahme.</p>
          <p>P. Unterlässt der Kunde die Abnahme aus anderen Gründen als wegen eines Mangels, gilt die Abnahme als erklärt, wenn der Kunde nicht innerhalb einer Frist von vier Wochen nach der Bereitstellung zur Abnahme, die Abnahme schriftlich unter Angabe von berechtigten Gründen verweigert.</p>
          <p class="font-bold mt-4">§7 Vertragsdauer und Kündigung</p>
          <p>(1) Vertragsbeginn und Vertragsdauer ebenso wie Kündigungsfristen ergeben sich aus dem jeweiligen Angebot, dem Leistungsschein oder individuellen Vereinbarungen.</p>
          <p>(2) Soweit nichts Gegenteiliges geregelt ist, läuft der Vertrag auf unbestimmte Zeit und kann von beiden Seiten jederzeit unter Einhaltung einer Kündigungsfrist von 30 Tagen gekündigt werden.</p>
          <p>Q. Das Recht, den Vertrag bei Vorliegen eines wichtigen Grundes außerordentlich und fristlos zu kündigen, bleibt unberührt. Ein wichtiger Grund liegt für MyShopBooster insbesondere in jedem Fall vor, in dem</p>
          <p>(a) der Kunde für zwei aufeinander folgende Termine mit der Entrichtung der vereinbarten Vergütung im Verzug ist oder der Kunde in einem Zeitraum, der sich über mehr als zwei Termine erstreckt, mit der Entrichtung der Vergütung in Höhe des Betrages in Verzug gekommen ist, welcher Vergütung für zwei Monate entspricht;</p>
          <p>(b) der Kunde zahlungsunfähig oder über sein Vermögen ein Insolvenzverfahren eröffnet oder mangels Masse der Antrag auf Eröffnung eines Insolvenzverfahrens abgewiesen worden ist. Nach Antrag auf Eröffnung des Insolvenzverfahrens über das Vermögen des Kunden darf MyShopBooster jedoch nicht wegen eines Verzuges mit der Entrichtung der Vergütung, der in der Zeit vor dem Eröffnungsantrag eingetreten ist, oder wegen einer Verschlechterung der Vermögensverhältnisse des Kunden kündigen;</p>
          <p>(c) der Kunde gegen wesentliche vertragliche Pflichten verstößt, insbesondere die vertragliche Pflicht, bei der Nutzung der vertraglichen Leistungen von MyShopBooster das Recht zu beachten, und diesen Verstoß auch nach Abmahnung oder Benachrichtigung über die Sperrung der Inhalte durch MyShopBooster nicht unverzüglich abstellt;</p>
          <p>(d) ein von MyShopBooster eingesetzter Subunternehmer oder Lieferant seinerseits das zwischen ihm und MyShopBooster bestehende Vertragsverhältnis aus Gründen, die nicht MyShopBooster zu verantworten hat, kündigt oder beendet.</p>
          <p>R. Kündigungen bedürfen der Schriftform. Die Zusendung einer Kündigung per E-Mail ist ausreichend.</p>
          <p class="font-bold mt-4">§8 Vergütung und Zahlungsweise</p>
          <p>S. Die Höhe der vom Kunden zu zahlenden Vergütung richtet sich nach den im Produktkatalog und im Preisverzeichnis bzw. in dem Angebot oder in sonstigen individualvertraglichen Vereinbarungen getroffenen Regelungen.</p>
          <p>T. Alle Preise verstehen sich, sofern nicht etwas anderes ausdrücklich bestimmt ist, als Nettopreise in Euro zuzüglich der jeweils geltenden gesetzlichen Umsatzsteuer. Sollte sich die Umsatzsteuer erhöhen, ist MyShopBooster berechtigt, diese Erhöhung entsprechend an die Kunden weiterzugeben bzw. etwaig vereinbarte Bruttopreise entsprechend der gesetzlichen Erhöhung des Steuersatzes anzupassen.</p>
          <p>U. MyShopBooster ist berechtigt, Teilleistungen in Rechnung zu stellen.</p>
          <p>V. Sämtliche Rechnungen sind mit dem ausgewiesenen Rechnungsbetrag ohne Abzug innerhalb von 14 Kalendertagen ab Rechnungsdatum zur Zahlung fällig.</p>
          <p>W. Soweit keine anderweitigen individuellen Vereinbarungen getroffen wurden, wird die vereinbarte Vergütung im Einzugsermächtigungsverfahren vom Konto des Kunden eingezogen. Der Kunde erteilt MyShopBooster eine entsprechende Einzugsermächtigung. Bei Nichterteilung oder Widerruf der Einzugsermächtigung ist MyShopBooster berechtigt, für administrative Aufwände je Rechnungslegung ein Bearbeitungsentgelt in Höhe von 2,50 EUR zu verlangen. Bei Rückbelastungen einer Lastschrift ist MyShopBooster berechtigt, die entstehenden Kosten vom Kunden zurückzuverlangen.</p>
          <p>X. Gerät der Kunde mit der Zahlung fälliger Rechnungen in Verzug, so ist er zur Zahlung von Verzugszinsen in Höhe von 5 % über dem jeweiligen Basiszins verpflichtet, sofern der Kunde nicht nachweist, dass der Zinsschaden, der MyShopBooster entstanden ist, geringer ist. Die Geltendmachung weiterer Verzugsansprüche von MyShopBooster ist nicht ausgeschlossen.</p>
          <p>Y. Aufrechnungsrechte stehen dem Kunden nur zu, wenn seine Gegenansprüche rechtskräftig festgestellt, unbestritten oder von MyShopBooster schriftlich anerkannt sind. Zur Ausübung eines Zurückbehaltungsrechts ist der Kunde nur insoweit befugt, als sein Gegenanspruch auf dem gleichen Vertragsverhältnis beruht.</p>
          <p>Z. MyShopBooster ist berechtigt, die Vergütung für die von ihr angebotenen Leistungen erstmalig drei Monate nach Abschluss des Vertrages zu erhöhen. Die Erhöhung ist an die an MyShopBooster aufgrund der allgemeinen Preisentwicklung entstehende Kostensteigerung anzupassen. Sie wird einen Monat nach ihrer Mitteilung wirksam. Der Kunde kann für den Zeitpunkt des Wirksamwerdens außerordentlich kündigen. MyShopBooster weist den Kunden auf dieses Kündigungsrecht hin.</p>
          <p class="font-bold mt-4">§9 Haftung und Schadensersatz</p>
          <p>AA. MyShopBooster haftet für vom Kunden geltend gemachte Schadensersatzansprüche insoweit, als</p>
          <p>(a) ihm, seinen gesetzlichen Vertretern oder Erfüllungsgehilfen Vorsatz oder grobe Fahrlässigkeit zur Last fällt,</p>
          <p>(b) der Schaden auf das Fehlen einer garantierten Beschaffenheit oder die sonstige Nichterfüllung einer ausdrücklich gewährten Garantie zurückgeht, soweit der beschriebene Garantiefall eingetreten ist und der Kunde durch die Garantie gerade vor dem eingetretenen Schaden geschützt werden sollte,</p>
          <p>(c) es sich um schuldhaft verursachte Schäden aus einer Verletzung des Lebens, des Körpers oder der Gesundheit, oder</p>
          <p>(d) um Ansprüche aus dem Produkthaftungsgesetz, oder</p>
          <p>(e) um Ansprüche aufgrund sonstiger zwingender gesetzlicher Haftungsvorschriften handelt.</p>
          <p>BB. Darüber hinaus haftet MyShopBooster auch für solche Schäden, die sie in Verletzung einer wesentlichen Vertragspflicht (Kardinalpflicht) verursacht hat, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Kunde vertrauen darf; in diesen Fällen ist die Haftung jedoch der Höhe nach beschränkt auf den typischerweise voraussehbaren Schaden.</p>
          <p>CC. Die Haftung wegen Unterbrechung, Störung oder sonstiger schadensverursachender Ereignisse, die auf Telekommunikationsdienstleistungen von MyShopBooster oder Dritten, für die MyShopBooster haftet, beruhen, ist beschränkt auf die Höhe des für MyShopBooster möglichen Rückgriffs gegen den jeweiligen Telekommunikationsdienstleistungsanbieter. MyShopBooster haftet nicht für die Funktionsfähigkeit der Telefonleitungen zu dem vertragsgegenständlichen Server, bei Stromausfällen und bei Ausfällen von Servern, die nicht in seinem Einflussbereich stehen.</p>
          <p>DD. Im Übrigen ist die Haftung ausgeschlossen.</p>
          <p>EE. MyShopBooster bleibt der Einwand des Mitverschuldens offen.</p>
          <p class="font-bold mt-4">§10 Mitwirkungspflichten</p>
          <p>FF. Der Kunde wird MyShopBooster alle zur Erbringung der Leistungen erforderlichen Informationen sowie Unterlagen rechtzeitig und kostenfrei auf Anforderung zur Verfügung stellen, insbesondere Informationen über vorhandene Anlagen, Geräte, Software und Softwarekomponenten, die mit den zu erbringenden Leistungen zusammenwirken sollen.</p>
          <p>GG. Wenn es zur Erbringung der Leistung erforderlich ist, kann MyShopBooster nach gemeinsamer Vereinbarung seine Tätigkeit auch in den Räumen des Kunden ausführen. In diesem Fall wird der Kunde alle für die Durchführung der Leistungen vor Ort erforderlichen Einrichtungen, notwendigen Zutritte zu seinen Räumlichkeiten, für die von MyShopBooster genannten Personen, kostenfrei und unverzüglich ermöglichen und die im Rahmen der Erbringung der Leistungen erforderlichen Zugangsberechtigungen, insbesondere zu Betriebsgebäuden und Räumen sowie Rechnern und Daten erteilen.</p>
          <p>HH. Der Kunde gewährleistet die Erreichbarkeit eines hinreichend qualifizierten und befugten Ansprechpartners.</p>
          <p>II. Der Kunde wird MyShopBooster unverzüglich über jede Änderung seiner Anschrift, seiner Firma, seines Geschäftssitzes, seiner Rechnungsanschrift, sowie seiner Bankverbindung für die er MyShopBooster eine Einzugsermächtigung erteilt hat, informieren.</p>
          <p>JJ. Der Kunde wird MyShopBooster auf Anforderung bei der Feststellung, Analyse und Beseitigung von Mängeln unterstützen bzw. hieran mitwirken. Ebenso wird der Kunde zur Vermeidung und Minderung von Schäden beitragen.</p>
          <p>KK. Der Kunde übernimmt die Pflicht, die gelieferte Ware nach Nutzungsbeendigung auf eigene Kosten nach den gesetzlichen Vorschriften ordnungsgemäß zu entsorgen.</p>
          <p>LL. Soweit eine Nichterfüllung, oder eine nicht ordnungsgemäße Erfüllung einer Mitwirkungspflicht ursächlich dafür ist, dass MyShopBooster eine Leistung nicht vertragsgemäß erbringen kann, stellt dies seitens MyShopBooster keine Vertragsverletzung dar. MyShopBooster trifft insoweit für daraus resultierende Schäden kein Verschulden. Bei Mitverschulden gilt § 254 BGB.</p>
          <p>MM. Im Übrigen können sich weitere Mitwirkungspflichten aus den individuellen Vereinbarungen, den Leistungsbeschreibungen, sowie den jeweils ergänzenden Bedingungen ergeben.</p>
          <p class="font-bold mt-4">§11 Datensicherung und Virenabwehr</p>
          <p>Der Kunde hat die Pflicht zur regelmäßigen Datensicherung und zur Virenabwehr nach dem aktuellen Stand der Technik.</p>
          <p class="font-bold mt-4">§12 Duldungspflichten</p>
          <p>NN. MyShopBooster ist berechtigt, Anfragen-, Job-, und Transaktionsabläufe oder ähnliche zyklisch wiederkehrende Automatismen, die vom Kunden gestartet zu MyShopBooster gesendet werden ab- bzw. zu unterbrechen, wenn diese geeignet sind, die Serverleistung in erheblichen Maße zu beeinträchtigen (z.B. durch Spam, CGI-Skripte).</p>
          <p>OO. MyShopBooster wird den Kunden über die vorgenommene Maßnahme und ihren Grund unverzüglich informieren.</p>
          <p>PP. MyShopBooster hat das Recht, alle betroffenen Anwendungen, Infrastrukturen und Systeme abzuschalten, soweit für bzw. durch diese ein nicht nur unerhebliches Sicherheitsproblem zu befürchten ist oder ein solches sich bereits realisiert hat.</p>
          <p>QQ. Die vorbenannten Sicherheitsmaßnahmen stellen keinen außerordentlichen Kündigungsgrund dar und berechtigen den Kunden nicht zur Minderung der vereinbarten Vergütung oder zum Rücktritt vom Vertrag. Schadensersatzansprüche des Kunden gemäß § 8 AGB gegen MyShopBooster bleiben unberührt.</p>
          <p class="font-bold mt-4">§13 Eigentumsvorbehalt</p>
          <p>RR. Alle Lieferungen von MyShopBooster erfolgen unter Eigentumsvorbehalt.</p>
          <p>SS. Die Liefergegenstände bleiben bis zur vollständigen Bezahlung Eigentum von MyShopBooster. Der Kunde ist insoweit nicht berechtigt, die unter Eigentumsvorbehalt stehenden Liefergegenstände zu verpfänden, zur Sicherheit zu übereignen oder sonstige, das Eigentum von MyShopBooster gefährdende Verfügungen zu treffen.</p>
          <p>TT. Bei einer Pfändung oder jeder anderen Beeinträchtigung der Rechte von MyShopBooster durch Dritte, hat der Kunde den Dritten auf die Rechte von MyShopBooster hinzuweisen und MyShopBooster unverzüglich zu informieren. Kosten und Schäden aus der Verletzung dieser Pflicht trägt der Kunde.</p>
          <p class="font-bold mt-4">§14 Sach- und Rechtsmängel; sonstige Leistungsstörungen</p>
          <p>UU. Für der gesetzlichen Sach- und Rechtsmängelhaftung unterliegende Lieferungen und Leistungen gewährleistet MyShopBooster, dass diese bei Gefahrübergang die vereinbarte oder gewöhnliche Beschaffenheit aufweisen.</p>
          <p>VV. Soweit die Parteien im Einzelfall eine über die Beschaffenheitsvereinbarung hinausgehende Einstandspflicht (Garantie) vereinbaren wollen, hat dies ausdrücklich schriftlich zu erfolgen. Angaben in Katalogen, Preislisten und sonstigen dem Kunden überlassenen Informationsmaterial stellen keine derartigen Garantien für eine besondere Beschaffenheit der Liefergegenstände dar.</p>
          <p>WW. Die Mängelrechte des Kunden setzen voraus, dass dieser seinen nach § 377 HGB geschuldeten Untersuchungs- und Rügeobliegenheiten ordnungsgemäß nachgekommen ist. Festgestellte Mängel hat der Kunde MyShopBooster unverzüglich schriftlich anzuzeigen.</p>
          <p>XX. Soweit der Liefer- und Leistungsgegenstand im Zeitpunkt des Gefahrübergangs mit einem Mangel behaftet ist, wird MyShopBooster den Mangel innerhalb angemessener Frist kostenlos beseitigen oder eine mangelfreie Sache liefern (Nacherfüllung).</p>
          <p>YY. MyShopBooster behält sich ein zweimaliges Nacherfüllungsrecht vor. Das Wahlrecht, Beseitigung des Mangels oder Lieferung einer mangelfreien Sache, liegt bei MyShopBooster, sofern nicht dem Kunden nur eine bestimmte Art der Nacherfüllung zumutbar ist.</p>
          <p>ZZ. Erfolgt innerhalb einer angemessenen Frist keine Nacherfüllung bzw. schlägt diese fehl, ist sie dem Kunden unzumutbar oder hat MyShopBooster sie verweigert, ist der Kunde berechtigt, vom Vertrag zurückzutreten oder Minderung zu verlangen. Schadensersatz oder Ersatz vergeblicher Aufwendungen wegen eines Mangels leistet MyShopBooster im Rahmen der in § 6 festgelegten Grenzen.</p>
          <p>AAA. Ansprüche des Kunden wegen Sachmängeln erstrecken sich nicht auf Leistungen, die der Kunde ändert oder die er nicht in der vertraglich vereinbarten Systemumgebung einsetzt; es sei denn, der Kunde weist nach, dass diese Nutzung für den angezeigten Mangel nicht ursächlich ist.</p>
          <p>BBB. Die Verjährungsfrist für Mängelansprüche beträgt 12 Monate, gerechnet ab Gefahrübergang, soweit der Kunde nicht Verbraucher ist.</p>
          <p>CCC. Erbringt MyShopBooster außerhalb des Bereichs der Sach- und Rechtsmängelhaftung Leistungen nicht oder nicht ordnungsgemäß oder begeht MyShopBooster eine sonstige Pflichtverletzung, so hat der Kunde dies gegenüber MyShopBooster stets schriftlich anzuzeigen. Der Kunde räumt MyShopBooster in diesen Fällen eine angemessene Frist ein, innerhalb derer MyShopBooster die Gelegenheit zur ordnungsgemäßen Erfüllung der Leistung gegeben wird.</p>
          <p class="font-bold mt-4">§15 Urheberrechte, Nutzungsrechte</p>
          <p>DDD. Alle Rechte an den von MyShopBooster zur Erfüllung seiner Leistungspflicht erbrachten Arbeitsergebnissen, insbesondere das Urheberrecht, die Rechte an Erfindungen sowie gewerbliche Schutzrechte, verbleiben bei MyShopBooster.</p>
          <p>EEE. Soweit nicht etwas anderes schriftlich vereinbart wurde, erhält der Kunde an den Arbeitsergebnissen mit der vollständigen Zahlung der MyShopBooster zustehenden Vergütung, ein einfaches Nutzungsrecht für den vertraglich vorgesehenen Gebrauch. Eine über den Vertragszweck hinausgehende Verwendung, Vervielfältigung und Überlassung an Dritte ist dem Kunden nicht gestattet.</p>
          <p class="font-bold mt-4">§16 Freistellung durch den Kunden</p>
          <p>FFF. Sollte MyShopBooster aufgrund der nach diesem Vertrag für den Kunden zu erbringenden Leistungen durch Dritte in Anspruch genommen werden, so ist der Kunde zum Ersatz der MyShopBooster entstandenen und noch entstehenden Schadens sowie zur Freihaltung und Freistellung von MyShopBooster von Schadensersatz- und Aufwendungsersatzansprüche Dritter, die durch den Verstoß verursacht wurden, verpflichtet. Die vorstehende Freistellung greift in den Fällen, in denen ein vertragswidriges oder sonstiges unrechtmäßiges Verhalten bzw. vermeintlich unrechtmäßiges Verhalten des Kunden gegenüber Dritten ursächlich für die Inanspruchnahme von MyShopBooster durch den Dritten ist. Der Freistellungsanspruch umfasst auch die Verpflichtung, MyShopBooster von Rechtsverteidigungskosten (Gerichts- und Anwaltskosten etc.) vollständig freizustellen.</p>
          <p>(1) Sonstige Ansprüche, von MyShopBooster insbesondere zur Sperrung der Inhalte und der außerordentlichen Kündigung, bleiben unberührt.</p>
          <p class="font-bold mt-4">§17 Datenschutz</p>
          <p>MyShopBooster weist darauf hin, dass personenbezogene Daten des Kunden erhoben, verarbeitet und genutzt werden, sofern dies für die Erbringung der Leistungen und deren Abrechnung notwendig ist.</p>
          <p class="font-bold mt-4">§18 Schlussbestimmungen</p>
          <p>GGG. Die Abtretung von Forderungen gegen MyShopBooster bedarf zu ihrer Wirksamkeit der schriftlichen Zustimmung von MyShopBooster.</p>
          <p>HHH. Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des einheitlichen UN-Kaufrechts (CISG).</p>
          <p>III. Vertragssprache ist Deutsch.</p>
          <p>JJJ. Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist Berlin, sofern der Kunde Kaufmann ist. MyShopBooster bleibt berechtigt, am allgemeinen Gerichtsstand des Kunden Klage oder andere gerichtliche Verfahren zu erheben oder einzuleiten.</p>
          <p>KKK. Änderungen oder Ergänzungen dieses Vertrages bedürfen der Schriftform (per E-Mail ist ausreichend). Dies gilt auch für eine Regelung, mit der diese Schriftform abgedungen wird. Soweit über das Konfigurationsmenü der Leistung eine Kündigung vorsieht, erfüllt deren Nutzung auch bei Kündigungen die Schriftform.</p>
          <p>A. Ergänzende Bedingungen für Web-Hosting</p>
          <p>Sofern MyShopBooster für den Kunden Web-Hosting betreibt, gelten ergänzend zu den Allgemeinen Bedingungen für Lieferung und Leistung der AGB folgende besondere Regelungen, die die allgemeinen Regelungen ergänzen und im Falle speziellerer Regelungen den allgemeinen Regelungen vorgehen:</p>
           <p class="font-bold mt-4">§19 Vertragsgegenstand</p>
          <p>LLL. MyShopBooster wird dem Kunden Shared Webhosting (eigenen Ordner auf einem Server) oder einen virtuellen Server (Speicherplatz auf einem auch von anderen Kunden genutzten oder nutzbaren Speichermedium, der jedoch eine eigene IP-Adresse erhält und damit für Dritte als selbstständiger Server erscheint) einrichten.</p>
          <p>MMM. MyShopBooster wird die Verbindung zwischen dem Server und dem Internet verschaffen, gewähren und aufrechterhalten, damit die auf dem Server abgelegten Daten auf Anfrage von außen stehenden Rechnern im Internet (Clients) jederzeit und störungsfrei mittels der im Internet gebräuchlichen Protokolle (http, ftp, smtp, nntp) in dem jeweilig anwendbaren Protokoll an den abrufenden Rechner weitergeleitet werden.</p>
          <p>NNN. MyShopBooster schuldet ein Bemühen, dass die vom Kunden vertragsgemäß gespeicherten Daten (Website) im World-Wide-Web über das von der MyShopBooster unterhaltene Netz und das daran angeschlossene Internet von der Öffentlichkeit rund um die Uhr weltweit abrufbar sind. MyShopBooster übernimmt keine Verantwortung für den Erfolg des jeweiligen Zugangs zu der Website, soweit nicht ausschließlich das von MyShopBooster betriebene Netz einschließlich der Schnittstellen zu Netzen Dritter benutzt wird.</p>
          <p>OOO. MyShopBooster trägt des Weiteren dafür Sorge, dass der Kunde die Möglichkeit des jederzeitigen Zugriffs auf den Server hat. Hierzu vergibt die MyShopBooster einen Benutzernamen und ein Passwort an den Kunden, mit dem der Kunde seine Internetseiten im Wege des Datentransfers selbstständig speichern, ändern, ergänzen oder löschen kann (File Transfer Protocol - FTP). Aus Sicherheitsgründen gibt die MyShopBooster dem Kunden zudem die Möglichkeit, sein Passwort zu ändern.</p>
           <p class="font-bold mt-4">§20 Leistungserbringungszeitraum</p>
          <p>MyShopBooster erbringt die Leistungen innerhalb von 21 Tagen. In Ausnahmefällen kann eine Lieferzeit von bis zu 40 Tagen erfolgen. Sollte die Lieferzeit 40 Tage überschreiten, wird der Kunde per E-Mail benachrichtigt.</p>
           <p class="font-bold mt-4">§21 Vertragslaufzeit und Kündigung</p>
          <p>PPP. Der Vertrag läuft auf unbestimmte Zeit.</p>
          <p>QQQ. Die Vertragsparteien können, soweit sich aus dem Angebot, der Leistungsbeschreibung oder der Preisübersicht Leistungsschein nicht etwas anderes ergibt, den Vertrag jederzeit unter Einhaltung einer Kündigungsfrist von 30 Tagen kündigen, wobei erster möglicher Kündigungstermin drei Monate nach Vertragsbeginn ist.</p>
           <p class="font-bold mt-4">§22 Geschwindigkeit und Verfügbarkeit des Servers</p>
          <p>RRR. MyShopBooster stellt durch eine dem Stand der Technik entsprechende Bandbreite der Verbindung zum nächsten Internet-Knoten sicher, dass eine möglichst hohe Datenübertragungsgeschwindigkeit für Benutzer erreicht wird.</p>
          <p>SSS. Der Webserver ist durchgehend 24 Stunden, sieben Tage die Woche einsatzfähig mit einer Verfügbarkeit von 99 % im Jahresmittel. Hiervon ausgenommen sind Ausfallzeiten durch Wartung und Software-Updates sowie Zeiten, in denen der Webserver aufgrund von technischen oder sonstigen Problemen, die nicht im Einflussbereich von MyShopBooster liegen (höhere Gewalt, Verschulden Dritter etc.), über das Internet nicht zu erreichen ist. Sofern für MyShopBooster absehbar ist, dass Ausfallzeiten für Wartung und Software-Updates länger als zwei Stunden dauern, wird MyShopBooster dies dem Kunden mindestens zwei Tage vor Beginn der jeweiligen Arbeiten mitteilen.</p>
           <p class="font-bold mt-4">§23 Pflichten des Kunden</p>
          <p>TTT. Sollte es bei der Nutzung des Servers zu Störungen kommen, so wird der Kunde MyShopBooster von diesen Störungen unverzüglich in Kenntnis setzen.</p>
          <p>UUU. Der Kunde ist verpflichtet, die Zugangsdaten gegenüber unbefugten Dritten geheim zu halten. Insbesondere sind Benutzername und Passwort so aufzubewahren, dass der Zugriff auf diese Daten durch unbefugte Dritte unmöglich ist, um einen Missbrauch des Zugangs durch Dritte auszuschließen. Der Kunde wird MyShopBooster unverzüglich informieren, sobald er Kenntnis erlangt, dass unbefugten Dritten das Passwort bekannt ist.</p>
          <p>VVV. Als unbefugte Dritte gelten nicht die Personen, die den Speicherplatz, der Gegenstand dieses Vertrages ist, mit Wissen und Willen des Kunden nutzen.</p>
          <p>WWW. Der Kunde versichert, dass er keine Inhalte auf dem vertragsgegenständlichen Speicherplatz speichern und in das Internet einstellen wird, deren Bereitstellung, Veröffentlichung und Nutzung gegen Strafrecht, Urheberrechte, Marken- und sonstigen Kennzeichnungsrechte oder Persönlichkeitsrechte verstößt oder aber jugendgefährdenden bzw. pornographischen Charakter besitzen. Für jeden Fall der Zuwiderhandlung gegen vorstehende Verpflichtung verspricht der Kunde die Zahlung einer Vertragsstrafe in Höhe von 500 EUR. Außerdem berechtigt ein Verstoß des Kunden gegen die genannten Verpflichtungen MyShopBooster zur außerordentlichen Kündigung.</p>
           <p class="font-bold mt-4">§24 Vorübergehende Sperrung</p>
          <p>XXX. MyShopBooster ist berechtigt, die Anbindung der Website zum Internet vorübergehend zu unterbrechen (Sperrung der Website), falls ein hinreichender Verdacht auf rechtswidrige Inhalte der Website iSv. B § 5 Ziff. 4 vorliegt, aufgrund einer Abmahnung des vermeintlich Verletzten oder Ermittlungen stattlicher Behörden, es sei denn, die Abmahnung ist offensichtlich unbegründet.</p>
          <p>YYY. Die Sperrung ist, sofern technisch möglich und zumutbar, auf die vermeintlich rechtsverletzenden Inhalte zu beschränken. Der Kunde ist über die Sperrung unter Angabe der Gründe unverzüglich zu benachrichtigen und aufzufordern, die vermeintlich rechtswidrigen Inhalte zu entfernen oder die Rechtmäßigkeit darzulegen und gegebenenfalls zu beweisen.</p>
          <p>ZZZ. Die Sperrung ist aufzuheben, sobald der Verdacht entkräftet ist oder aber MyShopBooster die Möglichkeit hatte, aufgrund des Verhaltens des Kunden den Vertrag außerordentlich zu kündigen.</p>
           <p class="font-bold mt-4">§25 Rechteeinräumung</p>
          <p>AAAA. Die Inhalte der Website sind für den Kunden nach Urheberrechtsgesetz (als Werk, Sammelwerk, Datenbankwerk, Computerprogramm, Lichtbild, Datenbank, über verwandte Leistungsschutzrechte oder als abgeleitete Rechte von den genannten Rechten), Kunsturhebergesetz, Markengesetz oder über sonstige Schutzrechte geschützt (-geschützte Inhalte-).</p>
          <p>BBBB. Der Kunde gewährt MyShopBooster das zeitlich auf die Dauer des Vertrages beschränkte, nicht übertragbare, auf den Standort des jeweiligen Servers (für Backup-Kopien: auf den Ort ihrer Verwahrung) beschränkte, nicht ausschließliche Recht, die geschützten Inhalte zu Zwecken dieses Vertrages auf dem Server, auf einem weiteren Server, der zur Spiegelung dient, und auf einer ausreichenden Anzahl von Backup-Kopien zu vervielfältigen.</p>
          <p>CCCC. Der Kunde gewährt MyShopBooster das zeitlich auf die Dauer des Vertrages beschränkte, nicht übertragbare, weltweite, nicht ausschließliche Recht, die geschützten Inhalte über das von MyShopBooster unterhaltene Netz und das daran angeschlossene Internet der Öffentlichkeit in einer Weise zugänglich zu machen, dass Mitglieder der Öffentlichkeit Zugang zur Website von einem Ort und zu einer Zeit, die sie jeweils individuell wählen, haben und diese Daten durch Herunterladen vom Server von MyShopBooster speichern können. Soweit nach Beendigung des Vertrages geschützte Inhalte von Dritten in Cache-Speichern vorgehalten werden, wird diese Speicherung nicht mehr der MyShopBooster zugerechnet.</p>
           <p class="font-bold mt-4">§26 Folgen Vertragsbeendigung</p>
          <p>(1) Bei Vertragsbeendigung hat der Kunde gegen MyShopBooster einen Anspruch auf Herausgabe der Website. Die Herausgabe erfolgt</p>
          <p>(a) durch Vervielfältigung der Website auf CD-ROM oder einen anderen vom Kunden angegebenen Datenträger und Übergabe dieses Datenträgers an den Kunden oder per Download im Internet und</p>
          <p>(b) durch Anfertigung eines vollständigen Ausdrucks aller Dateien auf der Website sowohl im Quelltext wie auch durch einen marktüblichen Browser im Ansichtstext und Übergabe des vollständigen Ausdrucks an den Kunden.</p>
          <p>(2) MyShopBooster hat nach Übergabe und Abnahme des Datenträgers und des Ausdrucks durch den Kunden Anspruch auf Erstattung der zu belegenden Materialkosten.</p>
           <p class="font-bold mt-4">§27 Anfängliche Mängelhaftung und sonstige Leistungsstörung</p>
          <p>Bei der Überlassung des Speicherplatzes auf dem Webserver schließt MyShopBooster jegliche verschuldensunabhängige Haftung für anfängliche Mängel des Webservers aus. Spätere Einwendungen wegen offener oder verdeckter Mängel sind damit ausgeschlossen.</p>
          <p>A. Ergänzende Bedingungen für Domain-Registrierung</p>
          <p>Sofern MyShopBooster für den Kunden im Rahmen seiner Dienstleistungen Domains vermittelt, gelten ergänzend zu den Allgemeinen Bedingungen für Lieferung und Leistung der AGB folgende besondere Regelungen, die die allgemeinen Regelungen ergänzen und im Falle speziellerer Regelungen den allgemeinen Regelungen vorgehen:</p>
           <p class="font-bold mt-4">§28 Domain- und Routing-Service</p>
          <p>DDDD. MyShopBooster übernimmt es, für den Kunden Domains, unter denen die von dem Kunden auf dem reservierten Speicherplatz gespeicherten Daten zugänglich sein sollen, bei der jeweiligen Vergabestelle (in Deutschland für die Top-Level-Domain &quot;.de&quot; der DENIC) auf den Namen des Kunden anzumelden. MyShopBooster wird von dem Kunden hierzu ebenso wie zur Verwaltung der Domains gegenüber der jeweiligen Vergabestelle ausdrücklich bevollmächtigt.</p>
          <p>EEEE. Die verschiedenen Top-Level-Domains werden von einer Vielzahl unterschiedlicher, meist länderspezifischen, Regelungen unterliegenden Organisationen vergeben und verwaltet. Jede dieser Organisationen legt der Registrierung und der Verwaltung unterschiedliche Bedingungen, meist in der Landesprache oder in Englisch, zugrunde. Die jeweiligen Organisationen wenden ihre eigenen Regelungen zur Beilegung von Domainstreitigkeiten und zur Verwaltung und Registrierung von Domains an. MyShopBooster stellt den Zugang zu den Bedingungen der länderspezifischen Organisationen her. Für die Verschaffung und Kenntnisnahme, insbesondere für die Überwindung der sprachlichen Barriere und auch für die Übersetzung, steht MyShopBooster jedoch nicht ein. Der Kunde hat sich selbst den Inhalt der einzelnen landespezifischen Bedingungen zu verschaffen und diesen zur Kenntnis zu nehmen und alle hierfür notwendigen Aufwendungen, einschließlich der Übersetzung, zu tragen.</p>
          <p>FFFF. Der Kunde benennt MyShopBooster die gewünschten Domains unter Angabe, unter welcher Top-Level-Domain diese registriert werden sollen.</p>
          <p>GGGG. Dem Kunden ist bekannt, dass MyShopBooster auf die Entscheidungen der Vergabestellen und die von diesen erhobenen Gebühren, die der Kunde zusätzlich zu den Vergütungen nach diesem Vertrag zu tragen hat, keinen Einfluss hat, so dass er für eine Durchführung der Registrierung keine Verpflichtungen übernehmen kann.</p>
          <p>HHHH. MyShopBooster ist verpflichtet, für diese registrierten Domains die erforderlichen Anmeldungen und sonstigen Maßnahmen vorzunehmen und aufrechtzuerhalten, die den Zugriff unter Verwendung der jeweiligen Domain auf die von dem Kunden auf dem reservierten Speicherplatz gespeicherten Dateien aus dem Internet ermöglichen (Routing-Service).</p>
          <p>IIII. MyShopBooster betreut diese registrierten Domains während des Bestehens dieses Vertrags nach den jeweils gültigen Richtlinien der jeweils zuständigen Vergabestelle.</p>
          <p>JJJJ. MyShopBooster wird auf schriftliche Anweisung des Kunden eine registrierte Domain unverzüglich zur Weiternutzung durch einen anderen Provider freigeben und die hierfür erforderlichen Erklärungen gegenüber der jeweiligen Vergabestelle unverzüglich abgeben. Im Übrigen ist MyShopBooster berechtigt, die Domain des Kunden nach Beendigung des Vertrages freizugeben.</p>
          <p>KKKK. Befindet sich der Kunde im Zahlungsverzug, kann MyShopBooster bis zum Ausgleich der offenen Forderungen die Weitergabe der registrierten Domain gemäß Abs. 7 verweigern.</p>
           <p class="font-bold mt-4">§29 Verantwortlichkeit der Daten des Kunden</p>
          <p>LLLL. MyShopBooster übernimmt keine Überprüfung der rechtlichen Zulässigkeit der auf Veranlassung des Kunden angemeldeten Domain.</p>
          <p>MMMM. Der Kunde ist selbst für deren Zulässigkeit verantwortlich. Der Kunde ist verpflichtet, eine an MyShopBooster zur Registrierung mitgeteilte Domain auf die Verletzung von Urheber-, Marken-, Namens- oder Firmenrechten sowie auf die sonstige rechtliche Zulässigkeit zu überprüfen.</p>
          <p>NNNN. Der Kunde garantiert, dass die von ihm beantragte Domain keine Rechte Dritter verletzt.</p>
           <p class="font-bold mt-4">§30 Leistungserbringungszeitraum</p>
          <p>OOOO. MyShopBooster erbringt die Leistungen in der Regel innerhalb von zwei Werktagen (Montag-Freitag).</p>
          <p>Sollte die Leistung nicht innerhalb dieses Zeitraumes erbracht werden können, informiert MyShopBooster den Kunden hierüber per E-Mail.</p>
          <p>PPPP. Sollte die Domainregistrierung durch MyShopBooster für den Kunden abgeschlossen sein, bestätigt MyShopBooster dies diesem gegenüber.</p>
           <p class="font-bold mt-4">§31 Vertragsbeendigung</p>
          <p>QQQQ. Bei einer Kündigung erfolgt keine Erstattung der bereits bezahlten Domain-Gebühren, sofern nicht die Kündigung durch MyShopBooster verschuldet worden ist.</p>
          <p>RRRR. Für den Fall der Vertragsbeendigung erklärt der Kunde bereits jetzt seine ausdrückliche Einwilligung zur Rückgabe der jeweiligen Domains.</p>
           <p class="font-bold mt-4">§32 Domainstreitigkeiten</p>
          <p>Kommt es zu einem Streit über eine Domainregistrierung, obliegt es dem Kunden, den Streit zu führen. MyShopBooster wird den Kunden über eine offizielle Streitankündigung in Bezug auf eine Domainregistrierung des Kunden informieren. Erklärt der Kunde nicht innerhalb einer Frist von zehn Tagen nach Kenntnisnahme, dass er den Streit über die Domain aufnehmen will, ist der MyShopBooster berechtigt, die Domainregistrierung zurückzugeben.</p>
          <p>A. Dienstleistungen</p>
          <p>Sofern MyShopBooster für den Kunden Dienstleistungen erbringt, gelten ergänzend zu den Allgemeinen Bedingungen für Lieferung und Leistung der AGB folgende besondere Regelungen, die die allgemeinen Regelungen ergänzen und im Falle speziellerer Regelungen den allgemeinen Regelungen vorgehen:</p>
          <p>SSSS. Beginn und Dauer der Leistungserbringung ergeben sich aus dem diesen Geschäftsbedingungen zugrunde gelegten Angebot, der dazugehörigen Leistungsbeschreibung bzw. der Preisübersicht von MyShopBooster. Das Dienstverhältnis beginnt spätestens mit der Inanspruchnahme der bereitgestellten Leistung.</p>
          <p>TTTT. Ist eine feste Laufzeit vereinbart, endet das Dienstverhältnis mit Ablauf des eingetragenen Datums ohne dass es einer Kündigung bedarf.</p>
          <p>UUUU. Soweit zwischen den Parteien nicht anderweitig vereinbart, gilt für die zu erbringenden Dienstleistungen pro eingesetzten Mitarbeiter eine Vergütung in Höhe von 60 EUR (netto) pro Stunde als vereinbart.</p>
          <p>VVVV. Die Vertragsparteien können, soweit sich aus dem Angebot, der dazugehörigen Leistungsbeschreibung bzw. der Preisübersicht nicht etwas anderes ergibt, das Dienstverhältnis jederzeit unter Einhaltung einer Kündigungsfrist von 30 Tagen kündigen. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.</p>
          <p>B. Widerrufsrecht für Verbraucher</p>
          <p>Widerrufsbelehrung</p>
          <p>Ist der Kunde Unternehmer nach § 14 BGB, so hat er kein Widerrufs- und Rückgaberecht gem. § 312d BGB i. V. m. §§ 355, 356 BGB. Das Widerrufsrecht gilt nur für Verbraucher im Sinne des § 13 BGB. Verbraucher im Sinn des § 13 BGB sind jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch selbständigen beruflichen Tätigkeit zugerechnet werden kann.</p>
          <p>Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt 14 Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.</p>
          <p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
          <p>{{ company }}, Ringbahnstr. 12, 12099 Berlin, Germany
          Telefon: 030 - 609 83 09 63 (Telefonsupport Mo-Fr 9 - 18 Uhr), Email: service[@]myshopbooster.de</p>
          <p>mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.</p>
          <p>Sie können dafür das beigefügte Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
          <p>Folgen des Widerrufs bei paketversandfertiger Ware</p>
          <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
          <p>Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist. Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von 14 Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
          <p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
          <p>Folgen des Widerrufs bei Lieferung von Speditions-Waren</p>
          <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.</p>
          <p>Wir holen die Waren ab.</p>
          <p>Sie tragen die unmittelbaren Kosten der Rücksendung der Waren in Höhe von Euro 150,00.</p>
          <p>Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.</p>
          <p>Ausschluss des Widerrufsrechts</p>
          <p>Ein Widerrufsrecht nach den vorstehenden Bedingungen gemäß § 312d BGB besteht nicht bei Fernabsatzverträgen</p>
          <ul>
          <li>zur Lieferung von Waren, die nach Kundenspezifikation angefertigt werden oder</li>
          <li>eindeutig auf die persönlichen Bedürfnisse zugeschnitten sind.</li>
          </ul>
          <p>Muster für das Verbraucher-Widerrufsformular:</p>
          <p class="font-bold mt-4">Muster-Widerrufsformular:</p>
          <p>(Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)</p>
          <p> - An {{ company }}, Ringbahnstr. 12, 12099 Berlin, Germany
          Telefon: 030 - 609 83 09 63 (Telefonsupport Mo-Fr 9 - 18 Uhr), Email: service[@]myshopbooster.de</p>
          <p>- Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)</p>
          <p>- Bestellt am (*)/erhalten am (*)</p>
          <p>- Name des/der Verbraucher(s)</p>
          <p>- Anschrift des/der Verbraucher(s)</p>
          <p>- Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
          <p>- Datum</p>
          
          
         <p>_______________(*) Unzutreffendes streichen.</p>
          
        </b-tab>
        <!-- END TAB AGB-->

        <!-- TAB POLICY -->

        <b-tab :title="$t('POLICY_TITLE')">
          <p class="mt-4">Wir freuen uns über Ihr Interesse an unserer Website. Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig. Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.</p>
          <p class="font-bold mt-4">Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten</p>
          <p>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
              Verantwortlicher: myshopbooster.de, {{company}}, Ringbahnstr. 12, 12099 Berlin, Deutschland, Email: service[@]myshopbooster.de.</p>
          <p>Der Datenschutzbeauftragte von myshopbooster.de, {{company}}: Rechtsanwalt Usama El-Tounsy, Neue Straße 12, 12103 Berlin, Deutschland Email: kanzlei@el-tounsy.de, Telefon: + 49 (0) 30 809 21 66 77, Fax: + 49 (0) 30 809 21 66 78.</p>
          <p>Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.
              "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.</p>
          
          <p class="font-bold mt-4">Server-Logfiles</p>
          <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Es werden bei jedem Zugriff auf unsere Website Nutzungsdaten durch Ihren Internetbrowser übermittelt und in Protokolldaten (Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und der anfragende Provider. Diese Daten dienen ausschließlich der Gewährleistung eines störungsfreien Betriebs unserer Website und zur Verbesserung unseres Angebotes. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht möglich.</p>
          
          <p class="font-bold mt-4">Erhebung und Verarbeitung bei Nutzung des Kontaktformulars</p>
          <p>Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Telefonnummer, Adressdaten und Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme. Mit Absenden Ihrer Nachricht willigen Sie in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung.
              Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
          
          <p class="font-bold mt-4">Kundenkonto</p>
          <p>Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegeben Umfang. Die Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihr Kundenkonto wird anschließend gelöscht.</p>
          
          <p class="font-bold mt-4">Erhebung, Verarbeitung und Nutzung personenbezogener Daten bei Bestellungen</p>
          <p>Bei der Bestellung erheben und verwenden wir Ihre personenbezogenen Daten nur, soweit dies zur Erfüllung und Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der Daten ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich. Eine Weitergabe Ihrer Daten an Dritte ohne Ihre ausdrückliche Einwilligung erfolgt nicht. Ausgenommen hiervon sind lediglich unsere Dienstleistungspartner, die wir zur Abwicklung des Vertragsverhältnisses benötigen oder Dienstleister derer wir uns im Rahmen einer Auftragsverarbeitung bedienen. Neben den in den jeweiligen Klauseln dieser Datenschutzerklärung benannten Empfängern sind dies beispielsweise Empfänger folgender Kategorien: Versanddienstleister, Zahlungsdienstleister, Warenwirtschaftsdienstleister, Diensteanbieter für die Bestellabwicklung, Webhoster, IT-Dienstleister und Dropshipping Händler. In allen Fällen beachten wir strikt die gesetzlichen Vorgaben. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.</p>
          
          <p class="font-bold mt-4">Verwendung der E-Mail-Adresse für die Zusendung von Newslettern</p>
          <p>Wir nutzen Ihre E-Mail-Adresse unabhängig von der Vertragsabwicklung ausschließlich für eigene Werbezwecke zum Newsletterversand, sofern Sie dem ausdrücklich zugestimmt haben. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Sie können dazu den Newsletter jederzeit unter Nutzung des entsprechenden Links im Newsletter oder durch Mitteilung an uns abbestellen. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.</p>
          
          <p class="font-bold mt-4">Verwendung der E-Mail-Adresse für die Zusendung von Direktwerbung</p>
          <p>Wir nutzen Ihre E-Mail-Adresse, die wir im Rahmen des Verkaufes einer Ware oder Dienstleistung erhalten haben, für die elektronische Übersendung von Werbung für eigene Waren oder Dienstleistungen, die denen ähnlich sind, die Sie bereits bei uns erworben haben, soweit Sie dieser Verwendung nicht widersprochen haben. Die Bereitstellung der E-Mail-Adresse ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigtem Interesse an Direktwerbung. Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit durch Mitteilung an uns widersprechen. Die Kontaktdaten für die Ausübung des Widerspruchs finden Sie im Impressum. Sie können auch den dafür vorgesehenen Link in der Werbe-E-Mail nutzen. Hierfür entstehen keine anderen als die Übermittlungskosten nach den Basistarifen.</p>
          
          <p class="font-bold mt-4">Nutzung eines eigenen Warenwirtschaftsystems</p>
          <p>Wir verwenden zur Vertragsabwicklung ein eigenes Warenwirtschaftssystem im Rahmen einer Auftragsverarbeitung.</p>
          
          <p class="font-bold mt-4">Verwendung von PayPal</p>
          <p>Alle PayPal-Transaktionen unterliegen der PayPal-Datenschutzerklärung. Diese finden Sie unter https://www.paypal.com/de/webapps/mpp/ua/privacy-prev?locale.x=de_DE</p>
          
          <p class="font-bold mt-4">Cookies</p>
          <p>Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. Wir setzen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
          <p>Wir verwenden auf unserer Website darüber hinaus Cookies zu dem Zweck, eine Analyse des Surfverhaltens unserer Seitenbesucher zu ermöglichen.</p>
          <p>Des Weiteren verwenden wir Cookies zu dem Zweck, Seitenbesucher anschließend auf anderen Webseiten mit gezielter, interessenbezogener Werbung anzusprechen.</p>
          <p>Die Verarbeitung erfolgt auf Grundlage des § 15 (3) TMG sowie Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an den oben genannten Zwecken.
              Die auf diese Weise von Ihnen erhobenen Daten werden durch technische Vorkehrungen pseudonymisiert. Eine Zuordnung der Daten zu Ihrer Person ist daher nicht mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten von Ihnen gespeichert.
              Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
              Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren) können:
              Chrome Browser: <a class="text-light font-bold" href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank">https://support.google.com/accounts/answer/61416?hl=de</a>
              Internet Explorer: <a class="text-light font-bold" href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a>
              Mozilla Firefox: <a class="text-light font-bold" href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" target="_blank">https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</a>
              Safari: <a class="text-light font-bold" href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></p>
          
          <p class="font-bold mt-4">Nutzung von Google Analytics</p>
          <p>Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
              Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch die Cookies erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Ihre Daten werden gegebenenfalls in die USA übermittelt. Für Datenübermittlungen in die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission vorhanden. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website. Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Sie können dazu die Speicherung der Cookies durch die Auswahl entsprechender technischer Einstellungen Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können des Weiteren die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren [https://tools.google.com/dlpage/gaoptout?hl=de]. Um die Erfassung durch Google Analytics geräteübergreifend zu verhindern können Sie einen Opt-Out-Cookie setzen. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Sie müssen das Opt-Out auf allen genutzten Systemen und Geräten durchführen, damit dies umfassend wirkt. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren
              Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a class="text-light font-bold" href="https://www.google.com/analytics/terms/de.html" target="_blank">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a class="text-light font-bold" href="https://www.google.de/intl/de/policies/" target="_blank">https://www.google.de/intl/de/policies/</a>.</p>
          
          <p class="font-bold mt-4">Nutzung von LiveZilla</p>
          <p>Wir verwenden auf unserer Website das Analysetool der LiveZilla GmbH (Byk-Gulden-Straße 18, 78224 Singen).
              Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu werden Daten zu Marketing- und Optimierungszwecken gesammelt und gespeichert. Aus diesen Daten können unter einem Pseudonym Nutzungsprofile erstellt werden. Hierzu können Cookies eingesetzt werden. Die Cookies ermöglichen die Wiedererkennung des Internet-Browsers. Die mit den LiveZilla-Technologien erhobenen Daten werden ohne die gesondert erteilte Zustimmung des Betroffenen nicht dazu benutzt, den Besucher dieser Website persönlich zu identifizieren und nicht mit personenbezogenen Daten über den Träger des Pseudonyms zusammengeführt.
              Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) f) DSGVO aus dem berechtigten Interesse an direkter Kundenkommunikation und an der bedarfsgerechten Gestaltung der Website.
              Sie haben das Recht aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Sie können den Widerspruch ausüben, indem Sie die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
          </p>
          
          <p class="font-bold mt-4">Verwendung der Remarketing- oder "Ähnliche Zielgruppen"-Funktion der Google Inc.</p>
          <p>Wir verwenden auf unserer Website die Remarketing- oder "Ähnliche Zielgruppen"- Funktion der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Diese Funktion dient dem Zweck der Analyse des Besucherverhaltens und der Besucherinteressen.
              Zur Durchführung der Analyse der Websiten-Nutzung, welche die Grundlage für die Erstellung der interessenbezogenen Werbeanzeigen bildet, setzt Google Cookies ein. Über die Cookies werden die Besuche der Website sowie anonymisierte Daten über die Nutzung der Website erfasst. Es erfolgt keine Speicherung von personenbezogenen Daten der Besucher der Website. Besuchen Sie nachfolgend eine andere Website im Google Display-Netzwerk werden Ihnen Werbeeinblendungen angezeigt, die mit hoher Wahrscheinlichkeit zuvor aufgerufene Produkt- und Informationsbereiche berücksichtigen.
              Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für Datenübermittlungen in die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission vorhanden.
              Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse, die Besucher der Website zielgerichtet mit Werbung anzusprechen, indem für Besucher der Website des Anbieters personalisierte, interessenbezogene Werbung-Anzeigen geschaltet werden, wenn sie andere Websiten im Google Display-Netzwerk besuchen.
              Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Sie können dazu die Verwendung von Cookies durch Google dauerhaft deaktivieren, indem Sie dem nachfolgenden Link folgen und das dort bereitgestellte Plug-In herunterladen und installieren: <a class="text-light font-bold" href="https://support.google.com/ads/answer/7395996?hl=de" target="_blank">https://support.google.com/ads/answer/7395996?hl=de</a>.
              Alternativ können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem sie die Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter <a class="text-light font-bold" href="https://www.networkadvertising.org/choices/" target="_blank">https://www.networkadvertising.org/choices/</a> aufrufen und die dort genannten weiterführenden Information zum Opt-Out umsetzen.
              Nähere Informationen zu Google Remarketing sowie die dazugehörige Datenschutzerklärung finden Sie unter: <a class="text-light font-bold" href="https://www.google.com/privacy/ads/" target="_blank">https://www.google.com/privacy/ads/</a></p>
          
          <p class="font-bold mt-4">Verwendung von Google Adwords Conversion-Tracking</p>
          <p>Wir verwenden auf unserer Website das Online-Werbeprogramm „Google AdWords“ und in diesem Rahmen Conversion-Tracking (Besuchsaktionsauswertung). Das Google Conversion Tracking ist ein Analysedienst der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies haben eine begrenzte Gültigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen und das Cookie noch nicht abgelaufen ist, können Google und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Somit besteht keine Möglichkeit, dass Cookies über die Websites von AdWords-Kunden nachverfolgt werden können.
              Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden, dienen dem Zweck Conversion-Statistiken zu erstellen. Hierbei erfahren wir die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an zielgerichteter Werbung und der Analyse der Wirkung und Effizienz dieser Werbung.
              Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Dazu können Sie die Speicherung der Cookies durch die Auswahl entsprechender technischer Einstellungen Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.
              Des Weiteren können Sie in den Einstellungen für Werbung bei Google für Sie personalisierte Werbung deaktivieren. Eine Anleitung dazu finden Sie unter <a class="text-light font-bold" href="https://support.google.com/ads/answer/2662922?hl=de" target="_blank">https://support.google.com/ads/answer/2662922?hl=de</a>. Darüber hinaus können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem sie die Deaktivierungsseite der Netzwerkwerbeinitiative (Network Advertising Initiative) unter <a class="text-light font-bold" href="https://www.networkadvertising.org/choices/" target="_blank">https://www.networkadvertising.org/choices/</a> aufrufen und die dort genannten weiterführenden Information zum Opt-Out umsetzen.
              Weiterführende Informationen sowie die Datenschutzerklärung von Google finden Sie unter: <a class="text-light font-bold" href="https://www.google.de/policies/privacy/" target="_blank">https://www.google.de/policies/privacy/</a>.</p>
          
          <p class="font-bold mt-4">Verwendung von Facebook Remarketing</p>
          <p>Wir verwenden auf unserer Website die Remarketing-Funktion „Custom Audiences“ der Facebook Inc. (1601 S. California Ave, Palo Alto, CA 94304, USA; "Facebook").
              Diese Funktion dient dem Zweck die Besucher der Website zielgerichtet mit interessenbezogener Werbung im sozialen Netzwerk Facebook anzusprechen.
              Dazu wurde auf der Website das Remarketing-Tag von Facebook implementiert. Über dieses Tag wird beim Besuch der Website eine direkte Verbindung zu den Facebook-Servern hergestellt. Hierdurch wird an den Facebook-Server übermittelt, welche unserer Seiten Sie besucht haben. Facebook ordnet diese Information Ihrem persönlichen Facebook-Benutzerkonto zu. Wenn Sie das soziale Netzwerk Facebook besuchen, werden Ihnen dann personalisierte, interessenbezogene Facebook-Ads angezeigt. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigen Interesse an dem oben genannten Zweck.
              Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Dazu können Sie die Remarketing-Funktion „Custom Audiences“ hier deaktivieren.
              Nähere Informationen zur Erhebung und Nutzung der Daten durch Facebook, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von Facebook unter <a class="text-light font-bold" href="https://www.facebook.com/about/privacy/" target="_blank">https://www.facebook.com/about/privacy/</a>.</p>
          
          <p class="font-bold mt-4">Verwendung des Live-Chat-Systems LiveZilla</p>
          <p>Wir verwenden auf unserer Website das Chat System der LiveZilla GmbH (Byk-Gulden-Straße 18, 78224 Singen).
              Das System dient dem Zweck der Kommunikation zwischen Ihnen und uns als Anbieter. Dabei werden auch Daten zu Marketing- und Optimierungszwecken gesammelt und gespeichert. Aus diesen Daten können unter einem Pseudonym Nutzungsprofile erstellt werden.
              Hierzu können Cookies eingesetzt werden. Die Cookies ermöglichen die Wiedererkennung des Internet-Browsers. Die mit den LiveZilla-Technologien erhobenen Daten werden ohne die gesondert erteilte Zustimmung des Betroffenen nicht dazu benutzt, den Besucher dieser Website persönlich zu identifizieren und nicht mit personenbezogenen Daten über den Träger des Pseudonyms zusammengeführt.
              Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) f) DSGVO aus dem berechtigten Interesse an direkter Kundenkommunikation und an der bedarfsgerechten Gestaltung der Website. Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Sie können den Widerspruch ausüben, indem Sie die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</p>
          
          <p class="font-bold mt-4">Verwendung von YouTube</p>
          <p>Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der YouTube LLC. (901 Cherry Ave., San Bruno, CA 94066, USA; „YouTube“).
              YouTube ist ein mit der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”) verbundenes Unternehmen.
              Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die Option „Erweiterter Datenschutzmodus“ aktiviert. Dadurch werden von YouTube keine Informationen über die Besucher der Website gespeichert. Erst wenn Sie sich ein Video ansehen, werden Informationen darüber an YouTube übermittelt und dort gespeichert.
              Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von YouTube ( <a class="text-light font-bold" href="https://www.youtube.com/t/privacy" target="_blank">https://www.youtube.com/t/privacy</a>).</p>
          
          <p class="font-bold mt-4">Verwendung von GoogleMaps</p>
          <p>Wir verwenden auf unserer Website die Funktion zur Einbettung von GoogleMaps-Karten der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”).
              Die Funktion ermöglicht die visuelle Darstellung von geographischen Informationen und interaktiven Landkarten.
              Dabei werden von Google bei Aufrufen der Seiten, in die GoogleMaps-Karten eingebunden sich, auch Daten der Besucher der Seiten erhoben, verarbeitet und genutzt. Nähere Informationen zur Erhebung und Nutzung der Daten durch Google finden Sie in den Datenschutzhinweisen von Google unter <a class="text-light font-bold" href="https://www.google.com/privacypolicy.html" target="_blank">https://www.google.com/privacypolicy.html</a>. Dort haben Sie auch im Datenschutzcenter die Möglichkeit Ihre Einstellungen zu verändern, so dass Sie Ihre von Google verarbeiteten Daten verwalten und schützen können.
              Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für Datenübermittlungen in die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission vorhanden.
              Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.
              Dazu müssen Sie die Anwendung JavaScript in Ihrem Browser ausschalten. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website, wie bspw. die interaktive Kartenanzeige, vollumfänglich werden nutzen können.</p>
          
          <p class="font-bold mt-4">Dauer der Speicherung</p>
          <p>Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
          
          <p class="font-bold mt-4">Rechte der betroffenen Person</p>
          <p>Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.
              Außerdem steht Ihnen nach Art. 21 (1) DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 (1) f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.</p>
          <p>Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem Impressum.</p>
          
          <p class="font-bold mt-4">Beschwerderecht bei der Aufsichtsbehörde</p>
          <p>Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.</p>
          
          <p>letzte Aktualisierung: {{ dateRefresh }}</p>
        </b-tab>
        <!-- END TAB POLICY-->
      </b-tabs>
    </b-col>
    
  </b-row>
 
</b-container>
  
           
</template>

<script>


export default {
  name: "viewPolicy",
  data () {
    return {
      company: 'Shopentwickler E-Commerce GmbH',
      dateRefresh: '24.05.2018'
      
    }
  },

}
</script>

<style scoped>
</style>