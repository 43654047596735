<template>
  <b-container fluid="xxl">


      <b-row>
        <b-col cols="12"   xl="6" xxl="4">

          <h3>{{ $t('NAV_LINK_TEXT_ACADEMY') }}</h3>
          <template  v-if="getProducts.length == 0">
            <p class="mb-5" v-html="$t('NO_VIDEO_IN_LIST')"></p>
          </template>

         
      </b-col>
    </b-row>
    
    

    <b-row  v-if="getProducts.length > 0">

      <!-- item -->
      <b-col
          v-for="(product, product_index)  in getProducts"
          :key="product_index"
          cols="12" xl="6" xxl="4"
          class="mt-4 mb-3"
      >
        <ProductCard 
          :no-padding="true"
          :product-id="product.product_id  | tostring"
          :product-title="product.product_title" 
          :product-image="$utils.getMediaImg(3, product.media, 'product_media_description_type_id')"
          :product-type="product.product_type"
          :product-cta-link="`/academylesson/${product.product_id}`"
          :product-btn-label="$t('GO_TO_LEARNING_CONTENT')"
          :product-description="product.product_description | truncate"
          :product-price="`${product.product_price}`"
          :product-pseudo-price="`${product.product_pseudoprice}`"
          :product-is-my-course="product.is_my_course"
          v-on:submit="handleProductCardEvent(product)"
          :small-layout="true"
          :badge-text="`${badgeCourseTextHandler(product)}`"


        />


      </b-col>

    </b-row>

    
     
  </b-container>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";
    import ProductCard from '@/components/ProductCard.vue';
    import Vue from 'vue';

    export default {
        name: "areaAcademyVideoList",
        components: {
            ProductCard
        },
        data() {
          return {
            API_URL: process.env.VUE_APP_API,
          }
        },
        mounted() {
            this.loadCourses();
        },
        computed: {
            ...mapGetters( {
                getProducts : 'getProducts',
            }),

        },
        methods: {
          ...mapActions([
              'loadCourses',
              'trackSaleCourse'
          ]),

          handleProductCardEvent(data) {
         
      
            if (data.product_price == 0
                || data.is_my_course
            ) {
              //wird am Server abgefangen, ob bereits gekauft oder nicht
              this.trackSaleCourse({id: data.product_id});
            } else  {
              window.open(data.product_url,'_blank');
            }

          },


          badgeCourseTextHandler(product) {

            var label = '';

            if (product.is_my_course) {

              label = this.$t('PURCHASED_LEARNING_CONTENT');

            } else if (product.product_price > 0) {

              label = Vue.utils.formatPrice(product.product_price);

            } else {

              label = this.$t('FREE');

            }

            return label;

          }

        },


    }
</script>

<style scoped>

</style>