
<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">

  <g id="icon_close" transform="translate(-543 -165)">
    <g  transform="translate(-569.134 -111.136)">
      <path  d="M0,20a2,2,0,0,1-2-2V0A2,2,0,0,1,0-2,2,2,0,0,1,2,0V18A2,2,0,0,1,0,20Z" transform="translate(1130.863 282.136) rotate(45)" fill="#fff"/>
      <path  d="M0,20a2,2,0,0,1-2-2V0A2,2,0,0,1,0-2,2,2,0,0,1,2,0V18A2,2,0,0,1,0,20Z" transform="translate(1118.135 282.136) rotate(-45)" fill="#fff"/>
    </g>
  </g>
</svg>



</template>

<script>
export default {
  name: "CLOSE",
};
</script>
