<template>
    <button class="wl-check" :id="id" :class="{'wl-check--ischecked' : checked}" @click="select()">
        
    </button>
</template>

<script>

export default {
    name: "uiCheck",
    emits: ['change'],
    data() {
        return {
        }
    },

    props: {
        
        checked: {
            type:Boolean,
            default:false
        },
        context: {
            type:Object,
            default: () => {}
        },
        id: String
            
    },
    mounted() {
       
      
    },
    methods: {
        select() {
            this.isChecked = !this.isChecked;
            this.$emit('change', {
                context: this.context
            });
        }
    }
}
</script>