<template>

<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="21.916" viewBox="0 0 24 21.916">

  <g>
    <path d="M152.09,4.961c0,.11,0,.22,0,.33a1.336,1.336,0,0,0,.179.895,1.125,1.125,0,0,1,.148.56,7.817,7.817,0,0,1-.222,2.355,1.548,1.548,0,0,1-.658.966.413.413,0,0,0-.149.265,4.774,4.774,0,0,1-2.065,3.316,3.85,3.85,0,0,1-4.8-.29,4.647,4.647,0,0,1-1.653-2.97.454.454,0,0,0-.231-.376,1.179,1.179,0,0,1-.538-.719,6.919,6.919,0,0,1-.259-2.694.92.92,0,0,1,.139-.442c.223-.278.154-.585.167-.894a14.314,14.314,0,0,1,.152-2.127,3.507,3.507,0,0,1,2.59-2.8A6.65,6.65,0,0,1,148.941.3,2.483,2.483,0,0,1,150.52,1.62a.437.437,0,0,0,.172.179,2.751,2.751,0,0,1,1.4,2.479c.009.227,0,.455,0,.683m-8.253,2.206c-.033-.072-.065-.148-.1-.223-.079-.156-.122-.4-.344-.356s-.258.284-.243.477c.046.575.109,1.149.179,1.721a.427.427,0,0,0,.3.389.583.583,0,0,1,.431.581,3.611,3.611,0,0,0,1.2,2.451,2.694,2.694,0,0,0,3.777-.052A3.8,3.8,0,0,0,150.2,9.64a.434.434,0,0,1,.3-.414.622.622,0,0,0,.447-.6c.016-.121.058-.241.065-.362.026-.46.058-.921.052-1.381,0-.1-.144-.207-.221-.31a1.313,1.313,0,0,0-.264.222,2.629,2.629,0,0,0-.2.406c0-.584.007-1.138,0-1.692a2.241,2.241,0,0,0-.659-1.517,1.078,1.078,0,0,0-1.312-.214c-.194.1-.385.2-.58.3a1.648,1.648,0,0,1-1.5.01c-.215-.1-.426-.214-.641-.318a1.077,1.077,0,0,0-1.241.2,1.854,1.854,0,0,0-.6,1.413c0,.593,0,1.186,0,1.789" transform="translate(-135.119 0)" fill="#250719"/>
    <path d="M1.327,299a.325.325,0,0,0,.324-.3c.016-.2.032-.393.048-.586l.011-.131c.07-.84.147-1.684.222-2.5a.442.442,0,0,1,.344-.441c1.526-.511,3.063-1,4.633-1.5a4.793,4.793,0,0,0,1.206-.578c.054.286.115.577.2.87a2.611,2.611,0,0,0,1.349,1.844,1.838,1.838,0,0,1,.81.823c.176.3.372.58.561.848.084.118.163.23.24.344a.828.828,0,0,0,.082.1.849.849,0,0,0,.762.393.824.824,0,0,0,.653-.381c.267-.42.583-.969.966-1.678a3.125,3.125,0,0,1,.916-1.1,3.193,3.193,0,0,0,1.251-2.051,7.972,7.972,0,0,0,1.935.81c.921.281,1.855.586,2.758.881l1.049.342a.59.59,0,0,1,.443.618c.038.6.093,1.21.146,1.8q.035.387.069.775.021.253.044.505v0a.325.325,0,0,0,.324.3h1a.326.326,0,0,0,.324-.354l-.008-.091q-.022-.249-.044-.5-.033-.392-.069-.783c-.052-.576-.106-1.172-.143-1.753a2.229,2.229,0,0,0-1.581-2.082l-1.045-.34c-.911-.3-1.853-.605-2.789-.891a4.685,4.685,0,0,1-2.061-1.043,1.242,1.242,0,0,0-.272-.207,1.048,1.048,0,0,0-1.06-.057,1.065,1.065,0,0,0-.537.937l0,.03a4.055,4.055,0,0,1-.066.628,1.7,1.7,0,0,1-.711,1.252,4.743,4.743,0,0,0-1.326,1.6q-.146.271-.278.508-.057-.09-.11-.18a3.422,3.422,0,0,0-1.411-1.422c-.3-.172-.41-.237-.588-.865-.092-.324-.154-.667-.22-1.03-.032-.175-.065-.357-.1-.538-.017-.085-.036-.17-.055-.255l-.024-.107a.825.825,0,0,0-.634-.631.851.851,0,0,0-.454.032l-.09.03c-.1.034-.22.073-.341.122a1.081,1.081,0,0,0-.37.26l-.007.007a3.128,3.128,0,0,1-1.195.7c-1.577.5-3.12.995-4.657,1.51a2.081,2.081,0,0,0-1.462,1.855c-.075.819-.152,1.666-.223,2.512l-.011.131q-.028.332-.055.672A.325.325,0,0,0,.326,299Z" transform="translate(-0.001 -277.088)" fill="#250719"/>
  </g>
</svg>
</template>

<script>
export default {
  name: "VISITORS",
};
</script>