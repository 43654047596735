import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import areaUserList from "@/components/areaUserList";
import areaUserEdit from "@/components/areaUserEdit";
import areaRecording from "@/components/areaRecording";
import areaRecordingWeeks from "@/components/areaRecordingWeeks";
import areaRecordingMonths from "@/components/areaRecordingMonths";
import areaStatistic from "@/components/areaStatistic";
import areaAdminProductList from "@/components/areaAdminProductList";
import areaProductEdit from "@/components/areaProductEdit";
import areaAcademyVideo from '@/components/areaAcademyVideo';
import areaAcademyVideoList from '@/components/areaAcademyVideoList' 
//import { mapGetters } from 'vuex';
import store from './store'
import areaMyProducts from "@/components/areaMyProducts";
import areaMyChecklistProjects from "@/components/areaMyChecklistProjects";
import areaMyChecklistOverview from "@/components/areaMyChecklistOverview";
import areaCompanyList from "@/components/areaCompanyList";
import areaError404 from "@/components/areaError404";

import viewLoginOrSignup from '@/views/viewLoginOrSignup';
import viewPWActivation from '@/views/viewPWActivation';
import viewDashboard from '@/views/viewDashboard';


const routes = [
    {
        path: '/',
        alias: '/login',
        name: 'login',        
        component: viewLoginOrSignup,     
        meta: {
            requiresAuth: false,
            layout: 'LoginLayout'
        }
    },
    {
        path: '/passwordForgottenActivation/:user_key?',
        name: 'password-forgotten-activation',
        component: viewPWActivation,
        props: true,
        meta: {
            requiresAuth: false,
            layout: 'LoginLayout'
        }
    },
    {
        path: '/userList',
        name: 'user-list',
        component: areaUserList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    {
        path: '/userEdit/:user_id',
        name: 'user-edit-detail',
        component: areaUserEdit,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/userEdit',
        name: 'user-edit',
        component: areaUserEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recording',
        name: 'recording',
        component: areaRecording,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recordingWeeks',
        name: 'recording-weeks',
        component: areaRecordingWeeks,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/recordingMonths',
        name: 'recording-months',
        component: areaRecordingMonths,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/statistic',
        name: 'statistic',
        component: areaStatistic,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewStatisticHints'
        }
    },
    {
        path: '/adminProductList',
        name: 'admin-product-list',
        component: areaAdminProductList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    {
        path: '/productEdit/:product_id',
        name: 'edit-product-detail',
        component: areaProductEdit,
        props: true,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/productEdit',
        name: 'edit-product',
        component: areaProductEdit,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/myProducts',
        name: 'my-products',
        component: areaMyProducts,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewProductRecommendation'
        }
    },
    {
        path: '/myChecklistProjects/:product_id',
        name: 'my-checklist-projects',
        component: areaMyChecklistProjects,
        props: true,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewProductRecommendation'
        }
    },     
    {
        path: '/academylessons',
        name: 'academy-lessons',
        component: areaAcademyVideoList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewTasks'
        }
    },{
        path: '/academylesson/:video_id',
        name: 'academy-lesson',
        component: areaAcademyVideo,
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewLessons'
        }
    },
    {
        path: '/myChecklistOverview/:company_checklist_id',
        name: 'my-checklist-overview',
        component: areaMyChecklistOverview,
        props: true,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    {
        path: '/companyList',
        name: 'Firmen',
        component: areaCompanyList,
        meta: {
            requiresAuth: true,
            layout: 'DefaultOneColumnLayout'
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        components: {
            default: viewDashboard
        },
        meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            sidebar: 'viewTasks'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error-404',
        component: areaError404,
        meta: {
            requiresAuth: false,
            layout: 'FullWidthLayout'
        }
    }

]

const router = new VueRouter ({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (!store.getters.getIsLoggedIn && to.meta.requiresAuth) {
        next('/login' );
        //next()
    } else {
        next() // go to wherever I'm going
    }

})

//this.$router

export default router;