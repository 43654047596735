<template>
     <div class="wl-anim-overlay wl-anim-overlay--bg-secondary">
        <div ref="starter" class="wl-anim-overlay__button rounded-circle" v-on:click="show">
            <iconPFW ref="iconPFW" />
        </div>

        <div class="wl-anim-overlay__content" :class="{ active: isActive }">
            
            <div ref="animatedcircle" class="wl-anim-overlay__animated-circle rounded-circle"></div>
            <div class="wl-anim-overlay__closer" ref="closer" v-on:click="hide">
                <iconCLOSE />
            </div>
            <div ref="slotcontent" class="wl-anim-overlay__slot-content">
                 <slot></slot>
            </div>
           
        </div>
        
    </div>
</template>

<script>

import { gsap  } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import iconPFW from "@/icons/FPW";
import iconCLOSE from "@/icons/CLOSE";

gsap.registerPlugin(CSSPlugin);

export default {
    name: "hocAnimatedOverlay",
    components: {
        iconPFW,
        iconCLOSE
    },
    data() {
        return {
            timeline: null,
            isActive: false
        }
    },
    methods:{
        show(){
            this.timeline.play();
            this.isActive = true;
        }, 
        hide() {
            this.timeline.reverse();
            this.isActive = false
        }
    },
    mounted() {


        const { starter, iconPFW, closer, animatedcircle, slotcontent } = this.$refs;

        this.timeline = gsap.timeline({paused: true});
        
        this.timeline.to(iconPFW.$el, {opacity:0, duration:0.1});
        this.timeline.to(starter,  {
            left:"50%", 
            top:"50%",
            right: "auto",
            translateY: '-50%',
            translateX: '-50%',
            duration: .5,
            ease: "expo.out", 
        });
        this.timeline.to(starter, {opacity:0, duration:0});
        this.timeline.to(animatedcircle, {opacity:1, duration:0});
        this.timeline.to(animatedcircle, {scale:'12', ease: "expo.out", duration:0.8});
        this.timeline.fromTo(slotcontent, {autoAlpha: 0, y: 10}, {autoAlpha: 1, y: 0, ease: "expo.out", duration: .7}, "-=.4");
        this.timeline.fromTo(closer, {autoAlpha: 0, x: 10}, {autoAlpha: 1, x: 0, ease: "expo.out", duration: 0.5}, "-=.7");
    },
    unmounted() {
        this.timeline.kill();
    }

}
</script>
