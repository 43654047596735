<template>

<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20.998" height="21" viewBox="0 0 20.998 21">


    <g>
      <path  d="M10.5,21c-.189,0-.377,0-.567-.013A10.54,10.54,0,1,1,10.5,21M10.5,1.85a8.661,8.661,0,1,0,.468.013c-.157-.009-.312-.013-.468-.013" transform="translate(0 -0.001)" fill="#250719"/>
      <path d="M9.5,6.287,9.2,7.5,6.513,7.361c-.013.091-.02.168-.024.231l-.011.192c0,.067-.006.124-.009.174s0,.12,0,.211l2.536.137-.3,1.218-2.14-.115a1.836,1.836,0,0,0,.579,1.074,1.83,1.83,0,0,0,1.043.348,4.737,4.737,0,0,0,.837-.03,4.08,4.08,0,0,0,.86-.2l.283,1.449a3.9,3.9,0,0,1-.88.259,5.276,5.276,0,0,1-1.246.065,3.4,3.4,0,0,1-2.333-.88,3.727,3.727,0,0,1-.97-2.179l-.895-.048.067-1.231.784.043a2.1,2.1,0,0,1,0-.213c0-.05.007-.107.009-.174l.013-.211a1.222,1.222,0,0,1,.024-.211l-.786-.043.068-1.231.943.05A3.593,3.593,0,0,1,6.306,4.008,3.677,3.677,0,0,1,8.67,3.424a4.555,4.555,0,0,1,.952.139,5.68,5.68,0,0,1,.784.279L9.944,5.263a6.181,6.181,0,0,0-.6-.2,3.634,3.634,0,0,0-.767-.111,1.872,1.872,0,0,0-1.181.261,1.833,1.833,0,0,0-.652.926Z" transform="translate(3.26 2.897)" fill="#250719"/>
    </g>
</svg>
</template>

<script>
export default {
  name: "TURNOVER",
};
</script>