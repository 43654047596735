<template>
  <b-container fluid="xxl">
    <b-row>
        <b-col cols="12" xl="6">         
          <h3>{{$t('USER')}}</h3>
      </b-col>

      <b-col cols="12" xl="6"> 
        <b-form-group
            :label="$t('SEARCH')"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="md"
            class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('TERM')"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="cta">{{$t('DELETE')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>



    <b-table
        sticky-header
        striped hover
        :fields="fields"
        :items="getUserList"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        stacked="xl"
        show-empty
        class="mt-4"
        small
        @filtered="onFiltered"
    >

      <template #cell(company)="data">

        <strong>{{ data.value.name }}</strong><br/>
        <span v-if="data.value.additional_address != ''">{{ data.value.additional_address }}<br/></span>
        {{ data.value.street }}<br/>
        {{ data.value.plz }} {{ data.value.city }}

      </template>

      <template #cell(name)="data">

        <strong>{{ data.value.firstname }} {{ data.value.lastname }}</strong><br/>
        {{ data.value.telephone }}<br/>
        <a
            :href="'mailto:' + data.value.email"
            class="btn-link link-primary">{{ data.value.email }}</a>

      </template>

      <template #cell(status)="data">

        <span :class="data.value == 1 ? 'wl-status wl-status--active': 'wl-status wl-status--inactive'"></span>
      </template>

      <template #cell(last_login)="data">

        <span
            v-if="data.value !== '' && data.value != '00.00.0000'">{{ data.value }}</span>

        <span
          v-else>noch nie</span>

      </template>

      <template #cell(last_insert)="data">

        <span
            v-if="data.value !== '' && data.value != '00.00.0000'">
          <span
              v-if="data.item.last_insert_is_red"
              class="text-danger">{{ data.value }}</span>

          <span
              v-else>{{ data.value }}</span>

        </span>

        <span
            v-else>noch nie</span>

      </template>

      <template #cell(edit)="data">
        <b-button
                  type="button"
                  variant="cta"
                  class="button-cta rounded-circle icon small"
                  :to="'/userEdit/' + data.item.user_id"
              >
              <font-awesome-icon icon="fa-solid fa-user-pen" /></b-button>
      

      </template>

    </b-table>

    <b-row>
      <b-col cols="12">
        <b-button to="/userEdit" type="button" variant="cta" class="button-cta mt-4">
            {{ $t('CREATE_NEW_USER') }}
        </b-button>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>

/*
<pre>{{ getDates(new Date("2021-01-01T00:00:00"), new Date("2022-03-08T00:00:00")) }}</pre>
 */

import { mapMutations, mapGetters, mapActions } from 'vuex';
import axios from "axios";

export default {
  name: "areaUserList",
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      fields: [
        {
          key: 'company',
          label: 'Firma',
          sortable: true
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false
        },
        {
          key: 'type',
          label: 'Typ',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'last_login',
          label: 'Letzter Login',
          sortable: true
        },
        {
          key: 'last_insert',
          label: 'Letzte Eintragung',
          sortable: true
        },
        {
          key: 'edit',
          label: 'Bearbeiten',
          sortable: true
        }
      ],
      filter: null,
      filterOn: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 5000,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
    }
  },
  mounted() {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

    this.loadUserList();
    // Set the initial number of items
    //this.totalRows = this.getUserList.length
  },
  computed: {
    ...mapGetters([
      'getUserList',
      'getUser'
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
    },
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
    ]),
    ...mapGetters([
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getFormattedDate(date) {

      var res = date.toISOString().slice(0,10).split(/-/);
      return res[2] + '.' + res[1] + '.' + res[0];

    },
    getDates(startDate, endDate) {
      //first the function and variable definitions
      let dates = []
      const addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      //now our Sunday check
      let currentDate = startDate
      if (currentDate.getDay() > 0) {
        //console.log('not a sunday...adjusting')
        currentDate.setDate(currentDate.getDate() - ((currentDate.getDay() + 6) % 7));
      }

      while (currentDate <= endDate) {
        let endWeekDate = addDays.call(currentDate, 6);

        var week = (new Date(currentDate)).getWeek();
        var old_year = currentDate.getFullYear();
        var new_year = endWeekDate.getFullYear();

        var year = old_year;

        if (week == 1) {
          year = new_year;
        }

        var week_str = week;

        if (week <= 9) week_str = '0' + week;

        dates.push(
            {
              begin: currentDate,
              begin_formatted: this.getFormattedDate(currentDate),
              end: endWeekDate,
              end_formatted: this.getFormattedDate(endWeekDate),
              week: week,
              week_str: week_str,
              year: year
            });
        currentDate = addDays.call(currentDate, 7);
      }
      return dates;
    },

    async loadUserList() {

      //console.log('loadUserList');

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_userlist',
            cookie: cookie,
          }
      );

      //console.log(response);

      if (response.data.error) {

        //console.log(response);
        this.$store.dispatch('setUserList', {});

      } else {

        //console.log('response');
        //console.log(response.data);
        this.$store.dispatch('setUserList', response.data);
        // Set the initial number of items
        this.totalRows = this.getUserList.length;
        //console.log(this.getUserList);

      }

    },
    checkUserEdit(actual_user) {
      //console.log(actual_user);
      //console.log(this.getUser.user_id);

      //wenn kein Admin
      if (actual_user.user_level < 40) return true;

      //admin
      let user = this.getUser;

      if (user.user_level == 40) return true;

      return false;

    }

}
}
</script>

<style scoped>

</style>