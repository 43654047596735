<template>
  
  <div class="wl-card__slider__image" :class="slide === id ? 'wl-card__slider__image--active' : ''" :style="`background-image: url(${productImage})`"></div>
   
</template>

<script>

export default {
  name: "CardSliderImage",
  props: {
    slide : {
        type: Number,
        default: 0
    },
    productImage: {
        type: String,
        default: ''
    },
    id: {
        type: Number,
        default: 0
    },

  }
}
</script>
