<template>
    <div class="wl-circular-timer">
        <canvas :height="dimensions" :width="dimensions" ref="counter"></canvas> 
    </div>
    
</template>

<script>

    export default {        
        name: "uiCircularTimer",
        emits: ['finished'],
        data() {
            return {
                startTime :null,
                duration:null,
                intervalID: 0
            }
        },
        props: {
            dimensions:{
                type: Number,
                default: 80
            },
            strokeColor: {
                type: String,
                default: '#666666'
            },
            lineWidth: {
                type: Number,
                default: 3
            },
            interval: {
                type: Number,
                default:4000
            }
        },

        methods: {
           draw(time) {
            
            const { counter } = this.$refs;
            const context = counter.getContext('2d');
            context.lineWidth = this.lineWidth;
            context.strokeStyle = this.strokeColor;

            const radius =  (this.dimensions - 10) / 2;
            const endPercent = 100;
            const quart = -(Math.PI * 2) + 1;

            if (!this.startTime) {
                this.startTime = time;
            }

            const delta = Math.min(1, (time - this.startTime) / this.interval);
            const curPerc = ((-2 * Math.PI) / 100) * (endPercent * delta);

            context.clearRect(0, 0, this.dimensions, this.dimensions);
            context.beginPath();
            context.arc(this.dimensions / 2, this.dimensions / 2, radius, -quart, curPerc - quart, true);
            context.stroke();


            if (delta < 1) {
                this.intervalID = requestAnimationFrame(this.draw);
            } else {
                this.startTime = null;
                cancelAnimationFrame(this.intervalID);                
                this.$emit('finished');
            }
           
           }, 
           start() {
                this.intervalID = requestAnimationFrame(this.draw);
           },
           stop() {
                cancelAnimationFrame(this.intervalID);    
                this.startTime = null;      
           }
        },
        beforeDestroy() {
            this.stop()
        }
    }
    </script>