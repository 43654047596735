<template>
    <div class="wl-groupselect d-flex flex-nowrap" >
        
       <button :id="`comp-${id}-${index}`" class="wl-groupselect__option" v-for="(option, index) in groupOptions" :key="index" :class="[{'wl-groupselect__option--selected':option.selected}, `bg-${option.variant}`]" @click="select(option)"></button>
       <b-tooltip :target="`comp-${id}-${ttindex}`" v-for="(option, ttindex) in groupOptions" :variant="option.variant" :triggers="['hover']" cnoninteractive :key="`tt-${ttindex}`" >{{option.text}}</b-tooltip>
       
    </div>
</template>

<script>

export default {
    name: "uiGroupSelect",
    emits: ['change'],
    data() {
        return {
            groupOptions: this.options,
            currentValue: -1
        }
    },

    props: {
        options: {
            type: Array,
            default: () => []
        },
        context: {
            type:Object,
            default: () => {}
        },
        id: String
            
    },
    mounted() {
       
      
    },
    methods: {
        select(option) {

            this.groupOptions = this.groupOptions.map( item =>  {
                if(item.value === option.value ) {
                     item['selected'] = true;
                     this.currentValue = option.value
                    
                } else {
                    item['selected'] = false;
                }
                
                return item;
            })
           
            this.$emit('change', {
                value: this.currentValue,
                context: this.context
            });
        }
    },
    watch: {
        options(newVal, oldVal) {
            if(newVal != oldVal) {
                this.groupOptions = this.options;
            }
           
        }
    }
}
</script>