<template>
    <hocCard :title="$t('PW_ACTIVATION_TITLE')" :subtitle="$t('PAGE_SUBTITLE')" variant="primary">
        <template #default><areaPasswordForgottenActivation/></template>
    </hocCard>
</template>

<script>

import areaPasswordForgottenActivation from '@/components/areaPasswordForgottenActivation';
import hocCard from '@/components/hoc/hocCard';

export default {
    name: "viewPWActivation",
    components: {
        hocCard,
        areaPasswordForgottenActivation
    }
}
</script>

<style scoped>
</style>