<template>
  <b-container fluid="xxl">

    <b-row>
        <b-col cols="12"   xl="6" xxl="4">         
          <h3>{{$t('NAV_LINK_TEXT_STATISTICS')}}</h3>
      </b-col>
    </b-row>

  

    <b-row class="mb-3">
      <b-col cols="12">
        <hocTask :title="$t('FILTER')" :open="true" :show-completion="false" class="mt-4">

            <b-container fluid="xxl" class="mt-4">
              

                <b-row>
                  <b-col cols="12" md="6">
                    <b-row>
                      <b-col cols="12 mb-2">
                        <h6>{{$t('APPEARANCE')}}</h6>
                      </b-col>
                      <b-col cols="12" md="auto" class="mr-md-2 mb-1">
                        <uiPill :label="$t('MONTHS')" v-on:update="toggleMainButton('monthButton', 'month')" :is-checked="activeMainButton === 'monthButton'" :is-disabled="activeMainButton === 'monthButton'" />
                      </b-col>
                      <b-col cols="12" md="auto" class="mr-md-2 mb-1">
                        <uiPill :label="$t('WEEKS')" v-on:update="toggleMainButton('weekButton', 'week')" :is-checked="activeMainButton === 'weekButton'" :is-disabled="activeMainButton === 'weekButton'" />
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-row>
                      <b-col cols="12 mb-2 mt-2">
                        <h6>{{$t('MAIN_CATEGORIES')}}</h6>
                      </b-col>
                      <b-col cols="12" md="auto" class="mr-md-2 mb-1" v-for="(category, index) in getMainCategories" :key="index">
                        <uiPill :label="category.main_category" v-on:update="collectFilter(category.main_category_id, 'main')"  :is-checked="JSON.parse(category.active)" />
                      </b-col>
                    </b-row>
                  </b-col>
                
                </b-row>

                <b-row>
                  <b-col cols="12 mb-2 mt-4">
                        <h6>{{$t('SUB_CATEGORIES')}}</h6>
                      </b-col>
                      <b-col cols="12" md="auto" class="mr-md-2 mb-1" v-for="(category, index) in getSubCategories" :key="index">
                        <uiPill :label="category.sub_category" v-on:update="collectFilter(category.sub_category_id, 'sub')" :is-checked="JSON.parse(category.active)" />
                      </b-col>
                </b-row>
            </b-container>
        </hocTask>

        
       
      </b-col>
    </b-row>

    <!--<b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">

        {{ /* {active: activeMainButton === 'monthButton'} */ }}
        <b-button
            :variant="[activeMainButton === 'monthButton' ? 'primary' : 'outline-primary']"
            class="mr-10"
            :class="[activeMainButton === 'monthButton' ? 'button-primary' : 'button-outline-primary']"
            id="monthButton"
            @click="toggleMainButton('monthButton', 'month')"
        >Monate</b-button>
        <b-button
            :variant="[activeMainButton === 'weekButton' ? 'primary' : 'outline-primary']"
            class="mr-10"
            :class="[activeMainButton === 'weekButton' ? 'button-primary' : 'button-outline-primary']"
            id="weekButton"
            @click="toggleMainButton('weekButton', 'week')"
        >Kalenderwochen</b-button>

      </b-col>
    </b-row>-->

    <!--<b-row class="justify-content-md-center mt-3">
      <b-col cols="12" md="auto">

        <h5>Hauptkategorien</h5>

      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">

           <b-button
              v-for="(category, index) in getMainCategories"
              :key="index"
              :variant="[category.active === true ? 'primary' : 'outline-primary']"
              class="mr-10 mt-3"
              :class="[category.active === true ? 'button-primary' : 'button-outline-primary']"
              @click="collectFilter(category.main_category_id, 'main')"
          >{{ category.main_category }}</b-button>

      </b-col>
    </b-row>-->

    <!--<b-row class="justify-content-md-center mt-3">
      <b-col cols="12" md="auto">

        <h5>Unterkategorien</h5>

      </b-col>
    </b-row>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">

          {{ /* :class="{active: category.active}" */ }}
          <b-button
              v-for="(category, index) in getSubCategories"
              :key="index"
              :variant="[category.active === true ? 'primary' : 'outline-primary']"
              class="mr-10 mt-3"
              :class="[category.active === true ? 'button-primary' : 'button-outline-primary']"
              @click="collectFilter(category.sub_category_id, 'sub')"
          >{{ category.sub_category }}</b-button>
      </b-col>
    </b-row>-->


    <div
      v-if="chartType === 'month'"
      >
      <b-row
          class="justify-content-md-center mb-3 mt-6"
      >
        <b-col cols="12" md="auto">

          <h3>Monate</h3>

        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3  w-100 "
      >
        <b-col cols="12">

          <!--<chart-turnover-months ref="chartTurnoverMonths"></chart-turnover-months>-->
<div id="chart">
        <apexchart type="area" height="450" :options="chartOptions" :series="series"></apexchart>
      </div>
        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3"
      >
        <b-col cols="12" md="auto">

          <!--<chart-visitor-months ref="chartVisitorMonths"></chart-visitor-months>-->


        </b-col>
      </b-row>

    </div>

    <div
        v-if="chartType === 'week'"
    >
      <b-row
          class="justify-content-md-center mb-3 mt-6"
      >
        <b-col cols="12" md="auto">

          <h3>Kalenderwochen</h3>

        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3"
      >
        <b-col cols="12" md="auto">

          <!--<chart-turnover-weeks ref="chartTurnoverWeeks"></chart-turnover-weeks>-->

        </b-col>
      </b-row>

      <b-row
          class="justify-content-md-center mb-3"
      >
        <b-col cols="12" md="auto">

          <!--<chart-visitor-weeks ref="chartVisitorWeeks"></chart-visitor-weeks>-->


        </b-col>
      </b-row>

    </div>

  </b-container>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
import uiPill from '@/components/ui/uiPill.vue';
import hocTask from '@/components/hoc/hocTask.vue';
/*import chartTurnoverWeeks from "@/components/chartTurnoverWeeks";
import chartVisitorWeeks from "@/components/chartVisitorWeeks";
import chartTurnoverMonths from "@/components/chartTurnoverMonths";
import chartVisitorMonths from "@/components/chartVisitorMonths";*/
import VueApexCharts from 'vue-apexcharts'
export default {
  name: "areaStatistic",
  components: {
    /*chartTurnoverWeeks,
    chartVisitorWeeks,
    chartTurnoverMonths,
    chartVisitorMonths*/
    uiPill,
    hocTask,
    apexchart: VueApexCharts
},
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      buttons: {},
      activeMainButton: 'monthButton',
      chartType: 'month',
      activeButtons: [],
       series: [{
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100]
          }, {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41]
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'area'
            },
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: 'smooth',
              width: 2
            },

            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: .7,
                opacityFrom: 0.45,
                opacityTo: 0.2,
                stops: [0, 100]
              }
            },
         
            colors: ["#52388E", "#AD0267"],
            xaxis: {
              type: 'datetime',
              title: {
                text: 'Monate'
              },
              categories: ["2022-01-01T00:00:00.000Z", "2022-02-01T00:00:00.000Z", "2022-03-01T00:00:00.000Z", "2022-04-01T00:00:00.000Z", "2022-05-01T00:00:00.000Z", "2022-06-01T00:00:00.000Z", "2022-07-01T00:00:00.000Z"]
            },
            yaxis: {
              title: {
                text: 'Umsätze'
              }
            },
            tooltip: {
              x: {
                show: true,
                format: 'dd.MM.yy HH:mm'
              },
            },
          },
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters([
      'getRecordingDates',
      'getUser',
      'getMainCategories',
      'getSubCategories'
    ]),
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
        'setCategoryActive'
    ]),
    ...mapGetters([
        'getStatisticFilterButtons'
    ]),
    toggleMainButton(name, type) {
      //console.log(name);

      this.activeMainButton = name;
      this.chartType = type;

    },
    collectFilter(id, type) {

      //console.log(id);
      //console.log(type);

      //let buttons = this.buttons;
      let buttons = this.$store.getters.getStatisticFilterButtons;
      let obj = {};

      obj.category_id = id;
      obj.type = type;

      if (typeof buttons[id] === "undefined") {
        //console.log('add ' + id);
        buttons[id] = id;

        obj.active = true;

      } else {
        //console.log('lösche ' + id);
        delete buttons[id];

        obj.active = false;
      }

      this.$store.dispatch('setCategoryActive', obj);
      this.$store.dispatch('setStatisticFilterButtons', buttons);

      //console.log(buttons);
      //console.log('hier');

      /*
      const keys = Object.keys(buttons);
      keys.forEach((key) => {
        //console.log(`${key}: ${buttons[key]}`);
        //console.log(buttons);
        //console.log(buttons[key]);
      });
       */

      //this.buttons = buttons;

      if (this.chartType === 'month') {
        this.$refs.chartTurnoverMonths.getChartData();
        this.$refs.chartVisitorMonths.getChartData();
      } else {
        this.$refs.chartTurnoverWeeks.getChartData();
        this.$refs.chartVisitorWeeks.getChartData();
      }


    }
  },
}
</script>
