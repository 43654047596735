<template>
  <div id="app" class="app" :class="{ 'app--full-height': $route.name.match('error-404') }">
     <div class="page  w-100 d-flex" :class="{ 'page--error': $route.name.match('error-404') }">

      <div class="router-container  p-xl-5">
        <AppLayout>
          <router-view />
        </AppLayout>
      </div>
     </div>
   
     <div class="svg-filter">
       <!-- filters -->
       <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="5" />
                <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="hightContrastGraphic" />
                <feComposite in2="hightContrastGraphic" in="SourceGraphic" operator="atop" />
            </filter>
           
          </defs>
      </svg>
    </div>
  </div>


</template>

<script>
import AppLayout from "@/layouts/AppLayout"; 
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: 'App',
  components: {
    AppLayout 
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      interval: 0,
      intervalCounter: 0,
    }
  },
  computed: {
    ...mapMutations([
    ]),
    ...mapActions([
      'setIsLoggedIn',
    ]),
    ...mapGetters([
      'getIsLoggedIn',
      'getToken',
    ]),
    checkLogin() {

      this.loginCheckInterval();

      return true;
    },
  },
  methods: {
    loginCheckInterval() {
      this.interval = setInterval(() => this.sendLoginCheck(), 60000);
    },
    sendLoginCheck() {

      //console.log('sendLoginCheck');

      //todo: local development
      var cookie = this.$store.getters.getToken;

      axios.post(
          this.API_URL,
          {
            type: 'check_is_logged_in',
            cookie: cookie,
          }
      ).then((response) => {
        //console.log(response);

        //zum Testen
        /*
        this.intervalCounter++;

        if (this.intervalCounter >= 3) {
          this.$store.dispatch('setToken', '');
        }
         */

        if ((
                "is_logged_id" in response.data && response.data.is_logged_id === false
            )
            || ("error" in response.data)) {

          //console.log('Auto-Abmeldung');
          this.$store.dispatch('setIsLoggedIn', false);
          clearInterval(this.interval);
          window.location = '/';

        }

      }, (error) => {
        console.log(error);
        this.$store.dispatch('setIsLoggedIn', false);
      });

    },
  },
  watch: {
    getIsLoggedIn() {

      if (this.getIsLoggedIn) {

        this.checkLogin;

      }

    }
  }
}
</script>

<style>




.link-primary {
  color: #5d4498;
}

.link-primary:visited,
.link-primary:active,
.link-primary:link,
.link-primary:focus,
.link-primary:focus-within,
.link-primary:focus-visible
{
  color: #5d4498 !important;
}

.link-primary:hover
{
  color: #a28ad9 !important;
}

.little-button,
.little-button:visited,
.little-button:active,
.little-button:link,
.little-button:focus,
.little-button:focus-within,
.little-button:focus-visible,
.little-button:target {
  background: linear-gradient(0deg,#5d4498,#c5b7e8) !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0rem;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0,0,0,.5);
}


.little-button:hover
{
  background: #a28ad9 !important;
  border: none !important;
  border-radius: 5px !important;
  color: #fff !important;
  font-family: 'Ubuntu';
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0rem;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0,0,0,.5);
}



s {
  color: red;
  margin-right: 5px;
}
</style>
