<template>
  <b-container>

    <b-row class="justify-content-md-center mb-3">
      <b-col cols="12" md="auto">
        <h3>Benutzer anlegen / ändern</h3>
      </b-col>
    </b-row>

    <b-form id="userEditForm">

      <b-row class="">

        <b-col cols="6">

          <h5 class="mb-3">Benutzer-Informationen</h5>

        </b-col>

      </b-row>

      <b-row class="justify-content-md-center">

          <b-col cols="6">

            <b-form-group
                label="Anrede:"
                label-for="salutation"
            >
              <b-form-select
                  name="salutation"
                  id="salutation"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :options="[
                      {
                        text: 'Auswählen...',
                        value: null
                      },
                      {
                        text: 'Frau',
                        value: 0
                      },
                      {
                        text: 'Herr',
                        value: 1
                      }]"
                  v-model="formData.salutation"
                  :state="validation('salutation')"
              ></b-form-select>
              <b-form-invalid-feedback :state="validation('salutation')">
                Bitte wählen Sie die Anrede aus.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('salutation')"></b-form-valid-feedback>
            </b-form-group>

            <b-form-group
                label="Vorname:"
                label-for="firstname"
            >
              <b-form-input
                  name="firstname"
                  id="firstname"
                  type="text"
                  placeholder="Vorname"
                  :state="validation('firstname')"
                  v-model.trim="formData.firstname"
              ></b-form-input>
              <b-form-invalid-feedback :state="validation('firstname')">
                Der Vorname muss mindestens ein Zeichen enthalten.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('firstname')"></b-form-valid-feedback>

            </b-form-group>

            <b-form-group
                label="Nachname:"
                label-for="lastname"
            >
              <b-form-input
                  name="lastname"
                  id="lastname"
                  type="text"
                  placeholder="Nachname"
                  :state="validation('lastname')"
                  v-model.trim="formData.lastname"
              ></b-form-input>
              <b-form-invalid-feedback :state="validation('lastname')">
                Der Nachname muss mindestens ein Zeichen enthalten.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('lastname')"></b-form-valid-feedback>
            </b-form-group>

            <b-form-group
                label="Telefon:"
                label-for="telephone"
            >
              <b-form-input
                  name="telephone"
                  id="telephone"
                  type="text"
                  placeholder="Telefon"
                  :state="validation('telephone')"
                  v-model.trim="formData.telephone"
              ></b-form-input>
              <b-form-invalid-feedback :state="validation('telephone')">
                Bitte geben Sie eine gültige Telefonnummer ein.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('telephone')"></b-form-valid-feedback>
            </b-form-group>

            <b-form-group
                label="E-Mail:"
                label-for="email"
            >
              <b-form-input
                  name="email"
                  id="email"
                  type="email"
                  placeholder="E-Mail-Adresse"
                  :state="validation('email')"
                  v-model.trim="formData.email"
                  ></b-form-input>
              <b-form-invalid-feedback :state="validation('email')">
                Bitte geben Sie eine gültige E-Mail-Adresse ein.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('email')"></b-form-valid-feedback>
              <b-alert
                  :show="showEmailExistAlert"
                  variant="danger">
                Die E-Mail-Adresse ist bereits vergeben.
              </b-alert>

            </b-form-group>

            <b-form-group
                label="Typ:"
                label-for="type"
            >
              <b-form-select
                  name="type"
                  id="type"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :options="[
                      {
                        text: 'Auswählen...',
                        value: null
                      },



                      {
                        text: 'Lead',
                        value: 1,
                      },
                      {
                        text: 'Mitglied',
                        value: 2,
                      },
                      {
                        text: 'Mastermind',
                        value: 5,
                      },
                      {
                        text: '1x1 Mastermind',
                        value: 6,
                      },
                      {
                        text: 'Mitarbeiter',
                        value: 3,
                      },
                      {
                        text: 'Admin',
                        value: 4,
                      }

                      ]"
                  v-model="formData.user_type"
                  :state="validation('user_type')"
              ></b-form-select>
              <b-form-invalid-feedback :state="validation('user_type')">
                Bitte wählen Sie den Typen aus.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation('user_type')"></b-form-valid-feedback>
            </b-form-group>



          </b-col>

          <b-col cols="6">

            <span
              v-if="user.status == 1">
              <b-button
                  type="button"
                  variant="danger"
                  class="mb-4"
                  :disabled="busy"
                  @click="newCredentials">Neue Zugangsdaten senden</b-button><br/>

              <b-button
                  type="button"
                  variant="danger"
                  class="mb-4"
                  :disabled="busy"
                  @click="deactivateUser">Benutzer sperren</b-button><br/>
            </span>

            <span
              v-if="user.status == 0">

              <b-button
                  type="button"
                  variant="danger"
                  class="mb-4"
                  :disabled="busy"
                  @click="activateUser">Benutzer aktivieren</b-button><br/>

            </span>

          </b-col>



      </b-row>

      <b-row class="">

        <b-col cols="6">

          <h5 class="mb-3 mt-5">Firmen-Informationen</h5>

        </b-col>

      </b-row>

      <b-row class="justify-content-md-center">

        <b-col cols="6">

          <b-form-group
              label="Firma:"
              label-for="company"
          >
            <b-form-input
                name="company"
                id="company"
                type="text"
                list="companyList"
                placeholder="Firma"
                :state="validation('company')"
                v-model.trim="formData.company"
            ></b-form-input>
            <datalist id="companyList">
              <option
                  v-for="(company_area, index) in companies"
                  :key="index"
                  :id="'company_area' + index"
                  :value="company_area.company.company"
              >
                {{ company_area.company.company }}
              </option>
            </datalist>
            <b-form-invalid-feedback :state="validation('company')">
              Bitte geben Sie den Namen der Firma ein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('company')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Adress-Info:"
              label-for="additional_address"
          >
            <b-form-input
                name="additional_address"
                id="additional_address"
                type="text"
                placeholder="Adress-Info"
                v-model.trim="formData.additional_address"
            ></b-form-input>
          </b-form-group>

          <b-form-group
              label="Straße:"
              label-for="street"
          >
            <b-form-input
                name="street"
                id="street"
                type="text"
                placeholder="Straße"
                :state="validation('street')"
                v-model.trim="formData.street"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('street')">
              Bitte geben Sie die Straße mit Hausnummer ein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('street')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="PLZ:"
              label-for="plz"
          >
            <b-form-input
                name="plz"
                id="plz"
                type="text"
                placeholder="PLZ"
                :state="validation('plz')"
                v-model.trim="formData.plz"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('plz')">
              Bitte geben Sie die Postleitzahl ein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('plz')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Ort:"
              label-for="city"
          >
            <b-form-input
                name="city"
                id="city"
                type="text"
                placeholder="Ort"
                :state="validation('city')"
                v-model.trim="formData.city"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('city')">
              Bitte geben Sie den Ort ein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('city')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Land:"
              label-for="country"
          >
            <b-form-select
                name="country"
                id="country"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="countriesOptions"
                v-model="formData.country"
                :state="validation('country')"
            ></b-form-select>
            <b-form-invalid-feedback :state="validation('country')">
              Bitte wählen Sie ein Land aus.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('country')"></b-form-valid-feedback>
          </b-form-group>

        </b-col>

        <b-col cols="6">

          <h5 class="mb-4">Oberkategorien</h5>

          <b-form-checkbox
              v-for="(category, index) in getMainCategories"
              :key="index"
              :id="'main' + index"
              :name="'main_category[' + category.main_category_id + ']'"
              :value="category.main_category_id"
              v-model="checkedMainCategories"
          >
            {{ category.main_category }}
          </b-form-checkbox>

          {{ /* checkedMainCategories */ }}

          <h5 class="mb-4 mt-4">Unterkategorien</h5>

          <b-form-checkbox
              v-for="(category, index) in getSubCategories"
              :key="index"
              :id="'sub' + index"
              :name="'sub_category[' + category.sub_category_id + ']'"
              :value="category.sub_category_id"
              v-model="checkedSubCategories"
          >
            {{ category.sub_category }}
          </b-form-checkbox>

          {{ /* checkedSubCategories */ }}

        </b-col>

      </b-row>

      <b-button
          v-show="isFormError() && !showEmailExistAlert"
          type="button"
          variant="primary"
          class="mt-4 mb-4 button-primary"
          @click="saveUser"
      >speichern</b-button>


      <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
        <template #overlay>
          <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
            <b-icon icon="cloud-upload" font-scale="4"></b-icon>
            <div class="mb-3">Processing...</div>
            <b-progress
                min="1"
                max="20"
                :value="counter"
                variant="success"
                height="3px"
                class="mx-n4 rounded-0"
            ></b-progress>
          </div>
          <div
              v-else
              ref="dialog"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="text-center p-3"
          >
            <p><strong id="form-confirm-label">Bist Du Dir sicher?</strong></p>
            <div class="d-flex">
              <b-button variant="danger" class="mr-3" @click="onCancel" type="button">
                Cancel
              </b-button>
              <b-button variant="success" @click="onOK" type="button">OK</b-button>
            </div>
          </div>
        </template>
      </b-overlay>

    </b-form>
    {{ /* user */ }}
  </b-container>
</template>

<script>

import { mapMutations, mapGetters, mapActions } from 'vuex';
import axios from "axios";
//import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: "areaUserEdit",
  props: ['user_id'],
  data() {
    return {
      API_URL: process.env.VUE_APP_API,
      deactivate: false,
      activate: false,
      credentials: false,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      checkedMainCategories: [],
      checkedSubCategories: [],
      newUserData: {
        company: {
          name: '',
          additional_address: '',
          street: '',
          plz: '',
          city: '',
          country: '',
        },
        name: {
          firstname: '',
          lastname: '',
          email: '',
          telephone: '',
          salutation: null,
          salutation_str: ''
        },
        main_categories: [],
        sub_categories: [],
        type: '',
        type_id: null,
        status: null,
        last_login: '',
        last_insert: '',
        user_id: '',
        edit: 1
      },
      formData: {
        email: '',
        firstname: '',
        lastname: '',
        telephone: '',
        salutation: '',
        user_type: '',

        company: '',
        additional_address: '',
        street: '',
        plz: '',
        city: '',
        country: '',
      },
      formDataError: {},
      email: '',
      emailActualCheck: '',
      showEmailExistAlert: false,
      companies: {},
      actualCompanyName: '',
      countries: [],
      countriesOptions: [],
    }
  },
  beforeDestroy() {
    this.clearInterval()
  },
  mounted () {

    if (!this.$store.getters.getIsLoggedIn) {
      this.$router.push('/login');
    }

    this.getCompanies();
    this.getCountries();

  },
  created() {

    this.email = this.user.name.email;
    this.company_name = this.user.company.name;

    this.formData['salutation'] = this.user.name.salutation;
    this.formData['firstname'] = this.user.name.firstname;
    this.formData['lastname'] = this.user.name.lastname;
    this.formData['telephone'] = this.user.name.telephone;
    this.formData['email'] = this.user.name.email;
    this.formData['user_type'] = this.user.type_id;

    this.formData['company'] = this.user.company.name;
    this.formData['company_id'] = '';
    this.formData['additional_address'] = this.user.company.additional_address;
    this.formData['street'] = this.user.company.street;
    this.formData['plz'] = this.user.company.plz;
    this.formData['city'] = this.user.company.city;
    this.formData['country'] = this.user.company.country;

    for (var index in this.user.main_categories) {
      this.checkedMainCategories.push(this.user.main_categories[index].main_category_id);
    }

    for (var index2 in this.user.sub_categories) {
      this.checkedSubCategories.push(this.user.sub_categories[index2].sub_category_id);
    }

    //this.countriesOptions = this.createCountriesOptions();

    /*
    this.user.main_categories.forEach((category) => {
      //console.log(category, index);
      this.checkedMainCategories.push(category.main_category_id);
    });

    this.user.sub_categories.forEach((category) => {
      //console.log(category, index);
      this.checkedSubCategories.push(category.sub_category_id);
    });

     */
  },
  computed: {
    ...mapGetters([
        'getUserList',
        'getMainCategories',
        'getSubCategories',
        'getUser'
    ]),
    user() {

      if (this.user_id === undefined) {
        console.log('id nicht gesetzt = neuer User');
        return this.newUserData;
      }

      //console.log(this.user_id);
      //console.log(this.$store.getters.userListById(Number(this.user_id)));

      let userList = this.$store.getters.getUserList;
      //let user = this.newUserData;

      let user = userList.find(user => user.user_id === this.user_id);

      /*
      Object.keys(userList).forEach(el => {
        //console.log(el, userList[el].user_id);

        if (this.user_id == userList[el].user_id) {
          //console.log(userList[el].company.name);
          user = userList[el];
          //return userList[el];
        }

      });

      //return this.$store.getters.userListById(Number(this.user_id));

       */

      return user;
    },

  },
  methods: {
    ...mapMutations([
        'setNewUser'
    ]),
    ...mapActions([
        'setCategories',
    ]),
    ...mapGetters([

    ]),
    validation(type) {

      //console.log(type);
      //console.log(this.formData[type]);

      var ret = false;
      var data = '';
      if (this.formData[type] !== null) {
        data = this.formData[type];
      }
      //console.log(data);

      switch(type) {

        case 'email':

          var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/; /* eslint-disable-line */

          if (reg.test(data)) {

            ret = true;

            if (this.email != data) {
              this.checkEmail(data);

            }

          } else {
            ret = false;
          }
          break;

        case 'company':
          ret = true;
          if (data.length < 2) {
            ret = false;
          } else {
            if (this.company_name != data
                  && this.actualCompanyName != data
            ) {
              this.actualCompanyName = data;
              //this.checkCompany(data);
              //console.log(data);
              this.getCompanyDataFromCompanyName(data);
            }
          }

          break;

        case 'firstname':
        case 'lastname':
        case 'street':
        case 'city':
        case 'country':

          ret = true;
          if (data.length < 2) {
            ret = false;
          }

          break;

        case 'plz':

          var reg = /^[0-9]{5}$/; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }

          break;

        case 'telephone':

          var reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/im; /* eslint-disable-line */

          if (reg.test(data)) {
            ret = true;
          } else {
            ret = false;
          }
          break;

        case 'salutation':
        case 'user_type':
          //console.log(data);
          if (data === null) {
            //console.log('false');
            ret = false;
          } else {
            ret = true;
          }
          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      /*
      for (let [key, val] of form.entries()) {
        console.log(key, val);
        if (val) {
          continue;
        } else {
          return false;
        }

      }

      form.forEach((value, index) => {
        console.log(value, index);
      })

       */

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      //this.$refs.submit.focus()
    },
    onSubmit() {
      this.processing = false
      this.busy = true
    },
    newCredentials() {
      this.processing = false;
      this.busy = true;
      this.credentials = true;
    },
    deactivateUser() {
      this.processing = false;
      this.busy = true;
      this.deactivate = true;
    },
    activateUser() {
      this.processing = false;
      this.busy = true;
      this.activate = true;

    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.counter = 1;
      this.processing = true;

      //todo: local development
      var cookie = this.$store.getters.getToken;

      if (this.deactivate) {

        const response = axios.post(
            this.API_URL,
            {
              type: 'user_deactivate',
              user_id: this.user_id,
              cookie: cookie
            }
        );

        console.log(response);

      }

      if (this.activate) {

        const response = axios.post(
            this.API_URL,
            {
              type: 'user_activate',
              user_id: this.user_id,
              cookie: cookie
            }
        );

        console.log(response);

      }

      if (this.credentials) {

        this.credentials = false;

        const response = axios.post(
            this.API_URL,
            {
              type: 'user_credentials',
              user_id: this.user_id,
              cookie: cookie
            }
        );

        console.log(response);

      }

      // Simulate an async request
      this.clearInterval()
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter = this.counter + 1;
        } else {
          this.clearInterval()
          this.$nextTick(() => {
            this.busy = this.processing = false;
          })
        }
      }, 5);

      this.$router.push('/userList');
    },
    categoryCheck(category_data, category_id) {
      console.log(category_id);
      category_data.forEach((category, index) => {
        console.log(category, index);
      })
    },
    saveUser() {
      let user = this.user;
      let user_id = Number(this.user_id);

      //console.log(user, user_id);

      let userList = this.getUserList;

      var index = userList.findIndex(x => x.user_id === user_id);

      //console.log(index);

      let myForm = document.getElementById('userEditForm');
      let formData = new FormData(myForm);

      const main_categories = {};
      const sub_categories = {};
      const data = {};
      var main_size = 0;
      var sub_size = 0;

      for (let [key, val] of formData.entries()) {
        //console.log(data);
        //console.log(key);
        Object.assign(data, {[key]: val})

        if (key.indexOf('main_category') >= 0) {
          //Object.assign(main_categories, {[val]: val});

          main_categories[main_size++] = { main_category_id: val};

        }

        //console.log(key.indexOf('sub_category'));

        if (key.indexOf('sub_category') >= 0) {
          //Object.assign(sub_categories, {[val]: val});

          sub_categories[sub_size++] = { sub_category_id: val};
        }
      }

      //console.log(data);

      //console.log('main', main_categories);
      //console.log(sub_categories);

      /*
      company: {
                name: 'DSM8',
                additional_address: '',
                street: 'Leipziger Platz 1',
                plz: '12234',
                city: 'Berlin'
            },
            name: {
                firstname: 'Holger',
                lastname: 'Schmidt',
                email: 'asfadsf@asdfdsf.de',
                telephone: '12234',
                salutation: 1,
                salutation_str: 'Herr'
            },
            main_categories: [
                {
                    main_category_id: 1,
                    main_category: 'Indoor'
                },
            ],
            sub_categories: [
                {
                    sub_category_id: 1,
                    sub_category: 'Bühne'
                },
            ],
            type_id: 1,
            status: 1,
            user_id: 8,
       */

      user.name.salutation = data.salutation;
      user.name.firstname = data.firstname;
      user.name.lastname = data.lastname;
      user.name.email = data.email;
      user.name.telephone = data.telephone;
      user.name.type_id = data.type;

      user.company.name = data.company;
      user.company.additional_address = data.additional_address;
      user.company.street = data.street;
      user.company.plz = data.plz;
      user.company.city = data.city;

      user.main_categories = main_categories;
      user.sub_categories = sub_categories;

      if (this.formData['company_id'] != '') {
        user.company.company_id = this.formData['company_id'];
      }

      //console.log(user);

      let obj = {
        index: index,
        user: user
      };

      //console.log('user', user);
      //console.log('userlist', this.getUserList[index].main_categories);

      //this.$store.dispatch('setNewUser', obj);

      this.userEdit(obj);

      //console.log('userlist', this.getUserList[index].main_categories);

      //todo: newUser
      this.$router.push('/userList');
    },
    async userEdit(obj) {

      //console.log('userEdit');

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'user_edit',
            data: obj,
            cookie: cookie
          }
      );

      console.log(response);

      /*
      if (!response.data.login) {

        console.log('login fehlgeschlagen');
        console.log(response);
        console.log(response.data.error);
        this.loginErrorMessage = response.data.error.join("<br/>");
        this.loginError = true;

      } else {

        console.log('login erfolgreich');

        this.$store.dispatch('setIsLoggedIn', true);
        this.$store.dispatch('setUser', response.data.user);
        this.$store.dispatch('setToken', response.data.token);

        this.$router.push('/recording');

      }

       */
    },
    async checkEmail(email) {

      if (this.emailActualCheck == email) return;

      this.emailActualCheck = email;

      //todo: local development
      var cookie = this.$store.getters.getToken;

      //console.log(email);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'check_email',
            email: email,
            cookie: cookie
          }
      );

      //console.log('checkmail');
      //console.log(response);
      //console.log('checkmail response: ', response.data);

      this.showEmailExistAlert = false;
      if (response.data) {
        this.showEmailExistAlert = true;
      }

      return response.data;

    },

    async checkCompany(company) {

      //if (this.emailActualCheck == email) return;

      //this.emailActualCheck = email;

      //todo: local development
      var cookie = this.$store.getters.getToken;

      //console.log(company);

      const response = await axios.post(
          this.API_URL,
          {
            type: 'check_company',
            company: company,
            cookie: cookie
          }
      );

      //console.log('checkmail');
      //console.log(response);
      //console.log('checkcompany response: ', response.data);

      this.companies = response.data;

    },

    async getCompanies() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_company_list',
            cookie: cookie
          }
      );

      //console.log('checkmail');
      //console.log(response);
      //console.log('getcompanies response: ', response.data);

      this.companies = response.data;

    },

    async getCountries() {

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_countries',
            cookie: cookie
          }
      );

      this.countries = response.data;
      this.countriesOptions = this.createCountriesOptions();

    },

    getCompanyDataFromCompanyName(company) {

      //console.log(company);

      Object.keys(this.companies).forEach(index => {
        //console.log(el, userList[el].user_id);

        if (this.companies[index].company.company == company) {

          //console.log(this.companies[index].company.street);

          this.formData['company'] = this.companies[index].company.company;
          this.formData['additional_address'] = this.companies[index].company.additional_address;
          this.formData['street'] = this.companies[index].company.street;
          this.formData['plz'] = this.companies[index].company.plz;
          this.formData['city'] = this.companies[index].company.city;
          this.formData['country'] = this.companies[index].company.country;
          this.formData['company_id'] = this.companies[index].company.company_id;

          this.checkedMainCategories = [];

          Object.keys(this.companies[index].main_categories).forEach(indexMain => {

            this.checkedMainCategories.push(this.companies[index].main_categories[indexMain].category_id);

          });

          this.checkedSubCategories = [];

          Object.keys(this.companies[index].sub_categories).forEach(indexSub => {

            this.checkedSubCategories.push(this.companies[index].sub_categories[indexSub].category_id);

          });

        }

      });

    },
    createCountriesOptions() {
      let countries = this.countries;
      let options = [
        {
          'text': 'nicht zugewiesen',
          'value': null,
        }
      ];

      //console.log(countries);

      Object.keys(countries).forEach(index => {

        let obj = {};

        obj['text'] = countries[index].country;
        obj['value'] = countries[index].country;

        options.push(obj);

      });

      //console.log(options);

      return options;
    },

  }
}
</script>

<style scoped>

</style>