<template>
  <b-container>

    <!-- chapter -->

    <div
        v-for="(chapter, chapter_index) in getCourseItems"
        :key="chapter_index"
    >
      <b-row class="mt-5">


        <b-col
            cols="10"
            @click="showSidebar('courseSidebar', chapter_index,'chapter')"
            class="cursor-pointer"
        >

          <p class="h3 cursor-pointer">
            <font-awesome-icon
                :icon="['fa-solid', chapter.chapter_icon]"
                class="cursor-pointer mr-1 link-primary"
            />
            {{ chapter.chapter_title }}
          </p>
        </b-col>

        <b-col cols="2" class="">
          <p class="text-right">

            <span v-if="chapter_index == Object.keys(getCourseItems).length - 1">
              <font-awesome-icon
                  :icon="['fa-solid', 'fa-plus']"
                  class="cursor-pointer ml-2 link-primary"
                  title="Neues Kapitel anlegen"
                  @click="newItem('chapter', chapter.product_course_chapter_id, 0)"
              />
            </span>

            <font-awesome-icon
                :icon="['fa-solid', 'fa-trash-can']"
                class="cursor-pointer ml-2 text-danger"
                @click="deleteItem(chapter.product_course_chapter_id, 0)"
            />

          </p>
        </b-col>

      </b-row>

      <!-- items -->

      <div
          v-for="(item, item_index) in chapter.items"
          :key="item_index"
      >
        <b-row
            v-if="item_index == 0"
            class="item-header mt-2"
        >

          <b-col cols="9">

            <p class="h6">
              Aufgabe
            </p>
          </b-col>

          <b-col cols="2">

            <p class="h6  text-center">
              Medien
            </p>
          </b-col>

          <b-col cols="1" class="">

          </b-col>

        </b-row>


        <b-row class="item-line">

          <b-col
              cols="9"
              @click="openItemEditor(chapter_index, item_index, item.product_course_item_id)"
              class="cursor-pointer"
          >

            <p class="">
              {{ item.item_title }}
            </p>

          </b-col>

          <b-col
              cols="2"
              @click="openItemEditor(chapter_index, item_index, item.product_course_item_id)"
              class="cursor-pointer text-center"
          >

            <p class="">
              {{ countMedia(item) }}
            </p>

          </b-col>


          <b-col cols="1" class="">
            <p class="text-right">

                <span v-if="item_index == Object.keys(chapter.items).length - 1">
                  <font-awesome-icon
                      :icon="['fa-solid', 'fa-plus']"
                      class="cursor-pointer link-primary"
                      title="neue Aufgabe anlegen"
                      @click="newItem('item', chapter.product_course_chapter_id)"
                  />
                </span>

              <font-awesome-icon
                  :icon="['fa-solid', 'fa-trash-can']"
                  class="cursor-pointer ml-2 text-danger"
                  @click="deleteItem(0, item.product_course_item_id)"
              />
            </p>
          </b-col>



        </b-row>

      </div>



    </div>

    <b-row class="mt-5">

      <b-col
          cols="12"
      >

        <b-button
            type="button"
            variant="primary"
            class="mt-4 mb-4 button-primary"
            @click="saveCourse"
        >Onlinekurs speichern</b-button>

      </b-col>

    </b-row>




    <b-sidebar id="courseSidebar" title="Einstellungen" shadow>
      <div class="px-3 py-2">

        <div
            v-if="getActualCourseSidebarArea == 'chapter'"
        >
          <!-- main-category -->

          <h4>Kapitel</h4>

          <b-form-group
              label="Icon:"
              label-for="chapter_icon"
          >
            <b-form-input
                name="chapter_icon"
                id="chapter_icon"
                type="text"
                placeholder="Icon"
                :state="validation('chapter_icon')"
                v-model.trim="chapterIcon"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('chapter_icon')">
              Das Icon kann frei bleiben.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('chapter_icon')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Titel:"
              label-for="chapter_title"
          >
            <b-form-input
                name="chapter_title"
                id="chapter_title"
                type="text"
                placeholder="Titel"
                :state="validation('chapter_title')"
                v-model.trim="chapterTitle"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation('chapter_title')">
              Der Titel muss mindestens 2 Zeichen lang sein.
            </b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation('chapter_title')"></b-form-valid-feedback>
          </b-form-group>

          <b-form-group
              label="Beschreibung:"
              label-for="chapter_description"
          >
            <b-form-textarea
                id="chapter_description"
                v-model.trim="chapterDescription"
                placeholder="Kategoriebeschreibung..."
                rows="4"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
              label="Sortierung:"
              label-for="chapter_sort"
          >
            <b-input-group :append="chapterSort">
              <b-form-input
                  name="chapter_sort"
                  id="chapter_sort"
                  type="range"
                  min="0"
                  max="100"
                  v-model.trim="chapterSort"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

        </div>


      </div>
    </b-sidebar>





    <b-modal
        id="modal-item"
        size="xl"
        title="Lerninhalt-Editor">

      <!-- item -->

      <b-form-group
          label="Titel:"
          label-for="item_title"
      >
        <b-form-input
            name="item_title"
            id="item_title"
            type="text"
            placeholder="Titel"
            :state="validation('item_title')"
            v-model.trim="itemTitle"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation('item_title')">
          Der Titel muss mindestens 2 Zeichen lang sein.
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="validation('item_title')"></b-form-valid-feedback>
      </b-form-group>

      <b-form-group
          label="Beschreibung:"
          label-for="item_description"
      >
        <b-form-textarea
            id="item_description"
            v-model.trim="itemDescription"
            :state="validation('item_description')"
            placeholder="Aufgabenbeschreibung..."
            rows="4"
        ></b-form-textarea>
        <b-form-invalid-feedback :state="validation('item_description')">
          Die Beschreibung muss mindestens 0 Zeichen lang sein.
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="validation('item_description')"></b-form-valid-feedback>
      </b-form-group>

      <b-row>

        <b-col cols="6">

          <b-form-group
              label="Sortierung:"
              label-for="item_sort"
          >
            <b-form-input
                name="item_sort"
                id="item_sort"
                type="range"
                min="0"
                max="10"
                v-model.trim="itemSort"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="item-range-value">
          {{ itemSort }}
        </b-col>

      </b-row>


      <vue-dropzone
          ref="exampleDropzone"
          id="dropzoneItem"
          :options="dropzoneOptions"
          v-on:vdropzone-sending="uploadEvent"
          v-on:vdropzone-success="fileUploaded"
      ></vue-dropzone>

      <div
          v-for="(media, media_index) in getMedia"
          :key="media_index"
      >

        <b-row
            v-if="media_index == 0"
            class="media-line mt-5">

          <b-col cols="5">

            <h5>Video / Audio</h5>

          </b-col>

          <b-col cols="4">

            <h5>Titel</h5>

          </b-col>

          <b-col cols="3">
            <h5>Medien-Typ</h5>
          </b-col>

        </b-row>

        <b-row class="media-line mt-2">

          <b-col cols="5" class="pt-10 pb-10 text-center">

            <span
                v-if="createTag(media) !== ''"
                v-html="createTag(media)"></span>

          </b-col>

          <b-col cols="4" class="pt-10 pb-10">

            {{ media.ressource }}

          </b-col>

          <b-col cols="3" class="pt-10 pb-10">
            {{ media.media_type_description }}
          </b-col>

        </b-row>

      </div>

    </b-modal>



    <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
      <template #overlay>
        <div v-if="processing" class="text-center p-4 bg-primary text-light rounded">
          <b-icon icon="cloud-upload" font-scale="4"></b-icon>
          <div class="mb-3">Processing...</div>
          <b-progress
              min="1"
              max="20"
              :value="counter"
              variant="success"
              height="3px"
              class="mx-n4 rounded-0"
          ></b-progress>
        </div>
        <div
            v-else
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
        >
          <p><strong id="form-confirm-label">Bist Du Dir sicher?</strong></p>
          <div class="d-flex">
            <b-button variant="danger" class="mr-3" @click="onCancel" type="button">
              Cancel
            </b-button>
            <b-button variant="success" @click="onOK" type="button">OK</b-button>
          </div>
        </div>
      </template>
    </b-overlay>

    <b-modal size="xl" ref="preview-modal" hide-footer :title="previewModalTitle">
      <div class="d-block text-center">
        <p v-html="previewModalContent">{{ previewModalContent }}</p>
      </div>
    </b-modal>


  </b-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axios from "axios";
/* https://rowanwins.github.io/vue-dropzone/docs/dist/#/events */

export default {
  name: "productEditOnlineCourse",
  props: ['product_id'],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      formData: {
        title: '',
        url: '',
        price: '',
        pseudoprice: '',
        description: '',
        product_type: '',
      },
      formDataError: {},


      API_URL_UPLOAD: process.env.VUE_APP_UPLOAD,
      API_URL: process.env.VUE_APP_API,
      max: 10,
      uvdata: '',

      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD,
        thumbnailWidth: 150,
        maxFilesize: 512 * 1024 * 1024,
        /*
        init: function() {
          this.on("uploadprogress", function(file, progress) {
            console.log("File progress in %", progress);
          });
        },*/
        timeout: null,
        maxFiles: 1,
        acceptedFiles: ".mp4,.mp3",
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>Lade hier eine Video-Datei (nur .mp4) oder eine MP3-Datei hoch.",
        headers: { "members.myshopbooster.de": "courseItemUpload" }
      },

      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      delete_item_id: 0,

      delete_chapter_id: 0,

      previewModalTitle: '',
      previewModalContent: '',

    }
  },
  created() {
    this.getCourseData();

    this.$store.dispatch('setActualCourseChapterIndex', 0);
    this.$store.dispatch('setActualCourseItemIndex', 0);
    this.$store.dispatch('setActualCourseItemId', 0);

  },
  computed: {
    ...mapGetters([
      'getProductList',
      'getActualCourseChapterIndex',
      'getActualCourseItemIndex',
      'getActualCourseItemId',
      'getActualCourseSidebarArea',
      'getCourseItems',
      'getCourseItem',
      'getCourseItemChapter',
    ]),
    ...mapActions([
      'setActualCourseItemIndex',
      'setActualCourseItemId',
      'setActualSidebarArea',
      'setCourseItem',
      'setCourseItems',
      'setCourseChapter',
    ]),
    chapterTitle: {
      get () {
        return this.getCourseItemChapter.chapter_title;
      },
      set (value) {
        this.$store.dispatch('setCourseChapter', {chapter_title: value});
      }
    },
    chapterIcon: {
      get () {
        return this.getCourseItemChapter.chapter_icon;
      },
      set (value) {
        this.$store.dispatch('setCourseChapter', {chapter_icon: value});
      }
    },
    chapterSort: {
      get () {
        return this.getCourseItemChapter.chapter_sort;
      },
      set (value) {
        this.$store.dispatch('setCourseChapter', {chapter_sort: value});
      }
    },
    chapterDescription: {
      get () {
        return this.getCourseItemChapter.chapter_description;
      },
      set (value) {
        this.$store.dispatch('setCourseChapter', {chapter_description: value});
      }
    },

    itemTitle: {
      get () {
        return this.getCourseItem.item_title;
      },
      set (value) {
        this.$store.dispatch('setCourseItem', {item_title: value});
      }
    },
    itemDescription: {
      get () {
        return this.getCourseItem.item_description;
      },
      set (value) {
        this.$store.dispatch('setCourseItem', {item_description: value});
      }
    },
    itemSort: {
      get () {
        return this.getCourseItem.item_sort;
      },
      set (value) {
        this.$store.dispatch('setCourseItem', {item_sort: value});
      }
    },
    itemDuration: {
      get () {
        return this.getCourseItem.duration;
      },
      set (value) {
        this.$store.dispatch('setCourseItem', {duration: value});
      }
    },
    getMedia() {
      return this.getCourseItem.media;
    }
  },
  methods: {
    ...mapMutations([
    ]),
    ...mapActions([
      'setCourseItemMedia',
    ]),
    ...mapGetters([
    ]),
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      //this.$refs.submit.focus()
    },
    onSubmit() {
      this.processing = false
      this.busy = true
    },
    deleteItem(chapter_id, item_id) {
      this.processing = false;
      this.busy = true;
      this.delete_chapter_id = chapter_id;
      this.delete_item_id = item_id;
    },
    onCancel() {
      this.busy = false
    },
    async onOK() {
      this.counter = 1;
      this.processing = true;

      await this.deleteTree();

      // Simulate an async request
      this.clearInterval()
      this.interval = setInterval(() => {
        if (this.counter < 20) {
          this.counter = this.counter + 1;
        } else {
          this.clearInterval()
          this.$nextTick(() => {
            this.busy = this.processing = false;
          })
        }
      }, 5);
    },
    saveProduct() {
      let product = this.product;
      //let product_id = Number(this.product_id);

      let myForm = document.getElementById('productEditForm');
      let formData = new FormData(myForm);

      //console.log(formData);

      const data = {};

      for (let [key, val] of formData.entries()) {
        //console.log(data);
        //console.log(key);
        Object.assign(data, {[key]: val})

      }

      //console.log('data');
      //console.log(data);

      product.product_name = data.title;
      product.product_url = data.url;
      product.product_description = data.description;
      product.product_price = data.price;
      product.product_pseudoprice = data.pseudoprice;
      product.product_type_id = data.product_type;

      //console.log(product);

      this.productEdit(product);

    },
    showSaveButton(status) {
      this.$emit('updateShowSaveChildComponent2', status);
    },
    showSidebar(id, chapter_index, area) {
      //console.log(area);

      this.$store.dispatch('setActualCourseChapterIndex', chapter_index);
      this.$store.dispatch('setActualCourseSidebarArea', area);

      this.$root.$emit('bv::toggle::collapse', id);
    },
    openItemEditor(chapter_index, item_index, item_id) {

      this.$store.dispatch('setActualCourseChapterIndex', chapter_index);
      this.$store.dispatch('setActualCourseItemIndex', item_index);
      this.$store.dispatch('setActualCourseItemId', item_id);

      this.$bvModal.show('modal-item');
    },
    uploadEvent (file, xhr, formData) {

      //console.log('uploadEvent',this.getActualCourseItemId);
      if (this.getActualCourseItemId > 0) {
        formData.append('product_course_item_id', this.getActualCourseItemId);
      }

    },
    fileUploaded(file, response) {
      //console.log(file);
      //console.log(response);

      let result = JSON.parse(response);

      //console.log(result.product_media_id);
      //console.log(result);

      if (result.product_media_id === undefined) return;

      this.$store.dispatch('setCourseItemMedia', {item: result});


    },
    validation(type) {

      if (this.formData[type] === undefined) return true;

      var ret = false;
      var data = this.formData[type];



      switch(type) {

        case 'chapter_title':
        case 'item_title':

          ret = true;
          if (data.length < 2) {
            ret = false;
          }

          break;

        case 'url':

          if (data.startsWith('https://') || data === '') {
            ret = true;
          } else {
            ret = false;
          }

          break;

        default:
          ret = true;
          break;


      }

      this.formDataError[type] = ret;
      this.isFormError();
      return ret;

    },
    isFormError() {

      var form = this.formDataError;

      //console.log(form);
      if (form.length === 0) return true;

      for (const key in form) {
        //console.log(key);
        if (!form[key]) {
          //console.log(false);
          return false;
        }
      }

      return true;

    },
    getProgressCss(css, value) {

      if (value <= 3) {
        return css + 3;
      }

      if (value <= 6) {
        return css + 6;
      }

      return css + 10;

    },
    createTag(media) {

      if(media.media_type_description === undefined) return '';

      var str = '';

      switch(media.media_type_description) {

        case 'Video mp4':
          str = '<video width="100%" height="100%" controls volume="50" preload="auto"><source src="' + media.url + '" type="video/mp4"/></video>';
          break;

        case 'Audio mp3':
          str = '<audio controls volume="50" preload="auto"><source src="' + media.url + '" type="audio/mp3"/></audio>';
          break;

      }

      //console.log(str);

      return str;

    },
    copyText(content) {
      navigator.clipboard.writeText(content);
    },
    countMedia(item) {

      if (item.media === undefined) return 0;

      return Object.keys(item.media).length;

    },
    async sendUrlVideo() {

      if (this.uvdata === '') return;

      //todo: url validierung, nur wenn ok
      //todo: local development
      var cookie = this.$store.getters.getToken;

      var uvdata = this.uvdata;
      this.uvdata = '';

      const response = await axios.post(
          this.API_URL,
          {
            type: 'new_product_course_url_video',
            product_course_item_id: this.getActualCourseItemId,
            uvdata: uvdata,
            cookie: cookie
          }
      );

      console.log(response);

      if (response.data.error) {

        //console.log(response);

      } else {

        this.$store.dispatch('setCourseItemMedia', {item: response.data});

      }

    },
    async getCourseData() {

      this.$store.dispatch('setCourseItems', {});

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_product_course_data',
            product_id: this.product_id,
            cookie: cookie
          }
      );

      //console.log(response.data);

      this.$store.dispatch('setCourseItems', response.data.chapter);

    },

    async saveCourse() {

      let course = this.getCourseItems;

      console.log(course);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'save_product_course_data',
            product_id: this.product_id,
            course: course,
            cookie: cookie
          }
      );

      console.log(response.data);

      await this.getCourseData();

    },
    async newItem(itemType, chapter_id) {

      await this.saveCourse();

      let course = this.getCourseItems;

      var product_course_id = course[0].product_course_id;
      var product_course_chapter_id = 0;

      switch(itemType) {

        case 'chapter':
          product_course_chapter_id = 0;
          break;

        case 'item':
          product_course_chapter_id = chapter_id;
          break;

      }

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'create_new_course_item',
            product_id: this.product_id,
            product_course_id: product_course_id,
            product_course_chapter_id: product_course_chapter_id,
            cookie: cookie
          }
      );

      console.log(response.data);

      await this.getCourseData();

    },
    async deleteTree() {

      await this.saveCourse();

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'delete_course_item',
            product_id: this.product_id,
            product_course_chapter_id: this.delete_chapter_id,
            product_course_item_id: this.delete_item_id,
            cookie: cookie
          }
      );

      console.log(response.data);

      await this.getCourseData();

    },
    async preview() {
      console.log('preview');

      await this.saveCourse();

      let item = this.getCourseItem;

      //console.log(item);

      //todo: local development
      var cookie = this.$store.getters.getToken;

      const response = await axios.post(
          this.API_URL,
          {
            type: 'get_preview',
            product_id: this.product_id,
            product_course_item_id: item.product_course_item_id,
            cookie: cookie
          }
      );

      console.log(response.data);

      this.previewModalTitle = item.item_description;
      this.previewModalContent = response.data;

      this.$refs['preview-modal'].show();
      //this.$refs['preview-modal'].hide()
    },


  }
}
</script>

<style scoped>

.progress-impact-3 {
  background-color: #9F8CCC !important;
}

.progress-impact-6 {
  background-color: #7B61B8 !important;
}

.progress-impact-10 {
  background-color: #5d4498 !important;
}

.progress-cost-3 {
  background-color: #C47EC5 !important;
}

.progress-cost-6 {
  background-color: #B053B2 !important;
}

.progress-cost-10 {
  background-color: #954497 !important;
}

.progress-priority-3 {
  background-color: #98C57E !important;
}

.progress-priority-6 {
  background-color: #81B861 !important;
}

.progress-priority-10 {
  background-color: #639744 !important;
}

.sub-category-line {
  background-color: #e9ecef;
}

.item-line {
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 2px;
  margin-top: 2px;
}

.item-header {
  border-bottom: 1px solid #5d4498;
  margin-bottom: 10px;
}

.media-line {
  border-bottom: 1px solid #5d4498;
  margin-bottom: 10px !important;
}

.item-range-value {
  font-size: 26px;
  padding-top: 19px;
  font-weight: bold;
  color: #5d4498;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.media-icon {
  font-size: 30pt;
}


</style>