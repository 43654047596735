<template>
  <div v-if="getIsLoggedIn">
    <viewHeader />
    <b-container fluid="xxl" class="mb-6 position-relative">
      
      <hocSticky class="wl-sticky__nav-sticky">
          <NavigationBar></NavigationBar>
      </hocSticky>

      <hocCard>
       
        <template #default>
           <b-container fluid="xxl">
            <b-row>
              <b-col cols="12" lg="7" xl="8" xxl="9" class="p-xxl-5 p-xl-4 p-2">
                <slot />
              </b-col>
              <b-col cols="12" lg="5" xl="4" xxl="3">
                <aside id="sidebar" class="sidebar h-100 p-2 pt-4" >
                   <component :is="sidebar">
                        <slot />
                    </component>
                </aside>
              </b-col>
            </b-row>
          
            </b-container>
          
        </template>               
      </hocCard>
    </b-container>
   
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import viewHeader from "@/views/viewHeader";
import hocCard from '@/components/hoc/hocCard';
import NavigationBar from "@/components/NavigationBar";
import hocSticky from '@/components/hoc/hocSticky.vue';
import viewTasks from '@/views/viewTasks';

export default {
  name: "DefaultLayout",
  data() {
    return{
        sidebar: viewTasks
    }
  },
  components: {
    viewHeader,
    hocCard,
    hocSticky,
    NavigationBar,

  },
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          const component = await import(`@/views/${route.meta.sidebar}.vue`)
         
          this.sidebar = component?.default || viewTasks
        } catch (e) {
          this.sidebar = viewTasks
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getIsLoggedIn'
    ]),
  }
}
</script>

<style scoped>
</style>